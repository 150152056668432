import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { deleteMethod, getMethod, postMethod } from "../../../../Services/ApiServices";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ProductGallery from "./ProductGallery";
import AddProductGallery from "./Modal/AddProductGallery";

const ProductGalleryList = (props) => {
    const history = useHistory()
    const [showModal, setShowModal] = useState(false)
    const [prevGallery, setPrevGallery] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    async function fetchGallery(){
        const endpoint = "home/productgallery";
        await getMethod(endpoint).then((res) => {
            if (res.success) {
                setPrevGallery(res.data)
            }
        })
    }

    useEffect(() => {
        fetchGallery()
    }, [])

    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Product Gallery</h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <a onClick={() => setShowModal(true)} className="btn btn-white btn-outline-light"><span>Add New</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {prevGallery?.map((val, index) => {
                    return (
                        <ProductGallery key={index} data={val} showToast={setShowToast} fetchGallery={fetchGallery} showMessage={setShowMessage} prevGallery={prevGallery} index={index} />
                    )
                })}
            </div>
            <AddProductGallery
                show={showModal}
                onHide={() => setShowModal(false)}
                showToast={setShowToast}
                showMessage={setShowMessage}
            />
        </div >
    )
}

export default ProductGalleryList;