import React from "react";
import { Link } from "react-router-dom";

const Register = () => {
    return (
        <div className="nk-body bg-white npc-general pg-auth">
            <div className="nk-app-root">
                <div className="nk-main ">
                    <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                                <div className="brand-logo pb-4 text-center">
                                    <a href="html/index.html" className="logo-link">
                                        <img className="logo-light logo-img logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo-main.png"} alt="logo" />
                                        <img className="logo-dark logo-img logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo-main.png"} alt="logo-dark" />
                                    </a>
                                </div>
                                <div className="card card-bordered">
                                    <div className="card-inner card-inner-lg">
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title">Register</h4>
                                                <div className="nk-block-des">
                                                    <p>Create New Dashlite Account</p>
                                                </div>
                                            </div>
                                        </div>
                                        <form action="/login">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="name">Name</label>
                                                <input type="text" className="form-control form-control-lg" id="name" placeholder="Enter your name" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="email">Email or Username</label>
                                                <input type="text" className="form-control form-control-lg" id="email" placeholder="Enter your email address or username" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="password">Passcode</label>
                                                <div className="form-control-wrap">
                                                    <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                        <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                        <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                                    </a>
                                                    <input type="password" className="form-control form-control-lg" id="password" placeholder="Enter your passcode" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-control-xs custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="checkbox" />
                                                    <label className="custom-control-label" htmlFor="checkbox">I agree to Dashlite <a href="#">Privacy Policy</a> &amp; <a href="#"> Terms.</a></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-lg btn-primary btn-block">Register</button>
                                            </div>
                                        </form>
                                        <div className="form-note-s2 text-center pt-4"> Already have an account? <Link to="/login"><strong>Sign in instead</strong></Link>
                                        </div>
                                        <div className="text-center pt-4 pb-3">
                                            <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
                                        </div>
                                        <ul className="nav justify-center gx-8">
                                            <li className="nav-item"><a className="nav-link" href="#">Facebook</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Google</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-footer nk-auth-footer-full">
                                <div className="container wide-lg">
                                    <div className="row g-3">
                                        <div className="col-lg-6 order-lg-last">
                                            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#">Terms & Condition</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#">Privacy Policy</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#">Help</a>
                                                </li>
                                                <li className="nav-item dropup">
                                                    <a className="dropdown-toggle dropdown-indicator has-indicator nav-link" data-toggle="dropdown" data-offset="0,10"><span>English</span></a>
                                                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                        <ul className="language-list">
                                                            <li>
                                                                <a href="#" className="language-item">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/flags/english.png"} alt="" className="language-flag" />
                                                                    <span className="language-name">English</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="language-item">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/flags/spanish.png"} alt="" className="language-flag" />
                                                                    <span className="language-name">Español</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="language-item">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/flags/french.png"} alt="" className="language-flag" />
                                                                    <span className="language-name">Français</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="language-item">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/flags/turkey.png"} alt="" className="language-flag" />
                                                                    <span className="language-name">Türkçe</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="nk-block-content text-center text-lg-left">
                                                <p className="text-soft">&copy; 2019 CryptoLite. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Register;