import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { postMethod, getAddress, putMethod } from "../../../Services/ApiServices";
import FeatureDescription from "./Features/FeatureDescription";

const Itinerary = (props) => {

    const [itineraryTitle, setItineraryTitle] = useState("");
    const [itineraryImages, setItineraryImages] = useState([])
    const [itineraryFeature, setItineraryFeature] = useState({});
    const [featureLocation, setFeatureLocation] = useState("")
    const [featureDescription, setFeatureDescription] = useState([]);

    useEffect(() => {
        setItineraryTitle(props?.data?.heading);
        setItineraryImages(props?.data?.itineraryImages ? props?.data?.itineraryImages : []);
        setFeatureLocation(props?.data?.location);
        setFeatureDescription(props?.data?.itineraryDescription ? props?.data?.itineraryDescription : [])
        setItineraryFeature({
            "heading": props?.data?.heading ? props?.data?.heading : "",
            "latitude": props?.data?.latitude ? props?.data?.latitude : "",
            "longitude": props?.data?.longitude ? props?.data?.longitude : "",
            "location": props?.data?.location ? props?.data?.location : "",
            "itineraryImages": props?.data?.itineraryImages ? props?.data?.itineraryImages : [],
            "itineraryDescription": props?.data?.itineraryDescription ? props?.data?.itineraryDescription : []
        })
    }, [props])

    const handleFeatureImage = (e) => {
        const endpoint = "product/uploadimages";
        const formData = new FormData();
        let gallery = Object.values(e.target.files);
        for (let key in gallery) {
            formData.append('itineraryImages', gallery[key])
        }
        postMethod(endpoint, formData).then((res) => {
            if (res.success) {
                setItineraryImages(res.itineraryImages)
                // setImages((prev) => [...prev, res.itineraryImages[0]])
                let arr = props.itinerary;
                arr[props.index] = { ...arr[props.index], "itineraryImages": res.itineraryImages }
                props.setItinerary(arr)

            }
        })
    }
    const handleFeatureDetail = (e) => {
        setFeatureLocation(e.target.value);
        const params = {
            address: e.target.value.split(" ").join("")
        }
        getAddress(params).then((res) => {
            if (res?.results[0]?.geometry) {
                let x = res?.results[0]?.geometry?.location?.lat;
                let y = res?.results[0]?.geometry?.location?.lng;
                let arr = props?.itinerary;
                arr[props.index] = {
                    ...arr[props.index],
                    [e.target.name]: e.target.value,
                    "latitude": x,
                    "longitude": y,
                }
                props.setItinerary(arr)
            }

        })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const deleteItinerary=(e)=>{
        e.preventDefault();
        const endpoint="admin/delete/itineraries"
        const params={
            "productId":props?.prodId,
            "itinerariesId":props?.itId
        }
        putMethod(endpoint,'',params).then((res)=>{
            if(res.success){
                props.showToast(true);
                props.showMessage("Itinerary Deleted");
                props.itinerary.splice(props.index, 1)
            }
            else{
                props.showToast(true);
                props.showMessage(res.error)
            }
        })
    }


    return (
        <div>
            <form onSubmit={handleSubmit} className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6 mt-2">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-title">Itinerary Title</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-title"
                                    name="heading"
                                    value={itineraryTitle}
                                    placeholder="Enter Itinerary Title"
                                    onChange={(e) => {
                                        let arr = props.itinerary;
                                        arr[props.index] = {
                                            [e.target.name]: e.target.value,
                                            "longitude": itineraryFeature.longitude,
                                            "latitude": itineraryFeature.latitude,
                                            "location": itineraryFeature.location,
                                            "itineraryImages": itineraryFeature.itineraryImages,
                                            "itineraryDescription": itineraryFeature.itineraryDescription
                                        }
                                        props.setItinerary(arr)
                                        setItineraryTitle(e.target.value)
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <button className="d-flex del-btn ml-auto" onClick={deleteItinerary}><IoTrashOutline color="#000" size={18} /></button>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">Feature Images</label>
                            <div className="d-flex">
                                {itineraryImages?.length > 0 ?
                                    itineraryImages.map((val, index) => {
                                        return (
                                            <Image src={process.env.REACT_APP_IMG_URL + val} key={index} className="image-container1  mr-4" style={{ borderStyle: 'dashed' }} />

                                        )
                                    })

                                    :
                                    null
                                }
                                <label htmlFor={"profile" + props.index}>
                                    <div className="image-container1">
                                        <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                    </div>
                                </label>
                                <input
                                    id={"profile" + props.index}
                                    multiple
                                    type="file"
                                    name="itineraryImages"
                                    onChange={handleFeatureImage}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-location">Feature Location</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-location"
                                    name="location"
                                    value={featureLocation}
                                    placeholder="Enter Feature Location"
                                    onChange={handleFeatureDetail}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                    {
                        featureDescription?.map((val, index) => {
                            return (
                                <FeatureDescription data={val} feature={featureDescription} setFeature={setFeatureDescription} featureDescription={props?.itinerary} setFeatureDescription={props?.setItinerary} index={index} ind={props?.index} />
                            )
                        })
                    }
                </div>
            </form>
            <div className="col-md-6 pl-0 mt-3">
                <div className="d-flex">
                    <Button className="feature-desc-btn" onClick={() => { setFeatureDescription(prev => [...prev, ""]) }}>Add Feature Description<FiPlus className="ml-1" color="#fff" size={18} /></Button>
                </div>
            </div>
        </div>

    )
}
export default Itinerary;