import React, { useEffect, useState } from "react";
import { getCities, getCountries, getStates, postMethod } from "../../../../../Services/ApiServices";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddAddress = (props) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState();
    const [stateCode, setStateCode] = useState()
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in"
    });
    const [billing, setBilling] = useState({
        "userName": "",
        "phoneNo": {
            "mobile": "",
            "countryCode": "",
            "callingCode": ""
        },
        "gstNo": "",
        "buildingName": "",
        "address": "",
        "city": "",
        "state": "",
        "country": "",
        "label": "billing",
        "portCode": ""
    });

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
        }).catch(err => {

        });
    }

    const fetchStates = (code) => {
        getStates(code).then(res => {
            setStates(res.data);
        }).catch(err => {

        });
    }

    const fetchCities = (code) => {
        getCities(countryCode, code).then(res => {
            setCities(res.data);
        }).catch(err => {

        });
    }

    const handleBillChange = (e) => {
        setBilling((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,

            };
        });
    }
    const saveAddress = () => {
        const endpoint = "admin/address";
        if (props?.id) {
            billing.userId = props?.id
        } else {
            billing.agentId = props?.agentId
        }
        postMethod(endpoint, billing).then((res) => {
            if (res.success) {
                props.set(false);
                props.showToast(true);
                props.toastMessage("Address Added Successfully");
                props.reload(true)
                if (props?.id) {
                    props?.agentAddressDetailsFetch(props?.id)
                } else {
                    props?.agentAddressDetailsFetch()
                }
            }
            else {
                props.showToast(true);
                props.toastMessage(res.error);
            }
        })
    }
    return (
        <>
            <div className="pt-3 pl-3">
                <h4 className="uL_18">Billing Address</h4>
                <form className="form-validate">
                    <div className="row g-gs">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-name">Name or Company Name</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-name"
                                        type="text"
                                        name="userName"
                                        placeholder="Name or Company Name"
                                        value={billing.userName}
                                        autoComplete="off"
                                        onChange={handleBillChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-gst">GST No</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-gst"
                                        type="text"
                                        name="gstNo"
                                        placeholder="GST Number"
                                        value={billing.gstNo}
                                        autoComplete="off"
                                        onChange={handleBillChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                <div className="form-control-wrap">
                                    <span className="countryCode">
                                        <PhoneInput
                                            inputProps={{
                                                name: 'phoneNo',
                                                disabled: true,
                                                className: "hgt01"
                                            }}
                                            value={"+" + phoneFormat.dialCode}
                                            onChange={(e, a) => {
                                                setPhoneFormat(a);
                                                setBilling((prev) => {
                                                    return {
                                                        ...prev,
                                                        "phoneNo": { mobile: phone, countryCode: a.countryCode.toUpperCase(), callingCode: parseInt(a.dialCode) }
                                                    }
                                                })
                                            }}
                                        />
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control pl-110"
                                        id="fv-phone"
                                        name="phoneNo"
                                        value={phone}
                                        placeholder="Enter Phone Number"
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                            setBilling((prev) => {
                                                return {
                                                    ...prev,
                                                    [e.target.name]: { mobile: e.target.value, countryCode: phoneFormat.countryCode.toUpperCase(), callingCode: parseInt(phoneFormat.dialCode) }
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-hno">House/Flat Number</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-hno"
                                        type="text"
                                        name="buildingName"
                                        placeholder="House/Flat Number"
                                        value={billing.buildingName}
                                        autoComplete="off"
                                        onChange={handleBillChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-address">Street Address</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-address"
                                        type="text"
                                        name="address"
                                        placeholder="Street Address"
                                        value={billing.address}
                                        autoComplete="off"
                                        onChange={handleBillChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-country">Country</label>
                                <div className="form-control-wrap">
                                    <select custom name="country" value={billing?.country}
                                        onChange={(e) => {
                                            setBilling((prev) => {
                                                return {
                                                    ...prev,
                                                    [e.target.name]: e.target.value,
                                                    state: '',
                                                    city: ''
                                                };
                                            });
                                            countries.forEach(x => {
                                                if (x.name === e.target.value) {
                                                    setCountryCode(x.isoCode)
                                                    fetchStates(x.isoCode)
                                                }
                                            });

                                        }}
                                    >
                                        <option>Choose Country</option>
                                        {countries.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name}>{val.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-state">State</label>
                                <div className="form-control-wrap">
                                    <select custom name="state" value={billing?.state}
                                        onChange={(e) => {
                                            setBilling((prev) => {
                                                return {
                                                    ...prev,
                                                    [e.target.name]: e.target.value,
                                                    city: ''
                                                };
                                            });
                                            states.forEach(x => {
                                                if (x.name === e.target.value) {
                                                    setStateCode(x.isoCode)
                                                    fetchCities(x.isoCode)
                                                }
                                            });

                                        }}
                                    >
                                        <option>Choose State</option>
                                        {states.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name}>{val.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-city">City</label>
                                <div className="form-control-wrap">
                                    <select custom name="city" value={billing?.city}
                                        onChange={(e) => {
                                            setBilling((prev) => {
                                                return {
                                                    ...prev,
                                                    [e.target.name]: e.target.value,
                                                };
                                            });

                                        }}
                                    >
                                        <option>Choose City</option>
                                        {cities.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name}>{val.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-portcode">Zip Code</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-portcode"
                                        type="text"
                                        name="portCode"
                                        placeholder="Zip Code"
                                        value={billing.portCode}
                                        autoComplete="off"
                                        onChange={handleBillChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div className="py-4 px-3">
                <button type="button" className="dash-button btn btn-primary" onClick={saveAddress}>Save</button>
                <button className="btn_none pl-4" onClick={() => { props.set(false) }}>Cancel</button>
            </div>
        </>

    )
}
export default AddAddress;