import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoTrashOutline } from "react-icons/io5";
import { deleteMethod, getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ProductGallery = (props) => {
    const history = useHistory()
    const [title, setTitle] = useState("")
    const [gallery, setGallery] = useState([]);
    const [prevGallery, setPrevGallery] = useState([]);


    useEffect(() => {
        setTitle(props?.data?.title)
        setPrevGallery(props?.data?.images)

    }, [])

    const deleteImage = (e, id, i) => {
        e.preventDefault();
        const endpoint = "home/delete/productGallery"
        const params = {
            "productGalleryId": props?.data?._id,
            "imageId": id
        }
        putMethod(endpoint, '', params).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Deleted Successfully");
                prevGallery.splice(i, 1)
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })
    }

    const deleteGallery = (e) =>{
        e.preventDefault();
        const endpoint = "home/delete/productGallery"
        const params = {
            "productGalleryId": props?.data?._id,
        }
        const formData = new FormData();
        formData.append('select', true)
        putMethod(endpoint, formData , params).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Deleted Successfully");
                props?.prevGallery?.splice(props?.index, 1)
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint = "home/delete/productGallery";
        const params={
            "productGalleryId": props?.data?._id
        }

        const formData = new FormData();
        formData.append('title', title)
        for (let key in gallery) {
            formData.append('images', gallery[key])
        }
        formData.append('select', false)
        putMethod(endpoint, formData, params).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Updated Successfully");
                // setGallery([])
                props?.fetchGallery();
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })

    }
    return (
        <div className="card card-bordered">
            <div className="card-inner">
                <form className="form-validate">
                    <div className="row g-gs">
                        <div className="col-md-6 mt-2">
                            <div className="form-group">
                                <label className="form-label" htmlFor="fv-title">Title</label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fv-title"
                                        name="heading"
                                        value={title}
                                        placeholder="Enter Title"
                                        onChange={(e) => {
                                            setTitle(e.target.value)
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2">
                            <button className="d-flex del-btn ml-auto" onClick={deleteGallery}><IoTrashOutline color="#000" size={18} /></button>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label">Gallery</label>
                                <div className="form-control-wrap">
                                    {
                                        gallery || prevGallery ?
                                            <div className="main-block">
                                                {prevGallery?.map((val, i) => {
                                                    return (
                                                        <div className="inner-block">
                                                            <img key={i} className="image-container1" src={process.env.REACT_APP_IMG_URL + val.path} alt="gallery" />
                                                            <button className="btn_none btn-pos" onClick={(e) => deleteImage(e, val._id, i)}><AiOutlineCloseCircle color="#000" size={18} /></button>
                                                        </div>
                                                    )
                                                })
                                                }
                                                {
                                                    gallery?.map((val, i) => {
                                                        return (
                                                            <div className="inner-block">
                                                                <img className="image-container1" key={i} src={URL.createObjectURL(val)} alt="gallery" />
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <label htmlFor="customGallery">
                                                    <div className="image-container1">
                                                        <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                    </div>
                                                </label>
                                            </div>
                                            :
                                            <label htmlFor="customGallery">
                                                <div className="image-container1">
                                                    <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                </div>
                                            </label>
                                    }
                                </div>
                                <input
                                    id="customGallery"
                                    type="file"
                                    name="gallery"
                                    multiple
                                    onChange={(e) => setGallery(Object.values(e.target.files))}
                                />
                            </div>
                        </div></div>
                </form>
                <div className="row fr_jc pr-3 py-3">
                    <div className="form-group">
                        <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProductGallery;