import React, { useContext, useEffect, useState } from "react";
import ToastMessage from "../../../../Components/Toast/Toast";
import { getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import { Image, Button } from "react-bootstrap";
import AddHighlight from "../../Highlights/AddHighlight";
import Itinerary from "../../Itinerary/Itinerary";
import { IoCamera } from "react-icons/io5";
import { getFormatedDate } from "../../../../Utils/date.util";
import styles from './AddProduct.module.scss';
import clsx from "clsx";
import { CoreContext } from "../../../../Contexts/CoreContext";

const AddProduct = (props) => {

    const coreContext = useContext(CoreContext);

    const [id, setId] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [showBroc, setShowBroc] = useState(true);
    const [prodId, setProdId] = useState("");
    const [addHighlight, setAddHighlight] = useState([]);
    const [addItinerary, setAddItinerary] = useState([]);
    const [comingSoon, setComingSoon] = useState(false)
    const [data, setData] = useState({})
    const [tripName, setTripName] = useState("");
    const [price, setPrice] = useState("");
    const [note, setNote] = useState([]);
    const [sellingPrice, setSellingPrice] = useState("");
    const [arrivalAirport, setArrivalAirport] = useState("");
    const [departureAirport, setDepartureAirport] = useState("");
    const [maximumBooking, setMaximumBooking] = useState("");
    const [bookingTripStartDate, setBookingTripStartDate] = useState("");
    const [bookingTripEndDate, setBookingTripEndDate] = useState("");
    const [tripGoingDate, setTripGoingDate] = useState("");
    const [tripLeavingDate, setTripLeavingDate] = useState("");
    const [nonRefundableDate, setNonRefundableDate] = useState("")
    const [advancePrepaidAmountPercentage, setAdvancePrepaidAmountPercentage] = useState("");
    const [gracePeriod, setGracePeriod] = useState("");
    const [slug, setSlug] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState("");
    const [tripLogoImage, setTripLogoImage] = useState("");
    const [tripFeaturedImage, setTripFeaturedImage] = useState("");
    const [tripGallery, setTripGallery] = useState([]);
    const [tripVideo, setTripVideo] = useState("")
    const [showToast, setShowToast] = useState(false);
    const [noOfInstall, setNoOfInstall] = useState("");
    const [itineraryPdf, setItineraryPdf] = useState("");
    const [noOfInstallments, setNoOfInstallments] = useState("")
    const [installments, setInstallments] = useState([])
    const [refundCalender, setRefundCalender] = useState([])
    const [earlyBirdPrice, setEarlyBirdPrice] = useState(true)

    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    const dateFormatConverter = (arg) => {
        let date = new Date(arg);
        let datel = date.getUTCDate() < 10 ? ("0" + date.getUTCDate()).slice(-2) : date.getUTCDate();
        let month = date.getUTCMonth() < 10 ? ("0" + (date.getUTCMonth() + 1)).slice(-2) : (date.getUTCMonth() + 1);
        let returnDate = date.getUTCFullYear() + '-' + month + '-' + datel
        return returnDate;
    }
    const dateConvert = (arg) => {
        let date = new Date(arg);
        let datel = date.getUTCDate() < 10 ? ("0" + date.getUTCDate()).slice(-2) : date.getUTCDate();
        let month = date.getUTCMonth() < 10 ? ("0" + (date.getUTCMonth() + 1)).slice(-2) : (date.getUTCMonth() + 1);
        let returnDate = datel + '-' + month + '-' + date.getUTCFullYear()
        return returnDate;
    }
    useEffect(() => {
        let array = [];

        if (props?.location?.state?.id) {
            setId(props?.location?.state?.id)
            const endpoint = `product/${props?.location?.state?.id}`
            getMethod(endpoint).then((res) => {
                let { data } = res;
                if (data) {
                    setProdId(data._id)
                    data.installments?.forEach(element => {
                        array.push(dateFormatConverter(element.installmentDueDate))
                    })
                    setTripName(data.tripName);
                    setNote(data.note);
                    setArrivalAirport(data.arrivalAirport);
                    setDepartureAirport(data.departureAirport);
                    setComingSoon(data.comingSoon);
                    setPrice(data.price);
                    setSellingPrice(data.sellingPrice);
                    setMaximumBooking(data.maximumBooking);
                    setItineraryPdf(data.itineraryPdf);
                    setBookingTripStartDate(dateFormatConverter(data.bookingTripStartDate));
                    setBookingTripEndDate(dateFormatConverter(data.bookingTripEndDate));
                    setTripGoingDate(dateFormatConverter(data.tripGoingDate));
                    setTripLeavingDate(dateFormatConverter(data.tripLeavingDate));
                    setNonRefundableDate(dateFormatConverter(data.nonRefundableDate))
                    setTripLogoImage(data.tripLogoImage);
                    setTripFeaturedImage(data.tripFeaturedImage);
                    setAdvancePrepaidAmountPercentage(data.advancePrepaidAmountPercentage);
                    setGracePeriod(data.gracePeriod);
                    setSlug(data.slug);
                    setTermsAndConditions(data.termsAndConditions);
                    setTripGallery(data.tripGallery);
                    setTripVideo(data.tripVideo)
                    setData(res?.data);
                    setNoOfInstallments(data.noOfInstallments)
                    setInstallments(data.installments)
                    setRefundCalender(data.refundCalender.map(el => {
                        return {
                            date: getFormatedDate(el.date),
                            refundType: el.refundType,
                            refund: el.refund
                        }
                    }))

                    setInstallments(
                        array
                    )
                    setAddHighlight(
                        data.highlights ?
                            data.highlights
                            :
                            [{
                                "heading": "",
                                "highlight": []
                            }]
                    )
                    setAddItinerary(data.itineraries ?
                        data.itineraries
                        :
                        [{
                            "heading": "",
                            "itinerary": []
                        }]
                    )
                    setNoOfInstall(data.noOfInstallments)
                }

            })
        }

    }, [])
    const handleChange = (e) => {
        setData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleInstallments = (e) => {

    }

    let value = noOfInstallments ? parseInt(noOfInstallments) : null
    const arr = [...Array(value)];

    const handleSubmit = (e) => {
        e.preventDefault();
        id ? editProduct(data?._id) : addProduct()
    }

    const myFunction = () => {
        var abc = document.getElementsByClassName('ql-editor').innerHTML;
    }

    const updateRefundCalendarValue = (name, value, index) => {
        setRefundCalender(state => {
            const updated = JSON.parse(JSON.stringify(state));
            updated[index][name] = value;
            return updated;
        })
    }

    const addRefundCalendar = () => {
        setRefundCalender(state => {
            const updated = [...state];
            updated.push({
                date: '',
                refundType: 'partial',
                refund: ''
            });
            return updated;
        });
    }

    const removeRefundCalendar = (index) => {
        setRefundCalender(state => {
            const updated = [...state];
            updated.splice(index, 1);
            return updated;
        });
    }

    const addNote = () => {
        setNote(state => {
            const updated = [...state];
            updated.push('');
            return updated;
        });
    }

    const removeNote = (index) => {
        setNote(state => {
            const updated = [...state];
            updated.splice(index, 1);
            return updated;
        });
    }

    const addProduct = () => {
        const endpoint = "product/addproduct";

        const formData = new FormData();
        formData.append("tripName", tripName);
        formData.append("price", price);
        formData.append("sellingPrice", sellingPrice);
        formData.append("tripVideo", tripVideo);
        formData.append("tripLogoImage", tripLogoImage);
        formData.append("tripFeaturedImage", tripFeaturedImage);
        formData.append("bookingTripStartDate", bookingTripStartDate);
        formData.append("bookingTripEndDate", bookingTripEndDate);
        formData.append("tripGoingDate", tripGoingDate);
        formData.append("tripLeavingDate", tripLeavingDate);
        formData.append("nonRefundableDate", nonRefundableDate);
        formData.append("maximumBooking", maximumBooking);
        formData.append("gracePeriod", gracePeriod);
        formData.append("advancePrepaidAmountPercentage", advancePrepaidAmountPercentage);
        formData.append("termsAndConditions", termsAndConditions)
        formData.append("arrivalAirport", arrivalAirport);
        formData.append("departureAirport", departureAirport);
        formData.append("comingSoon", comingSoon);
        formData.append("earlyBirdPrice", earlyBirdPrice);
        formData.append("note", JSON.stringify(note));
        formData.append("slug", slug);
        formData.append("itineraryPdf", itineraryPdf);

        for (let key in tripGallery) {
            formData.append("tripGallery", tripGallery[key])
        }
        formData.append("noOfInstallments", noOfInstallments)
        formData.append("installmentDueDates", JSON.stringify(installments))
        formData.append("highlights", JSON.stringify(addHighlight));
        formData.append("itineraries", JSON.stringify(addItinerary));

        setSubmitting(true);
        postMethod(endpoint, formData).then((res) => {
            if (res.success) {
                setShowToast(true);
                setShowMessage("Product Added Successfully");
            }
            else {
                setShowToast(true);
                setShowMessage(res.error);
            }
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const editProduct = (id) => {

        const endpoint = "product/updateproduct"
        const params = {
            "productId": id
        }
        const formData = new FormData();
        for (let key in tripGallery) {
            formData.append("tripGallery", tripGallery[key])
        }
      //  console.log(note)
        formData.append("tripName", tripName);
        formData.append("price", price);
        formData.append("sellingPrice", sellingPrice);
        formData.append("tripVideo", tripVideo);
        formData.append("tripLogoImage", tripLogoImage);
        formData.append("tripFeaturedImage", tripFeaturedImage);
        formData.append("bookingTripStartDate", bookingTripStartDate);
        formData.append("bookingTripEndDate", bookingTripEndDate);
        formData.append("tripGoingDate", tripGoingDate);
        formData.append("tripLeavingDate", tripLeavingDate);
        formData.append("nonRefundableDate", nonRefundableDate);
        formData.append("maximumBooking", maximumBooking);
        formData.append("gracePeriod", gracePeriod);
        formData.append("advancePrepaidAmountPercentage", advancePrepaidAmountPercentage);
        formData.append("termsAndConditions", termsAndConditions);
        formData.append("arrivalAirport", arrivalAirport);
        formData.append("departureAirport", departureAirport);
        formData.append("comingSoon", comingSoon);
        formData.append("earlyBirdPrice", earlyBirdPrice);
        formData.append("note", JSON.stringify(note));
        formData.append("slug", slug);
        formData.append("itineraryPdf", itineraryPdf);
        formData.append("noOfInstallments", noOfInstallments)
        formData.append("installmentDueDates", JSON.stringify(installments))
        formData.append("highlights", JSON.stringify(addHighlight));
        formData.append("itineraries", JSON.stringify(addItinerary));
        formData.append("refundCalender", JSON.stringify(refundCalender.filter(el => el.date && (el.refund || el.refund === 0))))

        setSubmitting(true);
        putMethod(endpoint, formData, params).then((res) => {
            setSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage("Product Updated Successfully");
            }
            else {
                setShowToast(true);
                setShowMessage(res.error);
            }
        })
    }

    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">{id ? "Edit Product" : "Add Product"}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <div id="accordion" className="accordion">
                            <div className="accordion-item">
                                <a href="#" className="accordion-head" data-toggle="collapse" data-target="#accordion-item-1">
                                    <h6 className="title">Product Info</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse show" id="accordion-item-1" data-parent="#accordion">
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-trip-name">Trip Name</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-trip-name"
                                                                name="tripName"
                                                                value={tripName}
                                                                placeholder="Enter Trip Name"
                                                                onChange={(e) => setTripName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-price">Price</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-price"
                                                                name="price"
                                                                value={price}
                                                                placeholder="Enter Price"
                                                                onChange={(e) => setPrice(e.target.value)}
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-sell-price">Selling Price</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-sell-price"
                                                                name="sellingPrice"
                                                                value={sellingPrice}
                                                                placeholder="Enter Selling Price"
                                                                onChange={(e) => setSellingPrice(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-max-booking">Maximum Booking</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-max-booking"
                                                                name="maximumBooking"
                                                                value={maximumBooking}
                                                                placeholder="Enter Maximum Number of Booking"
                                                                onChange={(e) => setMaximumBooking(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-book-start">Trip Booking Start Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-book-start"
                                                                name="bookingTripStartDate"
                                                                value={bookingTripStartDate}
                                                                placeholder="Enter Trip Booking Start"
                                                                onChange={(e) => setBookingTripStartDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-book-end">Trip Booking End Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-book-end"
                                                                name="bookingTripEndDate"
                                                                value={bookingTripEndDate}
                                                                placeholder="Enter Trip Booking End Date"
                                                                onChange={(e) => setBookingTripEndDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-trip-go">Trip Going Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-trip-go"
                                                                value={tripGoingDate}
                                                                name="tripGoingDate"
                                                                placeholder="Enter Trip Going Date"
                                                                onChange={(e) => setTripGoingDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-trip-leave">Trip Leaving Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-trip-leave"
                                                                value={tripLeavingDate}
                                                                name="tripLeavingDate"
                                                                placeholder="Enter Trip Leaving Date"
                                                                onChange={(e) => setTripLeavingDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-non-refund">Non Refundable Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-non-refund"
                                                                value={nonRefundableDate}
                                                                name="nonRefundableDate"
                                                                placeholder="Enter Non Refundable Date"
                                                                onChange={(e) => setNonRefundableDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-advance">Advance Amount Percentage</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-advance"
                                                                name="advancePrepaidAmountPercentage"
                                                                value={advancePrepaidAmountPercentage}
                                                                placeholder="Enter Advance amount in %"
                                                                onChange={(e) => setAdvancePrepaidAmountPercentage(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-grace">Grace Period</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-grace"
                                                                value={gracePeriod}
                                                                name="gracePeriod"
                                                                placeholder="Enter Grace Period"
                                                                onChange={(e) => setGracePeriod(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-slug">Slug</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-slug"
                                                                value={slug}
                                                                name="slug"
                                                                placeholder="Enter Slug"
                                                                onChange={(e) => setSlug(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-arrivalAirport">Arrival Airport</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-arrivalAirport"
                                                                value={arrivalAirport}
                                                                name="arrivalAirport"
                                                                placeholder="Enter Arrival Airport"
                                                                onChange={(e) => setArrivalAirport(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-deptAirport">Departure Airport</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-deptAirport"
                                                                value={departureAirport}
                                                                name="departureAirport"
                                                                placeholder="Enter Departure Airport"
                                                                onChange={(e) => setDepartureAirport(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-terms">Terms and Conditions</label>
                                                        <div className="form-control-wrap">
                                                            <textarea
                                                                className="form-control"
                                                                id="fv-terms"
                                                                value={termsAndConditions}
                                                                name="termsAndConditions"
                                                                placeholder="Enter Terms and Conditions"
                                                                onChange={(e) => setTermsAndConditions(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-terms">Coming Soon</label>
                                                        <div className="form-control-wrap">
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input" checked={comingSoon ? "checked" : null} onChange={(e) => setComingSoon(e.target.checked)} id="customSwitch2" />
                                                                <label className="custom-control-label" htmlFor="customSwitch2"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-earlyBirdPrice">EarlyBird Price</label>
                                                        <div className="form-control-wrap">
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input" checked={earlyBirdPrice ? "checked" : null} onChange={(e) => setEarlyBirdPrice(e.target.checked)} id="customSwitchPrice" />
                                                                <label className="custom-control-label" htmlFor="customSwitchPrice"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-notes">
                                    <h6 className="title">Notes</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-notes" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            {note.map((noteEl, i) => (
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="fv-note">Note</label>
                                                    <div className="form-control-wrap">
                                                        <textarea
                                                            className="form-control"
                                                            id="fv-note"
                                                            value={noteEl}
                                                            name="note"
                                                            placeholder="Enter Note"
                                                            onChange={({ target }) => setNote(state => {
                                                                let updated = [...state];
                                                                updated[i] = target.value;
                                                                return updated;
                                                            })}
                                                            required
                                                        />
                                                    </div>
                                                    <span className="text-danger pointer b-5 d-inline-block mt-2" onClick={() => removeNote(i)}>
                                                        <i className="ni ni-minus-circle"></i> Remove
                                                    </span>
                                                </div>
                                            ))}
                                            <span className="b-5 pointer text-primary" onClick={addNote}><i className="ni ni-plus-circle"></i> Add Note</span>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-2">
                                    <h6 className="title">Product Installments</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-2" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-installments">Number of Installments</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                className="form-control"
                                                                id="fv-installments"
                                                                value={noOfInstallments}
                                                                name="noOfInstallments"
                                                                placeholder="Enter Number of Installments"
                                                                onChange={(e) => setNoOfInstallments(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {props?.location?.state?.id ?
                                                    noOfInstallments === noOfInstall ?
                                                        installments?.map((val, i) => {
                                                            return (
                                                                <div className="col-md-6" key={i}>
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor={`fv-installments${i}`}>Installment Due Date  {i + 1}</label>
                                                                        <div className="form-control-wrap">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                value={val}
                                                                                id={`fv-installments${i}`}
                                                                                name={"installmentDueDates" + i}
                                                                                onChange={({ target }) => {
                                                                                    setInstallments(state => {
                                                                                        const updated = [...state];
                                                                                        updated[i] = target.value;
                                                                                        return updated;
                                                                                    })
                                                                                }}
                                                                            />
                                                                            {/* <span className="form-control">{dateConvert(val)}</span> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        arr.map((_, i) => {
                                                            return (
                                                                <div className="col-md-6" key={i}>
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor={`fv-installments${i}`}>Installment Due Date  {i + 1}</label>
                                                                        <div className="form-control-wrap">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id={`fv-installments${i}`}
                                                                                name={"installmentDueDates" + i}
                                                                                placeholder={"Installment Due Date " + (i + 1)}
                                                                                onChange={({ target }) => {
                                                                                    setInstallments(state => {
                                                                                        const updated = [...state];
                                                                                        updated[i] = target.value;
                                                                                        return updated;
                                                                                    })
                                                                                }}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                    noOfInstallments ?
                                                        arr.map((_, i) => {
                                                            return (
                                                                <div className="col-md-6" key={i}>
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor={`fv-installments${i}`}>Installment Due Date  {i + 1}</label>
                                                                        <div className="form-control-wrap">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id={`fv-installments${i}`}
                                                                                name={"installmentDueDates" + i}
                                                                                placeholder={"Installment Due Date " + (i + 1)}
                                                                                onChange={(e) =>
                                                                                    setInstallments((prev) => {
                                                                                        return {
                                                                                            ...prev,
                                                                                            [e.target.name]: e.target.value
                                                                                        }
                                                                                    })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-refundCalender">
                                    <h6 className="title">Refund Calender</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-refundCalender" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            {refundCalender.map((el, index) => (
                                                <div key={index} className={clsx('p-3 mb-3', styles.rcFieldset)}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="fv-refund-date">Date</label>
                                                                <div className="form-control-wrap">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id="fv-refund-date"
                                                                        value={el.date}
                                                                        name="date"
                                                                        placeholder="Date"
                                                                        onChange={(e) => {
                                                                            let { name, value } = e.target;
                                                                            updateRefundCalendarValue(name, value, index);
                                                                        }}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="fv-refund-type">Type</label>
                                                                <div className="form-control-wrap">
                                                                    <select
                                                                        className="form-control"
                                                                        id="fv-refund-type"
                                                                        value={el.refundType}
                                                                        name="refundType"
                                                                        onChange={(e) => {
                                                                            let { name, value } = e.target;
                                                                            updateRefundCalendarValue(name, value, index);
                                                                        }}
                                                                        required
                                                                    >
                                                                        <option value="fully">Fully</option>
                                                                        <option value="partial">Partial</option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="fv-refund">Refund Amount (%)</label>
                                                                <div className="form-control-wrap">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="fv-refund"
                                                                        placeholder="E.g. 80"
                                                                        min={0}
                                                                        max={100}
                                                                        name="refund"
                                                                        value={el.refund}
                                                                        onChange={(e) => {
                                                                            let { name, value } = e.target;
                                                                            updateRefundCalendarValue(name, value, index);
                                                                        }}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a className="text-danger b-5 d-inline-block" href="javascript:void(0)" onClick={() => removeRefundCalendar(index)}><i className="align-middle ni ni-minus-circle"></i> remove</a>
                                                </div>

                                            ))}

                                            <a className="b-5" href="javascript:void(0)" onClick={addRefundCalendar}><i className="align-middle ni ni-plus-circle"></i> Add Fields</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-3">
                                    <h6 className="title">Product Images</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-3" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Logo Image</label>
                                                        {props?.location?.state?.prop ?
                                                            <div className="form-control-wrap">
                                                                {tripLogoImage != "[object File]" ?
                                                                    <label htmlFor="customLogo">
                                                                        <Image src={process.env.REACT_APP_IMG_URL + tripLogoImage} className="image-container1" />
                                                                    </label>
                                                                    :
                                                                    <label htmlFor="customLogo">
                                                                        <Image src={URL.createObjectURL(tripLogoImage)} className="image-container1" />
                                                                    </label>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="form-control-wrap">
                                                                {tripLogoImage ?
                                                                    <label htmlFor="customLogo">
                                                                        <Image src={URL.createObjectURL(tripLogoImage)} className="image-container1" />
                                                                    </label>
                                                                    :
                                                                    <label htmlFor="customLogo">
                                                                        <div className="image-container1">
                                                                            <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' />
                                                                        </div>
                                                                    </label>
                                                                }
                                                            </div>
                                                        }
                                                        <input
                                                            id="customLogo"
                                                            type="file"
                                                            name="tripLogoImage"
                                                            onChange={(e) => setTripLogoImage(e.target.files[0])}
                                                        // onChange={handleImageChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Featured Image</label>
                                                        {props?.location?.state?.prop ?
                                                            <div className="form-control-wrap">
                                                                {tripFeaturedImage != "[object File]" ?
                                                                    <label htmlFor="customFeatured">
                                                                        <Image src={process.env.REACT_APP_IMG_URL + tripFeaturedImage} className="image-container1" />
                                                                    </label>
                                                                    :
                                                                    <label htmlFor="customFeatured">
                                                                        <Image src={URL.createObjectURL(tripFeaturedImage)} className="image-container1" />
                                                                    </label>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="form-control-wrap">
                                                                {tripFeaturedImage ?
                                                                    <label htmlFor="customFeatured">
                                                                        <Image src={URL.createObjectURL(tripFeaturedImage)} className="image-container1" />
                                                                    </label>
                                                                    :
                                                                    <label htmlFor="customFeatured">
                                                                        <div className="image-container1">
                                                                            <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' />
                                                                        </div>
                                                                    </label>
                                                                }
                                                            </div>
                                                        }
                                                        <input
                                                            id="customFeatured"
                                                            type="file"
                                                            name="tripFeaturedImage"
                                                            onChange={(e) => setTripFeaturedImage(e.target.files[0])}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Video</label>
                                                        {props?.location?.state?.prop ?
                                                            <div className="form-control-wrap">
                                                                {
                                                                    tripVideo != "[object File]" ?
                                                                        <label htmlFor="customVideo">
                                                                            <video height="100" autoPlay muted src={process.env.REACT_APP_IMG_URL + tripVideo} className="image-container1" />
                                                                        </label>
                                                                        :
                                                                        <label htmlFor="customVideo">
                                                                            <video height="100" src={URL.createObjectURL(tripVideo)} className="image-container1" />
                                                                        </label>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="form-control-wrap">
                                                                {tripVideo ?
                                                                    <label htmlFor="customVideo">
                                                                        <video src={URL.createObjectURL(tripVideo)} className="image-container1" />
                                                                    </label>
                                                                    :
                                                                    <label htmlFor="customVideo">
                                                                        <div className="image-container1">
                                                                            <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' />
                                                                        </div>
                                                                    </label>
                                                                }

                                                            </div>
                                                        }
                                                        <input
                                                            id="customVideo"
                                                            type="file"
                                                            name="tripVideo"
                                                            onChange={(e) => setTripVideo(e.target.files[0])}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Gallery</label>
                                                        {props?.location?.state?.prop ?
                                                            <div className="form-control-wrap">
                                                                {tripGallery != "[object File]" ?
                                                                    <div style={{ display: "block", overflow: "auto" }}>
                                                                        {tripGallery?.map((val, i) => {
                                                                            if (val != "[object File]") {
                                                                                return (
                                                                                    <img key={i} className="image-container1 mr-3 mb-3" src={process.env.REACT_APP_IMG_URL + val} alt="gallery" />
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <img key={i} className="image-container1 mr-3 mb-3" src={URL.createObjectURL(val)} alt="gallery" />
                                                                                )
                                                                            }

                                                                        })
                                                                        }
                                                                        <label htmlFor="customGallery">
                                                                            <div className="image-container1">
                                                                                <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>

                                                            :
                                                            <div className="form-control-wrap">
                                                                {
                                                                    tripGallery ?
                                                                        <div style={{ display: "block", overflow: "auto" }}>
                                                                            {tripGallery?.map((val, i) => {
                                                                                return (
                                                                                    <img key={i} className="image-container1 mr-3 mb-3" src={URL.createObjectURL(val)} alt="gallery" />
                                                                                )
                                                                            })
                                                                            }
                                                                            <label htmlFor="customGallery">
                                                                                <div className="image-container1">
                                                                                    <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                                                    {/* <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' /> */}
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        :
                                                                        <label htmlFor="customGallery">
                                                                            <div className="image-container1">
                                                                                <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                                                {/* <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' /> */}
                                                                            </div>
                                                                        </label>
                                                                }
                                                            </div>
                                                        }
                                                        <input
                                                            id="customGallery"
                                                            type="file"
                                                            name="tripGallery"
                                                            multiple
                                                            onChange={(e) => setTripGallery(Object.values(e.target.files))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-4">
                                    <h6 className="title">Product Highlights</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-4" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        {
                                            addHighlight ?
                                                addHighlight.map((val, index) => {
                                                    return (
                                                        <div className="col-md-12 pb-3" key={index} style={{ borderBottom: "2px solid #ececec" }}>
                                                            <AddHighlight prodId={prodId} highId={val._id} data={val} highlight={addHighlight} setHighlight={setAddHighlight} index={index} showToast={setShowToast} showMessage={setShowMessage} />
                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        <div className="col-md-12 pt-3">
                                            <Button className="add-button" onClick={() => {
                                                setAddHighlight(prev => [...prev,
                                                {
                                                    "heading": "",
                                                    "highlight": []
                                                }])
                                            }}>Add New</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-5">
                                    <h6 className="title">Product Itineraries</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-5" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        {
                                            addItinerary ?
                                                addItinerary?.map((val, index) => {
                                                    return (
                                                        <div className="col-md-12 pb-3" key={index} style={{ borderBottom: "2px solid #ececec" }}>
                                                            <Itinerary data={val} itinerary={addItinerary} prodId={prodId} itId={val._id} setItinerary={setAddItinerary} index={index} showToast={setShowToast} showMessage={setShowMessage} />
                                                        </div>
                                                    )

                                                })
                                                :
                                                null
                                        }
                                        <div className="col-md-12 pt-3">
                                            <Button className="add-button" onClick={() => {
                                                setAddItinerary(prev => [...prev,
                                                {
                                                    "heading": "",
                                                    "itinerary": []
                                                }
                                                ])
                                            }}>Add New</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-6">
                                    <h6 className="title">Product Itineraries Brochure</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-6" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="brochure">Brochure</label>
                                                        {showBroc ?
                                                            <span className="profile-ud-label pb-2">{itineraryPdf}</span>
                                                            :
                                                            null
                                                        }
                                                        <div className="form-control-wrap abc">
                                                            <input
                                                                type="file"
                                                                id="brochure"
                                                                name="itineraryPdf"
                                                                onChange={(e) => { setShowBroc(false); setItineraryPdf(e.target.files[0]) }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting..' : id ? "Edit Product" : "Add Product"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default AddProduct;