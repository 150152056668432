import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import ToastMessage from "../../../../Components/Toast/Toast";
import { deleteMethod, getMethod } from "../../../../Services/ApiServices";
import MUIDataTable from "mui-datatables";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { format } from 'fecha';
const ProductsList = (props) => {
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [searchToggle, setSearchToggle] = useState(false);
    const [prevVal, setPrevVal] = useState(1);
    const [nextVal, setNextVal] = useState(10);
    const [search, setSearch] = useState("");
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    const Inr = (arg) => {
        let inr = new Intl.NumberFormat('en-IN').format(arg)
        return inr
    }

    async function fetchProducts() {
        const endpoint = "product/both"
        await getMethod(endpoint).then((res) => {
            if (res.success) {
                setProducts(res.data)
            }
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const searchVal = (e) => {
        setSearch(e.target.value);
        const endpoint = "product/both"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10,
            "search": e.target.value
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setProducts(res.data);
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)
    }

    const previous = () => {
        const endpoint = "product/both"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setProducts(res.data)
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)

    }
    const next = (currentPage) => {
        const endpoint = "product/both"
        const p = page >= 1 ? page + 1 : 1
        var params = {
            "page": p,
            "limit": 10
        }
        if (currentPage) {
            params = {
                "page": currentPage,
                "limit": 10
            }
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setProducts(res.data)
            }
        })
        if (currentPage) {

        } else {
            setPage(p);
            setPrevVal((p * 10) - 9)
            setNextVal(p * 10)
        }
    }

    const data = products?.map((item, index) => {
        const ar = [];
        ar.push(
            item?.tripName ? item?.tripName : '-',
            item?.sellingPrice ? `INR ${Inr(item?.sellingPrice)}` : '-',
            item?.tripGoingDate ? format(new Date(item?.tripGoingDate), 'Do MMM YYYY') : '-',
            item?.tripLeavingDate ? format(new Date(item?.tripLeavingDate), 'Do MMM YYYY') : '-',
            item?.installments ? `${item?.installments.length} installments` : "-",
            item?.createdAt ? format(new Date(item?.createdAt), 'Do MMM YYYY') : '-',
            item?._id ?
                <div className="drodown">
                    <a className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            <li><a onClick={() => editProduct(item?.slug, item?._id)}><em className="icon ni ni-focus"></em><span>View Products</span></a></li>
                            <li><a onClick={() => deleteProduct(item?._id, index)}><em className="icon ni ni-trash"></em><span>Delete Product</span></a></li>
                        </ul>
                    </div>
                </div>
                :
                null
        );
        return ar;
    })

    const columns = [
        "Name",
        "Amount",
        "Going Date",
        "Leaving Date",
        "Installments",
        "Created At",
        "Action"
    ];
    const options = {
        filter: false,
        filterType: "dropdown",
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        search: false
    };
    const editProduct = (slug, id) => {
        history.push('/add-product', { id: slug, prop: id })
    }
    const deleteProduct = (id, index) => {
        const endpoint = "product/deleteproduct"
        const params = {
            "productId": id
        }
        deleteMethod(endpoint, params).then((res) => {
            if (res.status) {
                setShowToast(true);
                products.splice(index, 1);
                setShowMessage("Product deleted Successfully");

            }
            else {
                setShowToast(true);
                setShowMessage(res.error);
            }
        })
    }


    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Products</h3>
                                </div>
                                <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><Link to="/add-product" className="btn btn-white btn-outline-light"><span>Add New Product</span></Link></li>                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="box">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <div className="card-tools mr-n1">
                                            <p className="mb-0 p-text">All Products</p>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a onClick={(e) => setSearchToggle(true)} className="btn btn-icon search-toggle toggle-search" data-target="search"><em className="icon ni ni-search"></em></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={searchToggle ? "card-search search-wrap active" : "card-search search-wrap"} data-search="search">
                                        <div className="card-body">
                                            <div className="search-content">
                                                <a onClick={(e) => { setSearchToggle(false); setSearch("") }} className="search-back btn btn-icon toggle-search" data-target="search"><em className="icon ni ni-arrow-left"></em></a>
                                                <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={search} onChange={searchVal} />
                                                <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </div>
                        <div className="pagination-row box">
                            <div className="fr_jE_aI">
                                <p className="mb-0">Page No. {page}</p>
                            </div>
                            <div className="mx-4 fr_jE_aI">
                                <p className="mb-0">{prevVal}</p>
                                <p className="mb-0">-</p>
                                <p className="mb-0">{nextVal}</p>
                            </div>
                            <button className="btn_none" disabled={page == 1 ? true : false} onClick={() => previous()}><FaAngleLeft size={20} color="#999999" /></button>
                            <button className="btn_none" disabled={products?.length < 10 ? true : false} onClick={() => next(0)}><FaAngleRight size={20} color="#999999" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ProductsList;