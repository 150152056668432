import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
import { postMethod, putMethod } from "../../../../Services/ApiServices";
import { format } from 'fecha';
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';
const InstallmentsList = (props) => {
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role
    const agentId = JSON.parse(localStorage.getItem(LS_USER_DATA))?._id ? JSON.parse(localStorage.getItem(LS_USER_DATA))?._id : false;
    const [payuId, setPayuId] = useState("");
    const [amount, setAmount] = useState("");
   // console.log(props.key, props?.val)
    const [installmentStatus, setInstallmentStatus] = useState(props?.val?.installmentStatus==='pending' && props?.val?.isSkip?'skipped':props?.val?.installmentStatus)
    const [showModal, setShowModal] = useState(false)
    const Inr = (arg) => {
        let inr = new Intl.NumberFormat('en-IN').format(arg)
        return inr
    }
    const bookingStatus = [
        { id: 0, value: "pending" },
        { id: 1, value: "lock" },
        { id: 2, value: "paid" },
        { id: 2, value: "skipped" }
    ]

    const handleInstallMentsByAgent = async (installmentData, getBookingData) => {
        console.log(installmentData, getBookingData)
        var data = {
            bookingId: getBookingData?.paymentId?.bookingId,
            installmentId: installmentData?._id,
            installmentPayment: installmentData?.installmentAmount,
            installmentNumber: installmentData?.installmentNumber
        }
        const redirectToPayU = async (paymentDetails, isInstallments) => {
            var fr = '<form action=\"' + process.env.REACT_APP_PAYU_URL + '\" method=\"post\">' +
                '<input type=\"hidden\" name=\"key\" value=\"' + paymentDetails.key + '\" />' +
                '<input type=\"hidden\" name=\"salt\" value=\"' + paymentDetails.salt + '\" />' +
                '<input type=\"hidden\" name=\"txnid\" value=\"' + paymentDetails.txnid + '\" />' +
                '<input type=\"hidden\" name=\"amount\" value=\"' + paymentDetails.amount + '\" />' +
                '<input type=\"hidden\" name=\"productinfo\" value=\"' + paymentDetails.productinfo + '\" />' +
                '<input type=\"hidden\" name=\"firstname\" value=\"' + paymentDetails.firstname + '\" />' +
                '<input type=\"hidden\" name=\"email\" value=\"' + paymentDetails.email + '\" />' +
                '<input type=\"hidden\" name=\"phone\" value=\"' + paymentDetails.phone + '\" />' +
                '<input type=\"hidden\" name=\"surl\" value=\"' + paymentDetails.surl + '\" />' +
                '<input type=\"hidden\" name=\"furl\" value=\"' + paymentDetails.furl + '\" />' +
                '<input type=\"hidden\" name=\"udf1\" value=\"' + paymentDetails.udf1 + '\" />' +
                '<input type=\"hidden\" name=\"udf2\" value=\"' + paymentDetails.udf2 + '\" />' +
                '<input type=\"hidden\" name=\"udf3\" value=\"' + paymentDetails.udf3 + '\" />' +
                '<input type=\"hidden\" name=\"udf4\" value=\"' + paymentDetails.udf4 + '\" />' +
                '<input type=\"hidden\" name=\"udf5\" value=\"' + paymentDetails.udf5 + '\" />' +
                '<input type=\"hidden\" name=\"udf6\" value=\"' + paymentDetails.udf6 + '\" />' +
                '<input type=\"hidden\" name=\"hash\" value=\"' + paymentDetails.hash + '\" />' +
                '</form>';
            var form = $(fr);
            $('body').append(form);
            form.submit();
        }
        await postMethod('booking/pay/installment/agent', data).then(async (response) => {
            if (response.success) {
                let payuDetails = {
                    key: process.env.REACT_APP_PAYU_KEY,
                    salt: process.env.REACT_APP_PAYU_SALT,
                    txnid: uuidv4(),
                    amount: Math.round(response.payment.amount),
                    firstname: JSON.parse(localStorage.getItem(LS_USER_DATA))?.name ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.name : '',
                    lastName: '',
                    email: JSON.parse(localStorage.getItem(LS_USER_DATA))?.email ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.email : '',
                    phone: JSON.parse(localStorage.getItem(LS_USER_DATA))?.phone?.mobile ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.phone?.mobile : '',
                    productinfo: getBookingData?.productDetails?.tripName ? getBookingData?.productDetails?.tripName : '',
                    hash: '',
                    surl: process.env.REACT_APP_API_URL + 'api/v1/booking/pay/installment/agent/payment',
                    furl: process.env.REACT_APP_API_URL + 'api/v1/booking/pay/installment/agent/payment',
                    bookingId: response.payment.bookingId,
                    paymentId: response.payment._id,
                    installmentId: data.installmentId,
                    installmentNumber: data.installmentNumber,
                    installmentPayment: data.installmentPayment,
                    udf1: response.payment.bookingId,
                    udf2: response.payment._id,
                    udf3: JSON.parse(localStorage.getItem(LS_USER_DATA))?._id ? JSON.parse(localStorage.getItem(LS_USER_DATA))?._id : '',
                    udf4: data.installmentId,
                    udf5: data.installmentNumber,
                    udf6: data.installmentPayment
                }
                let payuHashDetails = {
                    'isInstallment': true,
                    'txnid': payuDetails.txnid,
                    'email': payuDetails.email,
                    'amount': payuDetails.amount,
                    'productinfo': payuDetails.productinfo,
                    'firstname': payuDetails.firstname,
                    "bookingId": payuDetails.bookingId,
                    "paymentId": payuDetails?.paymentId,
                    "installmentId": payuDetails.installmentId,
                    "installmentNumber": payuDetails.installmentNumber,
                    "installmentPayment": payuDetails.installmentPayment,
                    "udf1": payuDetails?.udf1,
                    "udf2": payuDetails?.udf2,
                    "udf3": payuDetails?.udf3,
                    "udf4": payuDetails?.udf4,
                    "udf5": payuDetails?.udf5,
                    "udf6": payuDetails?.udf6
                }
                await postMethod('payment/payumoney2', payuHashDetails).then(async response => {
                    if (response.hash) {
                        payuDetails.hash = response.hash;
                        await redirectToPayU(payuDetails, true);
                    } else {
                        toast.error(response.message)
                        setIsSubmitting(false);
                        eventTrack('Purchase', Object.assign(parseData, { status: false, message: 'Payu hash code invalid!' }))
                    }
                })
            } else {
                props?.showToast(true);
                props?.showMessage(res.error);
            }
            console.log(response)
        })
    }
    const updateStatus = (e) => {
        setInstallmentStatus(e.target.value);
        if (e.target.value === 'paid') {
            if (role === 'Admin') {
                setShowModal(true)
                setAmount(props?.val?.installmentAmount)
            } else {
                handleInstallMentsByAgent(props?.val, props?.bookingDetails)
            }
        }

    }

    const handleSubmit = (e) => {
        if (!payuId) {
            props?.showToast(true);
            props?.showMessage("Please add Payu Id")
            return
        }
        const endpoint = "booking/pay/installment/admin";

        const data = {
            "bookingId": props?.bookingId,
            "installmentId": props?.val?._id,
            "installmentNumber": props?.val?.installmentNumber,
            "payuId": payuId,
            "installmentPayment": amount
        }
        postMethod(endpoint, data).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Installment Paid Successfully");
                setShowModal(false)
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })
    }

    return (
        <>
            <div className="nk-tb-item" >
                <div className="nk-tb-col">
                    <div className="user-info">
                        <span className="tb-lead">{props?.val?.installmentNumber + 1}</span>
                    </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                    <span className="tb-lead">INR {Inr(props?.val?.installmentAmount)}</span>
                </div>
                <div className="nk-tb-col tb-col-md">
                    <span className="tb-lead">Installment Amount</span>
                </div>
                <div className="nk-tb-col tb-col-md">
                    <span className="tb-lead">{format(new Date(props?.val?.installmentDueDate), 'Do MMM YYYY')}</span>
                </div>
                <div className="nk-tb-col tb-col-md">
                    <select name="installmentStatus" disabled={['paid']?.includes(installmentStatus)? true : false} value={props?.checkNormal ? 'lock' : installmentStatus} onChange={updateStatus}>
                        <option value="">Select Status</option>
                        {
                            bookingStatus.map((val, index) => {
                                return (
                                    <option key={index} value={val.value}>{val.value}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            {props?.val?.paymentId ?
                <div className="nk-tb-item">
                    <div className="nk-tb-col">
                        <span className="tb-lead">Payment Id: {props?.val?.paymentId?.payuMoneyId ? props?.val?.paymentId?.payuMoneyId : '-'}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Amount: {props?.val?.paymentId?.amount ? Math.round((props?.val?.paymentId?.amount)).toFixed(2) : '-'}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Paid Date: {props?.val?.paymentId?.updatedAt ? format(new Date(props?.val?.paymentId?.updatedAt), 'Do MMM YYYY') : '-'}</span>
                    </div>
                    <div className="nk-tb-col"></div>
                    <div className="nk-tb-col"></div>
                </div>
                :
                null
            }

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Pay Installments
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-bordered">
                        <div className="card-inner">
                            <form className="form-validate">
                                <div className="row g-gs">
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fv-payuId">Payu Id</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fv-payuId"
                                                    name="payuId"
                                                    value={payuId}
                                                    placeholder="Enter Payu Id"
                                                    onChange={(e) => {
                                                        setPayuId(e.target.value)
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fv-amount">Amount</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fv-amount"
                                                    name="amount"
                                                    value={amount}
                                                    placeholder="Enter Amount"
                                                    // onChange={(e) => {
                                                    //     setAmount(e.target.value)
                                                    // }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row fr_jc pr-3 py-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default InstallmentsList;