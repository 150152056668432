import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import Footer from "../Components/Footer/Footer";
import Sidebar from "../Components/Sidebar/Sidebar";
import Topbar from "../Components/Topbar/Topbar";
import Login from "../Pages/Auth/Login/Login";
import Register from "../Pages/Auth/Register/Register";
import Dashboard from "../Pages/Dashboard/Dashboard";
import UserList from "../Pages/Applications/Users/UserList/UserList";
import ProductsList from "../Pages/Applications/Products/ProductsList/ProductsList";
import AddProduct from "../Pages/Applications/Products/AddProduct/AddProduct";
import BookingList from "../Pages/Applications/Booking/BookingList/BookingList";
import PendingBookingList from "../Pages/Applications/Booking/BookingList/PendingBookingList";
import ConfirmedBookingList from "../Pages/Applications/Booking/BookingList/ConfirmedBookingList";
import CancelledBookingList from "../Pages/Applications/Booking/BookingList/CancelledBookingList";
import DeclinedBookingList from "../Pages/Applications/Booking/BookingList/DeclinedBookingList";

import CompletedBookingList from "../Pages/Applications/Booking/BookingList/CompletedBookingList";
import InstallmentPendingBookingList from "../Pages/Applications/Booking/BookingList/InstallmentPendingBookingList";
import AddBooking from "../Pages/Applications/Booking/AddBooking/AddBooking";
import BookingDetail from "../Pages/Applications/Booking/BookingDetail/BookingDetail";
import AddHighlight from "../Pages/Applications/Highlights/AddHighlight";
import UserDetail from "../Pages/Applications/Users/UserDetail/UserDetail";
import AddAgent from "../Pages/Applications/Agents/AddAgent/AddAgent";
import RequestList from "../Pages/Applications/RequestManagement/RequestList/RequestList";
import AgentList from "../Pages/Applications/Agents/AgentList/AgentList";
import RequestDetail from "../Pages/Applications/RequestManagement/RequestDetail/RequestDetail";
import HomeGallery from "../Pages/Applications/Gallery/HomeGallery/HomeGallery";
import ProductGalleryList from "../Pages/Applications/Gallery/ProductGallery/ProductGalleryList";
import { UserContext } from "../Contexts/UserContext";
import UserProfile from "../Pages/Applications/Users/UserProfile/UserProfile";
import AddUser from "../Pages/Applications/Users/UserProfile/AddUser";
import AddAgentBooking from "../Pages/Applications/Booking/AddBooking/AddAgentBooking";
import AddBlog from "../Pages/Applications/Blogs/AddBlog/AddBlog";
import BlogList from "../Pages/Applications/Blogs/BlogList/BlogList";
import AddTag from "../Pages/Applications/Tags/AddTag/AddTag";
import TagList from "../Pages/Applications/Tags/TagsList/TagList";

import CouponList from "../Pages/Applications/Coupons/CouponList/CouponList";
import AddCoupon from "../Pages/Applications/Coupons/AddCoupon/AddCoupon";



const PublicRoute = ({ children, component, ...rest }) => {
    const userContext = React.useContext(UserContext);
    let Component = component;

    return (
        <Route
            {...rest}
            render={(props) =>
                !userContext.state.isLoggedin ? <Component {...props} /> : <Redirect to="/bookings" />
            }
        />
    );
}

const PrivateRoute = ({ children, component, role, ...rest }) => {
    let Component = component;
    const userContext = React.useContext(UserContext);

    const { isLoggedin } = userContext.state;

    const isAuthorized = () => {
        let { user } = userContext.state
        return user && role.includes(user.role);
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedin ? (isAuthorized(role) ? <Component {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

const Routes = () => {
    return (
        <Switch>
            <PublicRoute path='/' exact component={Login} />
            <PublicRoute path='/login' exact component={Login} />
            <PublicRoute path='/register' exact component={Register} />
            <div className="nk-body bg-lighter npc-general has-sidebar ">
                <div className="nk-app-root">
                    <div className="nk-main">
                        <Sidebar />
                        <div className="nk-wrap">
                            <Topbar />
                            <PrivateRoute exact path='/dashboard' role={['Admin', 'Agent']} component={Dashboard} />
                            <PrivateRoute exact path='/users' role={['Admin']} component={UserList} />
                            <PrivateRoute exact path='/user-detail' role={['Admin']} component={UserDetail} />
                            <PrivateRoute exact path='/products' role={['Admin']} component={ProductsList} />
                            <PrivateRoute exact path='/add-product' role={['Admin']} component={AddProduct} />
                            <PrivateRoute exact path='/blogs' role={['Admin']} component={BlogList} />
                            <PrivateRoute exact path='/add-blog' role={['Admin']} component={AddBlog} />
                            <PrivateRoute exact path='/edit-blog' role={['Admin']} component={AddBlog} />
                            <PrivateRoute exact path='/tags' role={['Admin']} component={ TagList } />
                            <PrivateRoute exact path='/add-tag' role={['Admin']} component={ AddTag } />
                            <PrivateRoute exact path='/edit-tag' role={['Admin']} component={AddTag} />
                            <PrivateRoute exact path='/booked-bookings' role={['Admin', 'Agent']} component={BookingList} />
                            <PrivateRoute exact path='/pending-bookings' role={['Admin', 'Agent']} component={PendingBookingList} />
                            <PrivateRoute exact path='/Installment-pending-bookings' role={['Admin', 'Agent']} component={InstallmentPendingBookingList} />
                            <PrivateRoute exact path='/complete-bookings' role={['Admin', 'Agent']} component={CompletedBookingList} />
                            <PrivateRoute exact path='/confirmed-bookings' role={['Admin', 'Agent']} component={ConfirmedBookingList} />
                            <PrivateRoute exact path='/cancelled-bookings' role={['Admin', 'Agent']} component={CancelledBookingList} />
                            <PrivateRoute exact path='/declined-bookings' role={['Admin', 'Agent']} component={DeclinedBookingList} />

                            <PrivateRoute exact path='/booking-detail/:id' role={['Admin', 'Agent']} component={BookingDetail} />
                            <PrivateRoute exact path='/add-highlight' role={['Admin']} component={AddHighlight} />
                            <PrivateRoute exact path='/add-agent' role={['Admin']} component={AddAgent} />
                            <PrivateRoute exact path='/request-list' role={['Admin']} component={RequestList} />
                            <PrivateRoute exact path='/request-detail' role={['Admin']} component={RequestDetail} />
                            <PrivateRoute exact path='/agent-list' role={['Admin']} component={AgentList} />
                            <PrivateRoute exact path='/home-gallery' role={['Admin']} component={HomeGallery} />
                            <PrivateRoute exact path='/product-gallery' role={['Admin']} component={ProductGalleryList} />
                            <PrivateRoute exact path='/user-profile' role={['Admin', 'Agent']} component={UserProfile} />
                            <PrivateRoute exact path='/add-booking' role={['Admin']} component={AddBooking} />
                            <PrivateRoute exact path='/add-agent-booking' role={['Agent']} component={AddAgentBooking} />
                            <PrivateRoute exact path='/add-user' role={['Admin']} component={AddUser} />

                            <PrivateRoute exact path='/coupons' role={['Admin']} component={CouponList} />
                            <PrivateRoute exact path='/add-coupon' role={['Admin']} component={AddCoupon} />
                            <PrivateRoute exact path='/edit-coupon' role={['Admin']} component={AddCoupon} />
                            
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

        </Switch>

    );
}
export default Routes;