import React, { useEffect, useState, useRef } from "react";
import 'react-phone-input-2/lib/style.css';
import { useLocation, useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import { Editor } from '@tinymce/tinymce-react';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const AddTag = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [title, setTitle] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [editCase, setEditCase] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    useEffect(() => {
        if (window.location.pathname === "/edit-tag" && query.get("id") != "") {
            setEditCase(true)
            const endpoint = "blog-relateds/single";
            const params = {
                "id": query.get("id")
            }
            getMethod(endpoint, params).then((res) => {
                if (res.success) {
                  setTitle(res?.data?.name);
                }
            })
        }
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        if (editCase) {
            update()
        }
        else {
            add()
        }

    }
    const update = () => {
        const endpoint = "blog-relateds/update";
        const params = {
            "id": query.get("id")
        }
        let data;
        if (title) {
            data = {
               name:title,  
            }
        }
        
        setIsSubmitting(true);
        putMethod(endpoint, data, params).then((res) => {
            setIsSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage("Updated Successfully");
            }
            else {
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }
    const add = () => {
        const endpoint = "blog-relateds/add";
      let data = {
            name:title,
           
         }
         console.log(data);
        if (!data) {
            setShowToast(true);
            setShowMessage("All fields are required");
        }
        setIsSubmitting(true);
        postMethod(endpoint, data).then((res) => {
            setIsSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage("Added Successfully");
                history.goBack();
            }
            else {
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">{editCase? "Update Tag" : "Add Tag"}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-name">Title</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-name"
                                                name="name"
                                                value={title}
                                                placeholder="Enter Name"
                                                onChange={(e) => setTitle(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                             
                              
                               
                            </div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting..' : props?.location?.state?.id ? "Update" : "Add"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTag;