import React, { useEffect, useState } from "react";
import { Image, Row, Col, Button, Form } from "react-bootstrap";
import { IoCamera, IoTrashOutline } from "react-icons/io5";
import { postMethod, putMethod } from "../../../../Services/ApiServices";


const Features = (props) => {
    const [showFeatures, setShowFeatures] = useState(true);
    const [flag, setFlag] = useState(false);
    const [img, setImg] = useState("");

    // useEffect(()=>{
    //     let arr = props.highlight;
    //     arr[props.index] = {
    //         ...arr[props.index],
    //         "highlight": features
    //     }
    //     props.setHighlight(arr)
    // },[])

    const [featuresDetail, setFeaturesDetail] = useState({
        "highlightTitle": "",
        "highlightDescription": ""
    })

    useEffect(() => {
        setFeaturesDetail({
            "highlightTitle": props?.data?.highlightTitle,
            "highlightDescription": props?.data?.highlightDescription
        })
        setImg(props?.data.highlightImages[0])
    }, [props])

    const handleFeatureDetail = (e) => {
        setFeaturesDetail((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
        let arr = props.features;
        arr[props.index] = { ...arr[props.index], [e.target.name]: e.target.value }
        props.setFeatures(arr)
    }
    const handleFeatureImage = (e) => {
        const endpoint = "product/uploadimages"
        const formData = new FormData();
        formData.append('highlightImages', e.target.files[0])
        postMethod(endpoint, formData).then((res) => {
            if (res.success) {
                setImg(res.highlightImages[0])
                let arr = props.features;
                arr[props.index] = { ...arr[props.index], "highlightImages": res.highlightImages }
                props.setFeatures(arr)

            }
        })
    }
    const deleteFeature=(e)=>{
        e.preventDefault();
        const endpoint="admin/delete/highlight/feature"
        const params={
            "productId":props?.prodId,
            "highlightsId":props?.highId,
            "highlightFeatureId":props.featureId
        }
        putMethod(endpoint,'',params).then((res)=>{
            if(res.success){
                props.showToast(true);
                props.showMessage("Feature Deleted");
                props.features.splice(props.index, 1)
            }
            else{
                props.showToast(true);
                props.showMessage(res.error)
            }
        })
    }
    return (
        <div className="pb-3" style={{ borderBottom: "1px solid #ececec" }}>
            {
                showFeatures ?
                    <form className="form-validate">
                        <div className="row g-gs">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Feature Image {props.index + 1}</label>
                                    <div>
                                        {img ?
                                            <label htmlFor={"profilepic" + props.index}>
                                                <Image src={process.env.REACT_APP_IMG_URL + img} className="image-container1" style={{ borderStyle: 'dashed' }} />
                                            </label>
                                            :
                                            <label htmlFor={"profilepic" + props.index}>
                                                <div className="image-container1">
                                                    <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' />
                                                </div>
                                            </label>
                                        }
                                        <input
                                            id={"profilepic" + props.index}
                                            type="file"
                                            name="highlightImages"
                                            onChange={handleFeatureImage}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <button className="d-flex del-btn ml-auto" onClick={deleteFeature}><IoTrashOutline color="#000" size={18} /></button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fv-title">Feature Title {props.index + 1}</label>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fv-title"
                                            name="highlightTitle"
                                            value={featuresDetail.highlightTitle}
                                            placeholder="Enter Feature Title"
                                            onChange={handleFeatureDetail}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fv-desc">Feature Description {props.index + 1}</label>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fv-desc"
                                            name="highlightDescription"
                                            value={featuresDetail.highlightDescription}
                                            placeholder="Enter Feature Description"
                                            onChange={handleFeatureDetail}
                                            required />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    :
                    null
            }
        </div>
    )
}
export default Features;