import React from "react";
import { Toast } from "react-bootstrap";

const ToastMessage = (props) =>{
    return(
        <Toast className="toast-message" show={props.showToast} onClose={props.toggleShow} delay={2000} autohide>
          <Toast.Body>{props.showMessage}</Toast.Body>
        </Toast>
    )   
}
export default ToastMessage;