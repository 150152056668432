import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import {FaBloggerB, FaUsers} from 'react-icons/fa';
import {GrProductHunt} from 'react-icons/gr';
import {IoIosImages} from 'react-icons/io'
import {MdSupportAgent} from 'react-icons/md';
import {VscRequestChanges,} from 'react-icons/vsc'
import {IoBagCheckOutline} from 'react-icons/io5' 
import {RiCoupon3Line} from 'react-icons/ri';
const Sidebar = (props) => {

    const userContext = useContext(UserContext);

    return (
        <div className="nk-sidebar nk-sidebar-fixed is-dark " data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-sidebar-brand">
                    <a href="/">
                        <img className="logo-light logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="logo" />
                        <img className="logo-dark logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="logo-dark" />
                    </a>
                </div>
                <div className="nk-menu-trigger mr-n2">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                </div>
            </div>
            <div className="nk-sidebar-element">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            {/* <li className="nk-menu-item">
                                <Link to="/dashboard" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-dashlite"></em></span>
                                    <span className="nk-menu-text">Dashboard</span>
                                </Link>
                            </li> */}
                            {/* <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Applications</h6>
                            </li> */}
                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><FaUsers  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Users</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/users" className="nk-menu-link">
                                                <span className="nk-menu-text">User List</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {['Admin', 'Agent'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><IoBagCheckOutline  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Bookings</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/booked-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Booked Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/pending-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Pending Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/installment-pending-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">InstallmentPending Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/complete-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Complete Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/confirmed-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Confirmed Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/cancelled-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Cancelled Booking List</span>
                                            </Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/declined-bookings" className="nk-menu-link">
                                                <span className="nk-menu-text">Declined Booking List</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    
                                </li>
                            )}
                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><GrProductHunt  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Products</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/products" className="nk-menu-link">
                                                <span className="nk-menu-text">Products List</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
 
                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><RiCoupon3Line  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Coupons</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/coupons" className="nk-menu-link">
                                                <span className="nk-menu-text">Coupons List</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}


                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><VscRequestChanges  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Request Management</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/request-list" className="nk-menu-link">
                                                <span className="nk-menu-text">Request List</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><MdSupportAgent  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Agent Management</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/agent-list" className="nk-menu-link"><span className="nk-menu-text">Agent List</span></Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><IoIosImages className='svgReduce'/></span>
                                        <span className="nk-menu-text">Gallery</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/home-gallery" className="nk-menu-link"><span className="nk-menu-text">Home Gallery</span></Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/product-gallery" className="nk-menu-link"><span className="nk-menu-text">Product Gallery</span></Link>
                                        </li>
                                    </ul>
                                </li>
                            
                            )}
                              {['Admin'].includes(userContext.state.user?.role) && (
                                <li className="nk-menu-item has-sub">
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                        <span className="nk-menu-icon"><FaBloggerB  className='svgReduce'/></span>
                                        <span className="nk-menu-text">Blogs</span>
                                    </a>
                                    <ul className="nk-menu-sub">
                                        <li className="nk-menu-item">
                                            <Link to="/tags" className="nk-menu-link"><span className="nk-menu-text">Blog Tags</span></Link>
                                        </li>
                                        <li className="nk-menu-item">
                                            <Link to="/blogs" className="nk-menu-link"><span className="nk-menu-text">Blogs List</span></Link>
                                        </li>
                                    </ul>
                                </li>
                            )}

                            {/* <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><em className="icon ni ni-tranx"></em></span>
                                    <span className="nk-menu-text">Orders</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <a href="/orders" className="nk-menu-link"><span className="nk-menu-text">Orders List</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon"><em className="icon ni ni-grid-alt"></em></span>
                                    <span className="nk-menu-text">Template</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <a href="/templates" className="nk-menu-link"><span className="nk-menu-text">Template List</span></a>
                                    </li>
                                    <li className="nk-menu-item">
                                        <a href="/add-template" className="nk-menu-link"><span className="nk-menu-text">Add Template</span></a>
                                    </li>
                                </ul>
                            </li>
                             */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sidebar;