import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCountries, putMethod } from "../../../../Services/ApiServices";
import { checkStatus, dmy } from "../../../../Utils/utils";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
import { Checkbox } from "baseui/checkbox";

const User = (props) => {
    const [countries, setCountries] = useState([])
    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
        })
    }

    useEffect(() => {
        fetchCountries()
    }, [])




    return (
        <div className="d-flex accordion accordion-head justify-content-between w-100 aC">
            <h6 className="title">{props?.val?.firstName + ' ' + props?.val?.lastName}</h6>
            <h6 className="title">{props?.val?.phone?.callingCode ? '+' + props?.val?.phone?.callingCode : null}{props?.val?.phone?.mobile ? ' ' + props?.val?.phone?.mobile : '-'}</h6>
            <h6 className="title">{props?.val?.email}</h6>
            <h6 className="title">{checkStatus(props?.data?.totalInstallments)}</h6>
        </div>
    )
}
export default User;