import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import ToastMessage from "../../../../Components/Toast/Toast";
import { deleteMethod, getMethod } from "../../../../Services/ApiServices";
import MUIDataTable from "mui-datatables";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { checkStatus } from "../../../../Utils/utils";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
import { format } from 'fecha';
import { ExportToExcel } from '../../../../Utils/ExportToExcel';
const PendingBookingList = (props) => {
    const history = useHistory();
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role
    const [booking, setBooking] = useState([]);
    const [bookingList, setBookingList] = useState([]);
    const [searchToggle, setSearchToggle] = useState(false);
    const [festival, setFestival] = useState(null)
    const [showToast, setShowToast] = useState(false);
    const [page, setPage] = useState(1);
    const [prevVal, setPrevVal] = useState(1);
    const [nextVal, setNextVal] = useState(10);
    const [search, setSearch] = useState("");
    const [excelData, setExcelData] = useState([]);
    const Inr = (arg) => {
        let inr = new Intl.NumberFormat('en-IN').format(arg)
        return inr
    }
    const viewDetail = (id) => {
        history.push('/booking-detail/' + id)
    }

    async function fetchBookings() {
        const endpoint = "admin/booking/list?status=pending"
        await getMethod(endpoint).then((res) => {
            if (res.success) {
                setBookingList(res.data)
            }
        })
    }

    useEffect(() => {
        fetchBookings()

        getMethod('admin/get-order-list?status=pending').then((response) => {
            if (response.success) {
                let fileData = response.data;
                let bookingData = fileData?.map((n) => ({
                    bookingId: n?.bookingId,
                    advancePaidPayment: n?.advancePaidPayment,
                    advancedPaidDate: n?.advancedPaidDate,
                    advancedPaidStatus: n?.advancedPaidStatus,
                    bookingStatus: n?.bookingStatus,
                    createdAt: n?.createdAt,
                    isAdvancedPaid: n?.isAdvancedPaid,
                    nonSplitPaymentInstallmentFlag: n?.nonSplitPaymentInstallmentFlag,
                    numberOfPassengers: n?.numberOfPassengers,
                    splitPayment: n?.splitPayment,
                    termsAndCondition: n?.termsAndCondition,
                    productId: n?.productId,
                    addressId: n?.addressId? 
                         (n?.addressId?.userName?n?.addressId?.userName: "")+', '+(n?.addressId?.address?n?.addressId?.address: "")+', '+(n?.addressId?.buildingName?n?.addressId?.buildingName: "")+', '+(n?.addressId?.city?n?.addressId?.city: "")+', '+(n?.addressId?.state?n?.addressId?.state: "")+', '+(n?.addressId?.country?n?.addressId?.country: "")+', '+(n?.addressId?.portCode?n?.addressId?.portCode: "")
                    :'',
                    passengersDetails: n?.passengersDetails?.length? (n?.passengersDetails?.map((n)=>(n?.firstName+' '+n?.lastName+'('+n?.email+'[islead:'+n?.isLeadPassenger+']'+')'))).join(', \n') :'',
                    paymentId: n?.paymentId?n?.paymentId:'',
                    productDetails: n?.productDetails? n?.productDetails?.tripName:'',
                    room: n?.room?.details?.length?  (n?.room?.details.map((n)=>(n?.name+'(No of person: '+n?.noOfPerson+', No of room: '+n?.noOfRooms+')'))).join(', /n') :'',
                    user:n?.userId? n?.userId?.firstName+(n?.userId?.lastName?n?.userId?.lastName:'')+'('+n?.userId?.gender+', '+n?.userId?.email+', '+n?.userId?.phone?.callingCode+n?.userId?.phone?.mobile+')':'',
                    agentId:n?.agentId?n?.agentId:'',
                    totalInstallments: n?.totalInstallments?.length? (n?.totalInstallments?.map((n)=>(n?.installmentNumber+': ('+n?._id+')='+'('+n?.installmentAmount+')'+n?.installmentStatus))).join(', \n') :''
                }))

                let paidData = (bookingData?.map((n)=>n?.paymentId?n:null)).filter((n)=>n)
                let pendingData = (bookingData?.map((n)=>n?.paymentId === ''?n:null)).filter((n)=>n)
                let finalData = [{paid: paidData?.length?paidData:[], pending: pendingData?.length?pendingData:[]}]
                setExcelData(finalData)
            }

        })
    }, [])

    const searchVal = (e) => {
        setSearch(e.target.value);
        const endpoint = "admin/booking/list"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10,
            "search": e.target.value,
            "status" :"pending"
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setBookingList(res.data);
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)
    }

    const previous = () => {
        const endpoint = "admin/booking/list"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10,
            "status" :"pending"
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setBookingList(res.data)
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)

    }
    const next = () => {
        const endpoint = "admin/booking/list"
        const p = page >= 1 ? page + 1 : 1
        const params = {
            "page": p,
            "limit": 10,
            "status" :"pending"
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setBookingList(res.data)
            }
        })
        setPage(p);
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)
    }

    const deleteBooking = (id) => {
        const endpoint = `booking/cancel/${id}`
        deleteMethod(endpoint).then(
            (res) => {
                if (res.success) {
                    setShowToast(true)
                    setShowMessage("Booking Cancelled")
                    fetchBookings()
                }
                else {
                    setShowToast(true)
                    setShowMessage(res.error)
                }
            }
        )
    }

    const data = bookingList?.map((item) => {
        const ar = [];
        ar.push(
            role === 'Admin' ? item?.userId ? item?.userId?.firstName ? item?.userId?.firstName + ' ' + item?.userId?.lastName : '-' : item?.agentInfo?.name ? (item?.agentInfo?.name + '(agent)') : '-' : item?.passengersDetails?.firstName + ' ' + item?.passengersDetails?.lastName,
            item?.bookingId ? item?.bookingId : '-',
            item?.productDetails?.tripName ? item?.productDetails?.tripName : '-',
            // item?.userId?.email ? item?.userId?.email : '-',
            role === 'Admin' ? item?.userId?.phone ? '+' + item?.userId?.phone?.callingCode + ' ' + item?.userId?.phone?.mobile : '-' : item?.passengersDetails?.phone?.mobile,
            item?.bookingStatus ? item?.bookingStatus : '-',
            item?.createdAt ? format(new Date(item.createdAt), 'Do MMM YYYY') : '-',
            item?._id ?
                <div className="drodown">
                    <a className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            <li><a onClick={() => viewDetail(item._id)}><em className="icon ni ni-focus"></em><span>View Detail</span></a></li>
                            <li><a onClick={() => { deleteBooking(item._id) }}><em className="icon ni ni-trash"></em><span>Cancel Booking</span></a></li>
                        </ul>
                    </div>
                </div>
                :
                null
        );
        return ar;
    })

    const columns = [
        role === 'Admin' ? "Name/Agent Name" : 'Name',
        "Booking Id",
        "Trip Name",
        // "Email",
        "Phone Number",
        "Booking Status",
        "Booking Date",
        "Action"
    ];
    const options = {
        filter: false,
        filterType: "dropdown",
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        search: false
    };

    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Bookings</h3>
                                </div>
                                <div className="nk-block-head-content">
                                    <Link to={role == 'Admin' ? "/add-booking" : "/add-agent-booking"} className="btn btn-white btn-outline-light" style={{margin:'0px 5px'}}><span>Add Booking</span></Link>
                                    {
                                        (role).toLowerCase() === 'admin' && excelData?.length ?
                                            <ExportToExcel apiData={excelData[0]} fileName={"booking" + page} />
                                        : null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="nk-block">
                            <div className="box">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <div className="card-tools mr-n1">
                                            <p className="mb-0 p-text">All Pending Bookings</p>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a onClick={(e) => setSearchToggle(true)} className="btn btn-icon search-toggle toggle-search" data-target="search"><em className="icon ni ni-search"></em></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={searchToggle ? "card-search search-wrap active" : "card-search search-wrap"} data-search="search">
                                        <div className="card-body">
                                            <div className="search-content">
                                                <a onClick={(e) => { setSearchToggle(false); setSearch("") }} className="search-back btn btn-icon toggle-search" data-target="search"><em className="icon ni ni-arrow-left"></em></a>
                                                <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={search} onChange={searchVal} />
                                                <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </div>
                        <div className="pagination-row box">
                            <div className="fr_jE_aI">
                                <p className="mb-0">Page No. {page}</p>
                            </div>
                            <div className="mx-4 fr_jE_aI">
                                <p className="mb-0">{prevVal}</p>
                                <p className="mb-0">-</p>
                                <p className="mb-0">{nextVal}</p>
                            </div>
                            <button className="btn_none" disabled={page == 1 ? true : false} onClick={() => previous()}><FaAngleLeft size={20} color="#999999" /></button>
                            <button className="btn_none" disabled={bookingList?.length < 10 ? true : false} onClick={() => next()}><FaAngleRight size={20} color="#999999" /></button>
                        </div>

                    </div >
                </div >
            </div >
        </div >

    )
}
export default PendingBookingList;