function detectBed(number) {
    let bed = [];
    for (let li = 0; li < number; li++) {
        bed.push(li);
    }
    return bed;
}
function inr(arg) {
    let inr = new Intl.NumberFormat('en-IN').format(arg)
    return inr
}

function dmy(date) {
    let newDate = date?.toString().split("-").reverse().join("-")
    return newDate
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function checkStatus(val) {
    var returnStatus = '';
    val?.forEach(x => {
        if (x?.installmentStatus != "paid") {
            returnStatus = "Payment Pending";
        }
        else {
            returnStatus = "Payment Paid";
        }
    })
    return returnStatus;
}

function getCurrentDate() {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (date < 10) {
        date = '0' + date;
    }

    if (month < 10) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + date;
}

const dateFormatConverter = (arg) => {
    let date = new Date(arg);
    let datel = date.getUTCDate() === 1 || date.getUTCDate() === 21 || date.getUTCDate() === 31 ? date.getUTCDate() + 'st' : date.getUTCDate() === 2 ? date.getUTCDate() + 'nd' : date.getUTCDate() === 3 ? date.getUTCDate() + 'rd' : date.getUTCDate() + 'th';
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let returnDate = datel + ' ' + monthArray[(date.getUTCMonth())] + ' ' + date.getUTCFullYear()
    return returnDate;
}

export { detectBed, inr, dmy, checkStatus, getCurrentDate, dateFormatConverter, formatDate }