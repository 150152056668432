import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { deleteMethod, getMethod, postMethod } from "../../../../Services/ApiServices";
import { AiOutlineCloseCircle } from "react-icons/ai";

const HomeGallery = (props) => {
    const history = useHistory()
    const [gallery, setGallery] = useState([]);
    const [prevGallery, setPrevGallery] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    function fetchGallery(){
        const endpoint = "home/gallery";
        getMethod(endpoint).then((res) => {
            if (res.success) {
                setPrevGallery(res.data)
            }
        })
    }

    useEffect(() => {
        fetchGallery()
    }, [])

    const deleteImage =(e,id,i)=>{
        e.preventDefault();

        const endpoint="home/delete/gallery"
        const params={
            "galleryId": id
        }
        deleteMethod(endpoint, params).then((res)=>{
            if(res.success){
                setShowToast(true);
                setShowMessage("Deleted Successfully");
                prevGallery.splice(i,1)
                fetchGallery()
            }
            else{
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint="home/create/gallery";
        const formData = new FormData();
        for (let key in gallery){
            formData.append('images', gallery[key])
        }
        postMethod(endpoint, formData).then((res)=>{
            if(res.success){
                setShowToast(true);
                setShowMessage("Added Successfully");
                setGallery([])
                fetchGallery();
            }
            else{
                setShowToast(true);
                setShowMessage(res.error)
            }
        })

    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Home Gallery</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Gallery</label>

                                        <div className="form-control-wrap">
                                            {
                                                gallery || prevGallery ?
                                                    <div className="main-block">

                                                        {prevGallery?.map((val, i) => {
                                                            return (
                                                                <div className="inner-block">
                                                                    <img key={i} className="image-container1" src={process.env.REACT_APP_IMG_URL + val.image} alt="gallery" />
                                                                    <button className="btn_none btn-pos" onClick={(e)=>deleteImage(e,val._id,i)}><AiOutlineCloseCircle color="#000" size={18} /></button>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                        {
                                                            gallery?.map((val, i) => {
                                                                return (
                                                                    <div className="inner-block">
                                                                        <img className="image-container1" key={i} src={URL.createObjectURL(val)} alt="gallery" />
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        <label htmlFor="customGallery">
                                                            <div className="image-container1">
                                                                <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    :
                                                    <label htmlFor="customGallery">
                                                        <div className="image-container1">
                                                            <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                        </div>
                                                    </label>
                                            }
                                        </div>
                                        <input
                                            id="customGallery"
                                            type="file"
                                            name="gallery"
                                            multiple
                                            onChange={(e) => setGallery(Object.values(e.target.files))}
                                        />
                                    </div>
                                </div></div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default HomeGallery;