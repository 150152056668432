import React from "react";
import { FaUserAlt } from "react-icons/fa";

const DoubleBed = (props) => {
    return (
        <div className="fill-block" style={{backgroundColor:props?.bgColor}}>
            <FaUserAlt color="#fff" size={18} />
            <FaUserAlt color="#fff" size={18} />
        </div>
    )
}
export default DoubleBed;