import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../Components/Toast/Toast";
import { postMethod } from "../../../Services/ApiServices";
import Footer from "../../../Components/Footer/Footer";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { LS_USER_DATA, LS_USER_TOKEN, UserContext } from "../../../Contexts/UserContext";

const Login = (props) => {
    let token;

    const userContext = useContext(UserContext);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [emailPhoneErr, setEmailPhoneErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in",
    });
    const [data, setData] = useState({
        "emailPhone": "",
        "password": ""
    })
    const handleChange = (e) => {
        setData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    const shouldShow = (field) => {
        var phoneno = /^[0-9]+$/;
        if (data.emailPhone.match(phoneno)) {
            return true;
        }
        else {
            return false
        }

    };
    const formValidation = () => {
        let isValid = true;
        if (!data.emailPhone) {
            setEmailPhoneErr("Email or Phone Number is Required")
            isValid = false;
        }
        else {
            setEmailPhoneErr("")
            isValid = true;
        }
        if (!data.password) {
            setPasswordErr("Password is Required")
            isValid = false;
        }
        else {
            setPasswordErr("")
            isValid = true;
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let Valid = formValidation();

        if (Valid) {
            let value = { ...data };
            const endpoint = "admin/login";
            if (shouldShow('emailPhone')) {
                value.emailPhone = {
                    "mobile": value.emailPhone,
                    "countryCode": phoneFormat.countryCode.toUpperCase(),
                    "callingCode": parseInt(phoneFormat.dialCode)

                }
            }
            else {
                value.emailPhone = value.emailPhone
            }
            postMethod(endpoint, value).then((res) => {
                if (res.success) {
                    const token = res.token;
                    const data = res.data
                    if (!data.role) data.role = 'Agent';

                    localStorage.setItem(LS_USER_TOKEN, token);
                    localStorage.setItem(LS_USER_DATA, JSON.stringify(data));
                    userContext.dispatch({ type: 'SET_USER', payload: { ...data, token } });
                    setShowToast(true);
                    setShowMessage("Login Successfully")
                    // history.replace('/bookings');
                    window.location.href = '/bookings';
                }
                else {
                    setShowToast(true);
                    setShowMessage(res.error)
                }
            })
        }

    }
    return (
        <div className="nk-body bg-white npc-general pg-auth">
            <div className="nk-app-root">
                <div className="nk-main ">
                    <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                                <div className="brand-logo pb-4 text-center">
                                    <a href="/" className="logo-link">
                                        <img className="logo-light logo-img logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo-main.png"} alt="logo" />
                                        <img className="logo-dark logo-img logo-img-lg" src={process.env.PUBLIC_URL + "/assets/images/logo-main.png"} alt="logo-dark" />
                                    </a>
                                </div>
                                <div className="card card-bordered">
                                    <div className="card-inner card-inner-lg">
                                        <div className="nk-block-head">
                                            <ToastMessage showToast={showToast} toggleShow={setShowToast} showMessage={showMessage} />
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title">Sign-In</h4>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="emailPhone">Email or Phone</label>
                                                </div>
                                                <span className={shouldShow('emailPhone') ? "countryCode" : "hidden"}>
                                                    <PhoneInput
                                                        inputProps={{
                                                            name: 'phone',
                                                            disabled: true
                                                        }}
                                                        // country={'in'}
                                                        value={"+" + phoneFormat.dialCode}
                                                        onChange={(e, a) => { setPhoneFormat(a); }}
                                                    />
                                                </span>
                                                <input
                                                    type="text"
                                                    className={shouldShow('emailPhone') ? "form-control form-control-lg pl-110" : "form-control form-control-lg"}
                                                    id="emailPhone"
                                                    maxLength={shouldShow('emailPhone') ? "10" : null}
                                                    name="emailPhone"
                                                    value={data.emailPhone}
                                                    placeholder="Enter your email address or phone number"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                />
                                                <span className="errorStyle">{emailPhoneErr}</span>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <a className="link link-primary link-sm" href="#">Forgot Password?</a>
                                                </div>
                                                <div className="form-control-wrap">
                                                    {/* <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                        <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                        <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                                    </a> */}
                                                    <input
                                                        type="password"
                                                        className="form-control form-control-lg"
                                                        value={data.password}
                                                        id="password"
                                                        name="password"
                                                        placeholder="Enter your password"
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                    <span className="errorStyle">{passwordErr}</span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-lg btn-primary btn-block" >Sign in</button>
                                            </div>
                                        </form>
                                        {/* <div className="form-note-s2 text-center pt-4"> New on our platform? <a href="/register">Create an account</a>
                                        </div>
                                        <div className="text-center pt-4 pb-3">
                                            <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
                                        </div>
                                        <ul className="nav justify-center gx-4">
                                            <li className="nav-item"><a className="nav-link" href="#">Facebook</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#">Google</a></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;