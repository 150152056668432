import React, { useEffect, useState } from "react";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { FiPlus } from "react-icons/fi";
import { getMethod } from "../../../../../Services/ApiServices";
import { Button } from "react-bootstrap";
import AddAddress from "./AddAddress";
import UpdateAddress from "./UpdateAddress";

const AddressDetail = (props) => {
    const [address, setAddress] = useState([]);
    const [value, setValue] = useState("");
    const [addAddress, setaddAddress] = useState(false);
    const [showAddress, setShowAddress] = useState(false)

    const userAddressFetch = async (id) => {
        const endpoint = "admin/user/detail"
        const params = {
            "userId": id
        }
        await getMethod(endpoint, params).then((res) => {
            if (res?.data?.address?.length > 0) {
                setAddress(res.data.address);
                setValue(res?.data?.address[0]?._id);
                props.data((prev) => {
                    return {
                        ...prev,
                        "addressId": res?.data?.address[0]?._id
                    }
                })
            }
        })
    }
    useEffect(() => {

        if (props?.id && props?.role === 'Admin') {
            userAddressFetch(props?.id)
        }
        setShowAddress(false)
    }, [showAddress, props.id])
    const agentAddressDetailsFetch = async () => {
        const endpoint = "user/address"
        await getMethod(endpoint).then((res) => {
            if (res?.data?.length > 0) {
                setAddress(res.data);
                setValue(res?.data[0]?._id);
                props.data((prev) => {
                    return {
                        ...prev,
                        "addressId": res?.data[0]?._id
                    }
                })
            }
        })
    }
    useEffect(() => {
        if (props?.role === 'Agent') {
            agentAddressDetailsFetch()
        }
    }, props?.role)

    return (
        <div className="nk-block">
            <RadioGroup
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    props.data((prev) => {
                        return {
                            ...prev,
                            "addressId": value
                        }

                    })
                }}
                name="number"
                align={ALIGN.vertical}
                overrides={{
                    Label: {
                        style: ({
                            color: "#fff",
                            width: "100%"
                        })
                    },
                    Root: {
                        style: ({
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            paddingTop: "10px",
                            paddingBottom: "10px"
                        })
                    },
                    RadioMarkOuter: {
                        style: ({
                            backgroundColor: "#212c34"
                        })
                    }
                }}
            >
                {address?.map((val, index) => {
                    return (
                        props?.role === 'Admin' ?
                            <Radio value={val._id} key={index}>
                                <UpdateAddress agentId={false} userId={props?.id ? props?.id : false} agentAddressDetailsFetch={props?.role === 'Admin' ? userAddressFetch : agentAddressDetailsFetch} data={val} reload={setShowAddress} showToast={props.showToast} toastHead={props.toastHead} toastMessage={props.toastMesage} />
                            </Radio>
                            : props?.role === 'Agent' ?
                                <Radio value={val._id} key={index}>
                                    <UpdateAddress agentId={props?.agentId ? props?.agentId : ''} userId={false} agentAddressDetailsFetch={props?.role === 'Admin' ? userAddressFetch : agentAddressDetailsFetch} data={val} reload={setShowAddress} showToast={props.showToast} toastHead={props.toastHead} toastMessage={props.toastMesage} />
                                </Radio>
                                : null
                    )
                })}
            </RadioGroup>
            <div className="py-4 pl-3 pr-3">
                <Button className="add-button" onClick={() => setaddAddress(addAddress?false:true)}>Add New<FiPlus className="ml-1" color="#fff" size={18} /></Button>
            </div>
            {addAddress ?
                props?.role === 'Admin' ?
                    <AddAddress agentId={false} id={props?.id ? props?.id : false} agentAddressDetailsFetch={props?.role === 'Admin' ? userAddressFetch : agentAddressDetailsFetch} reload={setShowAddress} set={setaddAddress} showToast={props.showToast} toastHead={props.toastHead} toastMessage={props.toastMesage} />
                    : props?.role === 'Agent' ?
                        <AddAddress agentId={props?.agentId ? props?.agentId : ''} id={false} agentAddressDetailsFetch={props?.role === 'Admin' ? userAddressFetch : agentAddressDetailsFetch} reload={setShowAddress} set={setaddAddress} showToast={props.showToast} toastHead={props.toastHead} toastMessage={props.toastMesage} />
                        : null
                : null
            }
        </div>

    )
}
export default AddressDetail;