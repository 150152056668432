import React, { useEffect, useState, useRef } from "react";
import "react-phone-input-2/lib/style.css";
import { useLocation, useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { format } from 'fecha';
import Compress from 'compress.js';
import Resizer from "react-image-file-resizer";
import {
  getMethod,
  postMethod,
  putMethod,
  BASE_IMG_URL,
} from "../../../../Services/ApiServices";
import { Editor } from "@tinymce/tinymce-react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { Audio } from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddBlog = (props) => {
  const compress = new Compress()
  const query = useQuery();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [slug, setSlug] = useState(null);
  const [date, handleSetDate] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [file, setFile] = useState(null);
  const [blogContent, setBlogContent] = useState(null);
  const [isPublish, setIsPublish] = useState(false);
  const [editCase, setEditCase] = useState(false);
  const [loader, setLoader] = useState(false)
  const imageRef = useRef();
  const editorRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const toggleShow = () => {
    setShowToast(!showToast);
  };
  const [showMessage, setShowMessage] = useState("");

  function formatTags(data) {
    var arrayData = [];
    for (let li = 0; li < data?.length; li++) {
      if (data[li]?._id && data[li]?.name) {
        arrayData.push({ value: data[li]?._id, label: data[li]?.name });
      }
    }
    return arrayData;
  }

  useEffect(() => {
    getMethod("blog-relateds/all").then((res) => {
      if (res.success) {
        setTags(formatTags(res?.data));
      }
    });
    if (window.location.pathname === "/edit-blog" && query.get("id") != "") {
      setEditCase(true);
      const endpoint = "blogs/single";
      const params = {
        id: query.get("id"),
      };
      getMethod(endpoint, params).then((res) => {
        if (res.success) {
          setTitle(res?.data?.heading);
          setDescription(res?.data?.description);
          setSlug(res?.data?.slug);
          setSelectedTags(res?.data?.related?.map((item) => item?._id));
          setMetaTitle(res?.data?.metaTitle);
          //handleSetDate(res?.data?.date?res.data?.date:'')
          handleSetDate(format(new Date(res.data.date), 'YYYY-MM-DD'))
          setMetaDescription(res?.data?.metaDescription);
          setBlogContent(res?.data?.blogContents);
          setIsPublish(res?.data?.isPublish);
          setFile(res?.data?.featureImage);
        }
      });
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.get("id")) {
      update();
    } else {
      add();
    }
  };
  const update = () => {
    const endpoint = "blogs/update";
    const params = {
      id: query.get("id"),
    };
    let data;
    if (editCase) {
      data = {
        heading: title,
        description: description,
        slug: slug,
        metaTitle: metaTitle,
        date: date,
        metaDescription: metaDescription,
        related: selectedTags,
        featureImage: file,
        blogContents: editorRef.current.getContent(),
        isPublish: isPublish,
      };
    }
    setIsSubmitting(true);
    putMethod(endpoint, data, params).then((res) => {
      setIsSubmitting(false);
      if (res.success) {
        setShowToast(true);
        setShowMessage("Updated Successfully");
      } else {
        setShowToast(true);
        setShowMessage(res.error);
      }
    });
  };
  const add = () => {
    const endpoint = "blogs/add";
    let data = {
      heading: title,
      description: description,
      slug: slug,
      date: date,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      related: selectedTags,
      featureImage: file,
      blogContents: editorRef.current.getContent(),
      isPublish: isPublish,
    };
    console.log(data);
    if (!data) {
      setShowToast(true);
      setShowMessage("All fields are required");
    }
    setIsSubmitting(true);
    postMethod(endpoint, data)
      .then((res) => {
        setIsSubmitting(false);
        if (res.success) {
          setShowToast(true);
          setShowMessage("Added Successfully");
          history.goBack();
        } else {
          setShowToast(true);
          setShowMessage(res.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowMessage(err.error);
      });
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function handleSetTitle(title) {
    setTitle(title);
    setSlug(convertToSlug(title));
    console.log(convertToSlug(title));
  }
  const uploadImage = (e) => {
    // setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
    // const finalData = Compress.convertBase64ToFile(e[0].data, e[0].ext)
    const formData = new FormData();
    formData.append("image", e);
    postMethod("admin/upload-aws", formData)
      .then((response) => {
        setFile(response?.data?.url);
        //console.log(response.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="nk-content">
      <ToastMessage
        showToast={showToast}
        toggleShow={toggleShow}
        showMessage={showMessage}
      />
      <div className="container-fluid pt-3 px-5">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {editCase ? "Update Blog" : "Add Blog"}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <form className="form-validate" onSubmit={handleSubmit}>
          <div className="card card-bordered">
            <div className="card-inner">
              <div className="row g-gs">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Publish Date
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="fv-date"
                        name="date"
                        value={date}
                        placeholder="dd/mm/yyyy"
                        onChange={(e) => handleSetDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Title (Heading)
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fv-name"
                        name="name"
                        value={title}
                        placeholder="Enter Name"
                        onChange={(e) => handleSetTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Slug
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fv-name"
                        name="name"
                        value={slug}
                        placeholder=""
                        autoComplete="off"
                        required
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-agency-name">
                      Description
                    </label>
                    <div className="form-control-wrap">
                      {/* {description} */}

                      <textarea
                        type="text"
                        className="form-control"
                        id="fv-agency-name"
                        name="agencyName"
                        placeholder="Enter Agency Name"
                        onChange={(e) => setDescription(e.target.value)}
                        autoComplete="off"
                        value={description}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Tags
                    </label>
                    <div className="form-control-wrap">
                      <MultiSelect
                        style={{ width: '100%' }}
                        options={tags?.length ? tags : []}
                        defaultValue={selectedTags}
                        onChange={(e) => setSelectedTags(e.split(","))}
                        labelledBy="Select Tags"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      Meta Title
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fv-email"
                        name="email"
                        value={metaTitle}
                        placeholder="Enter related"
                        onChange={(e) => setMetaTitle(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-agency-name">
                      Meta Description
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        type="text"
                        className="form-control"
                        id="fv-agency-name"
                        name="agencyName"
                        placeholder="Enter Meta Description"
                        onChange={(e) => setMetaDescription(e.target.value)}
                        autoComplete="off"
                        value={metaDescription}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-address2">
                      Featured Image
                    </label>
                    <div className="form-control-wrap">
                      {file ? (
                        <img
                          src={file ? BASE_IMG_URL + file : BASE_IMG_URL + file}
                          className="img-fluid mb-4"
                        />
                      ) : null}
                      <input
                        id="img"
                        ref={imageRef}
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={async (e) => {
                          uploadImage(e.target.files[0])
                         //const newFiles = [...e.target.files];
                          // await Resizer.imageFileResizer(
                          //   e.target.files[0],
                          //   1300,
                          //   500,
                          //   "png",
                          //   100,
                          //   0,
                          //   (uri) => {
                          //     uploadImage(uri);
                          //   },
                          //   "file",
                          //   1300,
                          //   500
                          // );
                          // await compress.compress(newFiles, {
                          //   size: 18, // the max size in MB, defaults to 2MB
                          //   quality: 1, // the quality of the image, max is 1,
                          //   width:1920,
                          //   height:860,
                          //   maxWidth: 1920, // the max width of the output image, defaults to 1920px
                          //   maxHeight: 860, // the max height of the output image, defaults to 1920px
                          //   resize: true, // defaults to true, set false if you do not want to resize the image width and height
                          //   rotate: false, // See the rotation section below
                          // }).then((data) => {
                          //   uploadImage(data)
                          //   // returns an array of compressed images
                          // })
                        }}
                        style={{ display: "block" }}
                        required={editCase ? false : true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      IsPublish
                    </label>
                    <div className="form-control-wrap">
                      <select
                        className="form-control"
                        id="fv-email"
                        name="email"
                        placeholder="Enter related"
                        onChange={(e) => setIsPublish(e.target.value)}
                        autoComplete="off"
                        required
                      >
                        <option
                          value="true"
                          selected={isPublish ? "selected" : ""}
                        >
                          Yes
                        </option>
                        <option
                          value="false"
                          selected={!isPublish ? "selected" : ""}
                        >
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-agency-name">
                      Blog Content
                    </label>
                    <div className="form-control-wrap" style={{border:'solid #cecece 1px'}}>
                      <Editor
                        className='editorStyle'
                        outputFormat='text'
                        onInit={(evt, editor) => editorRef.current = editor}
                        onEditorChange={(evt, editor) => {
                          setBlogContent(editorRef.current.getContent());
                        }}
                        value={blogContent}
                        apiKey={process.env.REACT_APP_TINMCY_KEY}
                        init={
                          {
                            width: '100%',
                            height: '500px',
                            skin: "naked",
                            icons: "thin",
                            menubar: true,
                            selector: "textarea",
                            resize: 'both',
                            branding: false,
                            draggable_modal: false,
                            statusbar: false,
                            theme: 'silver',
                            toolbar_mode: 'wrap',
                            toolbar_location: 'top',
                            placeholder: 'Blogs Content...',
                            plugins: [
                              'insertdatetime code advlist anchor autolink fullscreen image imagetools tinydrive',
                              ' lists link media powerpaste preview, tinydrive',
                              ' searchreplace table tinymcespellchecker wordcount'
                            ],
                            toolbar: 'formatselect code fontselect fontsizeselect bold italic lineheight | forecolor backcolor alignleft aligncenter alignright alignjustify | bullist numlist | link image',
                            image_title: true,
                            automatic_uploads: true,
                            file_browser_callback_types: 'image media',
                            file_picker_callback: function (cb, value, meta) {
                              var input = document.createElement('input');
                              input.setAttribute('type', 'file');
                              input.setAttribute('accept', 'image/*');
                              input.onchange = function () {
                                var file = this.files[0];
                                var reader = new FileReader();
                                reader.onload = async function () {
                                  var id = 'blobid' + (new Date()).getTime();
                                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                  var base64 = reader.result.split(',')[1];
                                  var blobInfo = blobCache.create(id, file, base64);
                                  blobCache.add(blobInfo);
                                  const formData = new FormData();
                                  // formData.append('path', 'blogs');
                                  formData.append('image', file);
                                  setLoader(true)
                                  await postMethod('admin/upload-aws', formData).then(response => {
                                    if (response.success) {
                                      cb(BASE_IMG_URL + response?.data?.url, { title: response?.data?.url?.split('/')[(response?.data?.url?.split('/').length - 1)] });
                                      setLoader(false)
                                      //success(response?.aws?.location)
                                    } else {
                                      setLoader(false)
                                    }
                                  })
                                };
                                reader.readAsDataURL(file);
                              };
                              input.click();
                            },
                            images_upload_handler: async function (blobInfo, success, failure) {
                              const formData = new FormData();
                              // formData.append('path', 'blogs');
                              formData.append('image', blobInfo.blob());
                              setLoader(true)
                              await postMethod('admin/upload-aws', formData).then(response => {
                                if (response.success) {
                                  success(BASE_IMG_URL + response?.data?.url)

                                  setLoader(false)
                                } else {
                                  setLoader(false)
                                }
                              })
                            },
                            fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                            lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3; Header 4=h4',
                            font_formats: 'Arial Black=arial black,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Verdana=verdana,geneva;',
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row fr_jc pr-3 py-3">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? "Submitting.."
                      : props?.location?.state?.id
                        ? "Update"
                        : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBlog;
