import React, { useEffect, useState } from "react";
import { getCountries, getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import PhoneInput from 'react-phone-input-2';
import ToastMessage from "../../../../Components/Toast/Toast";
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";

const UserProfile = (props) => {
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in",
    });
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    const history = useHistory()

    useEffect(() => {
        const data = localStorage.getItem(LS_USER_DATA)
        setName(JSON.parse(data)?.name)
        setFirstName(JSON.parse(data)?.firstName)
        setLastName(JSON.parse(data)?.lastName)
        setEmail(JSON.parse(data)?.email)
        setPhone(JSON.parse(data)?.phone?.mobile)
        setRole(JSON.parse(data)?.role)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data;
        if (role == 'Admin') {
            data = {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "phone": {
                    "mobile": phone,
                    "countryCode": phoneFormat?.countryCode?.toUpperCase(),
                    "callingCode": phoneFormat?.dialCode
                },
                "newPassword": newPassword,
                "ConfirmPassword": confirmPassword
            }
        }
        else {
            data = {
                "name": name,
                "email": email,
                "phone": {
                    "mobile": phone,
                    "countryCode": phoneFormat?.countryCode?.toUpperCase(),
                    "callingCode": phoneFormat?.dialCode
                },
                "newPassword": newPassword,
                "ConfirmPassword": confirmPassword
            }
        }

       // console.log("data", data)
        const endpoint = "admin/update"
        putMethod(endpoint, data).then((res) => {
            if (res.success) {
                setShowToast(true)
                setShowMessage("Profile Updated")
            }
            else {
                setShowToast(true)
                setShowMessage(res.error)
            }
        })
    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">User Profile</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                {role == 'Admin' ?
                                    <>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fv-first-name">First Name</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fv-first-name"
                                                        name="firstName"
                                                        value={firstName}
                                                        placeholder="Enter First Name"
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fv-last-name">Last Name</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fv-last-name"
                                                        name="lastName"
                                                        value={lastName}
                                                        placeholder="Enter Last Name"
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fv-name">Name</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fv-name"
                                                    name="name"
                                                    value={name}
                                                    placeholder="Enter Name"
                                                    onChange={(e) => setName(e.target.value)}
                                                    required />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                        <div className="form-control-wrap">
                                            <span className="countryCode">
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phone',
                                                        disabled: true,
                                                        className: "hgt01"
                                                    }}
                                                    // country={'in'}
                                                    value={"+" + phoneFormat.dialCode}
                                                    onChange={(e, a) => { setPhoneFormat(a); }}
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control pl-110"
                                                id="fv-phone"
                                                name="phone"
                                                value={phone}
                                                placeholder="Enter Phone Number"
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-email">Email</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="fv-email"
                                                name="email"
                                                value={email}
                                                placeholder="Enter Email Address"
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-role">Role</label>
                                        <div className="form-control-wrap">
                                            <span className="form-control">{role}</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pwd">New Password</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pwd"
                                                name="newPassword"
                                                value={newPassword}
                                                placeholder="Enter New Password"
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-cpwd">Confirm Password</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-cpwd"
                                                name="ConfirmPassword"
                                                value={confirmPassword}
                                                placeholder="Enter Confirm Password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default UserProfile;