import React, { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const FeatureDescription = (props) => {
    const [desc, setDesc] = useState();

    useEffect(() => {
        setDesc(props?.data)
    }, [props])

    const removeDesc = () => {
        let arr = props.feature;
        arr.splice(props.index, 1)
        props?.setFeature(arr)
        let arr1 = props?.featureDescription
        arr1.splice(props.ind, 1)
        props.setFeatureDescription(
            arr1
        );
    }

    return (

        <div className="col-md-6">
            <div className="form-group">
                <label className="form-label" htmlFor="fv-desc">Feature Description {props.index + 1}</label>
                <div className="form-control-wrap">
                    <input
                        type="text"
                        className="form-control"
                        id="fv-desc"
                        name="itineraryDescription"
                        value={desc}
                        placeholder="Enter Feature Description"
                        onChange={(e) => {
                            setDesc(e.target.value);
                            let arr = props.feature;
                            arr[props.index] = e.target.value;
                            props?.setFeature(arr)
                            let arr1 = props?.featureDescription
                            arr1[props.ind] = {
                                ...arr1[props.ind],
                                [e.target.name]: arr,
                            }
                            props.setFeatureDescription(
                                arr1
                            );

                        }}
                        required />
                    {/* <div className="icon_block">
                        <button className="btn_none" onClick={removeDesc}>
                            <IoCloseCircleOutline size="24" />
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default FeatureDescription;