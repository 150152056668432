
import axios from 'axios';
import { LS_USER_TOKEN } from '../Contexts/UserContext';

const token = localStorage.getItem(LS_USER_TOKEN);
const key = "AIzaSyAJPg8FXsdEW8xjL-3EcYvnJ6ocaG94Bpc";
const BASE_IMG_URL = process.env.REACT_APP_IMG_URL;


function getAddress(params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?key=${key}`,
      params: params
    };
    axios(config).then(res => {
      resolve(res.data)
    }, error => {
      resolve(error.response.data)
    })
  })
};

function getMethod(endpoint, params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}` + 'api/v1/' + endpoint,
      headers: {
        Authorization: "Bearer " + token
      },
      params: params
    };
    axios(config).then(res => {
      resolve(res.data)
    }, error => {
      resolve(error.response.data)
    })
  })
};
function postMethod(endpoint, data, params = {}) {
  return new Promise(resolve => {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}` + 'api/v1/' + endpoint,
      headers: {
        Authorization: "Bearer " + token
      },
      data: data,
      params: params
    };

    axios(config).then(res => {
      resolve(res.data)

    }, error => {
      resolve(error.response.data)
    })
  })
};
function putMethod(endpoint, data, params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}` + 'api/v1/' + endpoint,
      headers: {
        Authorization: "Bearer " + token,
        Accept: "multipart/form-data"
      },
      data: data,
      params: params
    };
    axios(config).then(res => {
      resolve(res.data)
    }, error => {
      resolve(error.response.data)
    })
  })
};
function deleteMethod(endpoint, params = {}) {
  return new Promise(resolve => {
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}` + 'api/v1/' + endpoint,
      headers: {
        Authorization: "Bearer " + token
      },
      params: params
    };
    axios(config).then(res => {
      resolve(res.data)
    }, error => {
      resolve(error.response.data)
    })
  })
};

const getCountries = () => {
  return new Promise((resolve, error) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/v1/common/country').then(res => {
      resolve(res.data)
    }, err => {
      error(err.response.data)
    })
  })
}

const getStates = (countryCode) => {
  return new Promise((resolve, error) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/v1/common/state', { params: { countryCode } }).then(res => {
      resolve(res.data)
    }, error => {
      error(error.response.data)
    })
  })
}
const getCities = (countryCode, stateCode) => {
  return new Promise((resolve, error) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/v1/common/city', { params: { countryCode, stateCode } }).then(res => {
      resolve(res.data)
    }, error => {
      error(error.response.data)
    })
  })

}
export { getAddress, getMethod, postMethod, putMethod, deleteMethod, getCountries, getStates, getCities, BASE_IMG_URL };
