import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Checkbox } from 'baseui/checkbox';
import { formatDate, getCurrentDate } from "../../../../../Utils/utils";
import { getCountries } from "../../../../../Services/ApiServices";
import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im";

const AddPassenger = (props) => {
    const [checked, setChecked] = useState(false);
    const [countries, setCountries] = useState([])
    const [passenger, setPassenger] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "ageLimit": false,
        "isLeadPassenger": false,
        "phone": {
            "mobile": "",
            "countryCode": "IN",
            "callingCode": "91"
        },
        "alternatePhoneNo": {
            "mobile": "",
            "countryCode": "",
            "callingCode": ""
        },
        "passportDetails": {
            "passportName": "",
            "passportSurname": "",
            "passportDOB": "",
            "passportNumber": "",
            "passportIssueDate": "",
            "passportExpiryDate": "",
            "passportIssuePlace": "",
            "passportNationality": ""
        },
        "panDetails": {
            "panName": "",
            "panNo": ""
        },


    })
    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
        })
    }

    useEffect(() => {
        fetchCountries()
    }, [])


    useEffect(() => {
        setPassenger((prev) => {
            return {
                ...prev,
                "isLeadPassenger": props?.isLeadPassenger
            }
        })
    }, [props])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneFormat, setPhoneFormat] = useState({
        dialCode: "91",
        countryCode: "IN"
    })
    const [email, setEmail] = useState("");
    const [altPhone, setAltPhone] = useState("");
    const [altPhoneFormat, setAltPhoneFormat] = useState({
        dialCode: "91",
        countryCode: "IN"
    })
    const [panName, setPanName] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [passportName, setPassportName] = useState("");
    const [passportSurname, setPassportSurname] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [passportDOB, setPassportDOB] = useState("");
    const [passportIssueDate, setPassportIssueDate] = useState("");
    const [passportIssuePlace, setPassportIssuePlace] = useState("");
    const [passportExpiryDate, setPassportExpiryDate] = useState("");
    const [passportNationality, setPassportNationality] = useState("");


    const [in1, setIn1] = useState(false);
    const [in2, setIn2] = useState(false);
    const [in3, setIn3] = useState(false);
    const [in4, setIn4] = useState(false);
    const [in5, setIn5] = useState(false);
    const [in6, setIn6] = useState(false);
    const [in7, setIn7] = useState(false);
    const [in8, setIn8] = useState(false);
    const [in9, setIn9] = useState(false);
    const [in10, setIn10] = useState(false);
    const [in11, setIn11] = useState(false);
    const [in12, setIn12] = useState(false);
    const [in13, setIn13] = useState(false);
    const [in14, setIn14] = useState(false);
    const [in15, setIn15] = useState(false);

    useEffect(() => {
        if (props?.passenger[0] && props?.index === 0 && props?.checkIsLeadPassenger === 'true') {
            //diabled field or enabled filed
            if (props?.matchUserData) {
                setPassenger(props?.matchUserData)
                setFirstName(props?.matchUserData?.firstName ? props?.matchUserData?.firstName : '')
                setLastName(props?.matchUserData?.lastName ? props?.matchUserData?.lastName : '')
                setEmail(props?.matchUserData?.email ? props?.matchUserData?.email : "")
                setAltPhone(props?.matchUserData?.alternatePhoneNo ? props?.matchUserData?.alternatePhoneNo?.mobile : "")
                setPhone(props?.matchUserData?.phone ? props?.matchUserData?.phone?.mobile : "")
                setPanName(props?.matchUserData?.panDetails?.panName ? props?.matchUserData?.panDetails?.panName : "")
                setPanNumber(props?.matchUserData?.panDetails?.panNo ? props?.matchUserData?.panDetails?.panNo : "")
                setPassportName(props?.matchUserData?.passportDetails?.passportName ? props?.matchUserData?.passportDetails?.passportName : "")
                setPassportSurname(props?.matchUserData?.passportDetails?.passportSurname ? props?.matchUserData?.passportDetails?.passportSurname : "")
                setPassportNumber(props?.matchUserData?.passportDetails?.passportNumber ? props?.matchUserData?.passportDetails?.passportNumber : "")
                setPassportIssueDate(props?.matchUserData?.passportDetails?.passportIssueDate ? formatDate(props?.matchUserData?.passportDetails?.passportIssueDate) : "")
                setPassportExpiryDate(props?.matchUserData?.passportDetails?.passportExpiryDate ? formatDate(props?.matchUserData?.passportDetails?.passportExpiryDate) : "")
                setPassportDOB(props?.matchUserData?.passportDetails?.passportDOB ? formatDate(props?.matchUserData?.passportDetails?.passportDOB) : "")
                setPassportIssuePlace(props?.matchUserData?.passportDetails?.passportIssuePlace ? props?.matchUserData?.passportDetails?.passportIssuePlace : "")
                setPassportNationality(props?.matchUserData?.passportDetails?.passportNationality ? props?.matchUserData?.passportDetails?.passportNationality : "")
                setIn1(props?.matchUserData?.firstName ? true : false)
                setIn2(props?.matchUserData?.lastName ? true : false)
                setIn3(props?.matchUserData?.email ? true : false)
                setIn4(props?.matchUserData?.phone?.mobile ? true : false)
                setIn5(props?.matchUserData?.alternatePhoneNo?.mobile ? true : false)
                setIn6(props?.matchUserData?.panDetails?.panName ? true : false)
                setIn7(props?.matchUserData?.panDetails?.panNo ? true : false)
                setIn8(props?.matchUserData?.passportDetails?.passportName ? true : false)
                setIn9(props?.matchUserData?.passportDetails?.passportSurname ? true : false)
                setIn10(props?.matchUserData?.passportDetails?.passportNumber ? true : false)
                setIn11(props?.matchUserData?.passportDetails?.passportIssueDate ? true : false)
                setIn12(props?.matchUserData?.passportDetails?.passportExpiryDate ? true : false)
                setIn13(props?.matchUserData?.passportDetails?.passportDOB ? true : false)
                setIn14(props?.matchUserData?.passportDetails?.passportIssuePlace ? true : false)
                setIn15(props?.matchUserData?.passportDetails?.passportNationality ? true : false)
                let passengers = passenger;
                passengers = Object.assign(passengers, {
                    "firstName": props?.matchUserData?.firstName ? props?.matchUserData?.firstName : "",
                    "lastName": props?.matchUserData?.lastName ? props?.matchUserData?.lastName : "",
                    "email": props?.matchUserData?.email ? props?.matchUserData?.email : "",
                    "ageLimit": false,
                    "isLeadPassenger": true,
                    "phone": {
                        "mobile": props?.matchUserData?.phone ? props?.matchUserData?.phone?.mobile : "",
                        "countryCode": "IN",
                        "callingCode": "91"
                    },
                    "alternatePhoneNo": {
                        "mobile": props?.matchUserData?.alternatePhoneNo ? props?.matchUserData?.alternatePhoneNo?.mobile : "",
                        "countryCode": "IN",
                        "callingCode": "91"
                    },
                    "passportDetails": {
                        "passportName": props?.matchUserData?.passportDetails?.passportName ? props?.matchUserData?.passportDetails?.passportName : "",
                        "passportSurname": props?.matchUserData?.passportDetails?.passportSurname ? props?.matchUserData?.passportDetails?.passportSurname : "",
                        "passportDOB": props?.matchUserData?.passportDetails?.passportDOB ? formatDate(props?.matchUserData?.passportDetails?.passportDOB) : "",
                        "passportNumber": props?.matchUserData?.passportDetails?.passportNumber ? props?.matchUserData?.passportDetails?.passportNumber : "",
                        "passportIssueDate": props?.matchUserData?.passportDetails?.passportIssueDate ? formatDate(props?.matchUserData?.passportDetails?.passportIssueDate) : "",
                        "passportExpiryDate": props?.matchUserData?.passportDetails?.passportExpiryDate ? formatDate(props?.matchUserData?.passportDetails?.passportExpiryDate) : "",
                        "passportIssuePlace": props?.matchUserData?.passportDetails?.passportIssuePlace ? props?.matchUserData?.passportDetails?.passportIssuePlace : "",
                        "passportNationality": props?.matchUserData?.passportDetails?.passportNationality ? props?.matchUserData?.passportDetails?.passportNationality : ""
                    },
                    "panDetails": {
                        "panName": props?.matchUserData?.panDetails?.panName ? props?.matchUserData?.panDetails?.panName : "",
                        "panNo": props?.matchUserData?.panDetails?.panNo ? props?.matchUserData?.panDetails?.panNo : ""
                    },
                })
                setPassenger(passengers)
                let arr = props?.passenger;
                arr[props.index] = passengers
                props.setPassenger(arr)
            } else {
                setIn1(false)
                setIn2(false)
                setIn3(false)
                setIn4(false)
                setIn5(false)
                setIn6(false)
                setIn7(false)
                setIn8(false)
                setIn9(false)
                setPhone('')
                setIn10(false)
                setIn11(false)
                setIn12(false)
                setIn13(false)
                setIn14(false)
                setIn15(false)
            }

        } else {
            if (props?.passenger[0] && props?.index === 0) {
                setFirstName('')
                setLastName('')
                setEmail("")
                setAltPhone("")
                setPhone("")
                setPanName("")
                setPanNumber("")
                setPassportName("")
                setPassportSurname("")
                setPassportNumber("")
                setPassportIssueDate("")
                setPassportExpiryDate("")
                setPassportDOB("")
                setPassportIssuePlace("")
                setPassportNationality("")

                //diabled field 

                setIn1(false)
                setIn2(false)
                setIn3(false)
                setIn4(false)
                setIn5(false)
                setIn6(false)
                setIn7(false)
                setIn8(false)
                setIn9(false)
                setIn10(false)
                setIn11(false)
                setIn12(false)
                setIn13(false)
                setIn14(false)
                setIn15(false)

                let passengers = passenger;
                passengers = Object.assign(passengers, {
                    "firstName": "",
                    "lastName": "",
                    "email": "",
                    "ageLimit": false,
                    "isLeadPassenger": false,
                    "phone": {
                        "mobile": "",
                        "countryCode": "IN",
                        "callingCode": "91"
                    },
                    "alternatePhoneNo": {
                        "mobile": "",
                        "countryCode": "",
                        "callingCode": ""
                    },
                    "passportDetails": {
                        "passportName": "",
                        "passportSurname": "",
                        "passportDOB": "",
                        "passportNumber": "",
                        "passportIssueDate": "",
                        "passportExpiryDate": "",
                        "passportIssuePlace": "",
                        "passportNationality": ""
                    },
                    "panDetails": {
                        "panName": "",
                        "panNo": ""
                    },
                })
                setPassenger(passengers)
                let arr = props?.passenger;
                arr[props.index] = passengers
                props.setPassenger(arr)

            } else {
                setPassenger(props?.passenger[(props?.index)])
                setFirstName(props?.passenger[(props?.index)]?.firstName ? props?.passenger[(props?.index)]?.firstName : '')
                setLastName(props?.passenger[(props?.index)]?.lastName ? props?.passenger[(props?.index)]?.lastName : '')
                setEmail(props?.passenger[(props?.index)]?.email ? props?.passenger[(props?.index)]?.email : "")
                setAltPhone(props?.passenger[(props?.index)]?.alternatePhoneNo ? props?.passenger[(props?.index)]?.alternatePhoneNo?.mobile : "")
                setPhone(props?.passenger[(props?.index)]?.phone ? props?.passenger[(props?.index)]?.phone?.mobile : "")
                setPanName(props?.passenger[(props?.index)]?.panDetails?.panName ? props?.passenger[(props?.index)]?.panDetails?.panName : "")
                setPanNumber(props?.passenger[(props?.index)]?.panDetails?.panNo ? props?.passenger[(props?.index)]?.panDetails?.panNo : "")
                setPassportName(props?.passenger[(props?.index)]?.passportDetails?.passportName ? props?.passenger[(props?.index)]?.passportDetails?.passportName : "")
                setPassportSurname(props?.passenger[(props?.index)]?.passportDetails?.passportSurname ? props?.passenger[(props?.index)]?.passportDetails?.passportSurname : "")
                setPassportNumber(props?.passenger[(props?.index)]?.passportDetails?.passportNumber ? props?.passenger[(props?.index)]?.passportDetails?.passportNumber : "")
                setPassportIssueDate(props?.passenger[(props?.index)]?.passportDetails?.passportIssueDate ? formatDate(props?.passenger[(props?.index)]?.passportDetails?.passportIssueDate) : "")
                setPassportExpiryDate(props?.passenger[(props?.index)]?.passportDetails?.passportExpiryDate ? formatDate(props?.passenger[(props?.index)]?.passportDetails?.passportExpiryDate) : "")
                setPassportDOB(props?.passenger[(props?.index)]?.passportDetails?.passportDOB ? formatDate(props?.passenger[(props?.index)]?.passportDetails?.passportDOB) : "")
                setPassportIssuePlace(props?.passenger[(props?.index)]?.passportDetails?.passportIssuePlace ? props?.passenger[(props?.index)]?.passportDetails?.passportIssuePlace : "")
                setPassportNationality(props?.passenger[(props?.index)]?.passportDetails?.passportNationality ? props?.passenger[(props?.index)]?.passportDetails?.passportNationality : "")
            }
        }
    }, [props?.passenger, props?.checkIsLeadPassenger])
    return (
        <div className="px-2 pb-4 bg-white border-bottom" style={{ width: "100%" }}>
            <h3 className="nk-block-title page-title pt-3">{props?.index=="0" ? <ImRadioChecked size={20} /> : <ImRadioUnchecked size={20} />}  Passenger Detail {props.index + 1} {props.isLeadPassenger ? ' (Lead Passenger)' : ''}</h3>
            <div className="nk-block-head mt-3">
                <h5 className="title">Personal Detail</h5>
            </div>
            <form className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-fname">First Name</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-fname"
                                    name="firstName"
                                    disabled={in1}
                                    value={firstName}
                                    placeholder="Enter First Name"
                                    onChange={(e, a) => {
                                        setIn1(false)
                                        setFirstName(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { firstName: e.target.value })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-lname">Last Name</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-lname"
                                    name="lastName"
                                    disabled={in2}
                                    value={lastName}
                                    placeholder="Enter Last Name"
                                    onChange={(e, a) => {
                                        setIn2(false);
                                        setLastName(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { lastName: e.target.value })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-email">Email</label>
                            <div className="form-control-wrap">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="fv-email"
                                    disabled={in3}
                                    name="email"
                                    value={email}
                                    placeholder="Enter Email Address"
                                    onChange={(e, a) => {
                                        setIn3(false)
                                        setEmail(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { email: e.target.value })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                            <div className="form-control-wrap">
                                <span className="countryCode">
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            disabled: true,
                                            className: "hgt01"
                                        }}
                                        value={"+" + phoneFormat.dialCode}
                                        onChange={(e, a) => {
                                            setPhoneFormat(a);
                                            let passengers = passenger;
                                            passengers = Object.assign(passengers, { phone: { mobile: phone, countryCode: a.countryCode.toUpperCase(), callingCode: parseInt(a.dialCode) } })
                                            setPassenger(passengers)
                                            let arr = props?.passenger;
                                            arr[props.index] = passengers
                                            props.setPassenger(arr)
                                        }}
                                    />
                                </span>
                                <input
                                    type="number"
                                    className="form-control pl-110"
                                    id="fv-phone"
                                    name="phone"
                                    maxLength={10}
                                    disabled={in4}
                                    value={phone}
                                    placeholder="Enter Phone Number"
                                    onChange={(e) => {
                                        setIn4(false)
                                        setPhone(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { phone: { mobile: e.target.value, countryCode: phoneFormat.countryCode.toUpperCase(), callingCode: parseInt(phoneFormat.dialCode) } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-altphone">Alternate Phone Number</label>
                            <div className="form-control-wrap">
                                <span className="countryCode">
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            disabled: true,
                                            className: "hgt01"
                                        }}

                                        value={"+" + altPhoneFormat.dialCode}
                                        onChange={(e, a) => {
                                            setAltPhoneFormat(a);
                                            let passengers = passenger;
                                            passengers = Object.assign(passengers, { altPhone: { mobile: altPhone, countryCode: a.countryCode.toUpperCase(), callingCode: parseInt(a.dialCode) } })
                                            setPassenger(passengers)
                                            let arr = props?.passenger;
                                            arr[props.index] = passengers
                                            props.setPassenger(arr)
                                        }}
                                    />
                                </span>
                                <input
                                    type="number"
                                    className="form-control pl-110"
                                    id="fv-altphone"
                                    name="altPhone"
                                    maxLength={10}
                                    disabled={in5}
                                    value={altPhone}
                                    placeholder="Enter Alternate Phone Number"
                                    onChange={(e, a) => {
                                        setIn5(false)
                                        setAltPhone(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { altPhone: { mobile: e.target.value, countryCode: altPhoneFormat.countryCode.toUpperCase(), callingCode: parseInt(altPhoneFormat.dialCode) } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="nk-block-head mt-4">
                <h5 className="title">PAN Detail</h5>
            </div>
            <form className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pan-name">Pan Name</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-pan-name"
                                    name="panDetails"
                                    disabled={in6}
                                    value={panName}
                                    placeholder="Enter Pan Name"
                                    onChange={(e, a) => {
                                        setIn6(false)
                                        setPanName(e.target.value);
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { panDetails: { panName: e.target.value, panNo: panNumber } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-panNo">Pan Number</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-panNo"
                                    name="panDetails"
                                    disabled={in7}
                                    value={panNumber}
                                    placeholder="Enter Pan Number"
                                    onChange={(e, a) => {
                                        setIn7(false)
                                        setPanNumber(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { panDetails: { panName: panName, panNo: e.target.value } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <div className="nk-block-head mt-4">
                <h5 className="title">Passport Detail</h5>
            </div>
            <form className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-name">Name</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-pass-name"
                                    disabled={in8}
                                    value={passportName}
                                    name="passportDetails"
                                    placeholder="Enter Name"
                                    onChange={(e, a) => {
                                        setIn8(false)
                                        setPassportName(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: e.target.value, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-surname">Surname</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={in9}
                                    id="fv-pass-surname"
                                    value={passportSurname}
                                    name="passportDetails"
                                    placeholder="Enter Surname"
                                    onChange={(e, a) => {
                                        setIn9(false)
                                        setPassportSurname(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: e.target.value, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-no">Passport Number</label>
                            <div className="form-control-wrap">
                                <input
                                    disabled={in10}
                                    type="text"
                                    className="form-control"
                                    id="fv-pass-no"
                                    value={passportNumber}
                                    name="passportDetails"
                                    placeholder="Enter Passport number"
                                    onChange={(e, a) => {
                                        setIn10(false)
                                        setPassportNumber(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: passportSurname, passportNumber: e.target.value, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-dob">Date Of Birth</label>
                            <div className="form-control-wrap">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="fv-dob"
                                    max={getCurrentDate()}
                                    disabled={in11}
                                    value={passportDOB}
                                    name="passportDetails"
                                    placeholder="Enter Date of Birth"
                                    onChange={(e, a) => {
                                        setIn11(false)
                                        setPassportDOB(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(e.target.value), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-issue-date">Issue Date</label>
                            <div className="form-control-wrap">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="fv-pass-issue-date"
                                    disabled={in12}
                                    value={passportIssueDate}
                                    name="passportDetails"
                                    placeholder="Enter Issue Date"
                                    onChange={(e, a) => {
                                        setIn12(false)
                                        setPassportIssueDate(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(e.target.value), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-expiry">Expiry Date</label>
                            <div className="form-control-wrap">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="fv-pass-expiry"
                                    disabled={in13}
                                    value={passportExpiryDate}
                                    name="passportDetails"
                                    placeholder="Enter Expiry Date"
                                    onChange={(e, a) => {
                                        setIn13(false)
                                        setPassportExpiryDate(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(e.target.value), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-place">Issue Place</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-pass-place"
                                    disabled={in14}
                                    value={passportIssuePlace}
                                    name="passportDetails"
                                    placeholder="Enter Issue Place"
                                    onChange={(e, a) => {
                                        setIn14(false)
                                        setPassportIssuePlace(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportName: passportName, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: e.target.value, passportExpiryDate: formatDate(passportExpiryDate), passportNationality: passportNationality } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-pass-nationality">Nationality</label>
                            <div className="form-control-wrap">
                                <select name="passportDetails"
                                    disabled={in15}
                                    value={passportNationality}
                                    onChange={(e, a) => {
                                        setIn15(false)
                                        setPassportNationality(e.target.value)
                                        let passengers = passenger;
                                        passengers = Object.assign(passengers, { passportDetails: { passportNationality: e.target.value, passportName: passportName, passportSurname: passportSurname, passportNumber: passportNumber, passportDOB: formatDate(passportDOB), passportIssueDate: formatDate(passportIssueDate), passportIssuePlace: passportIssuePlace, passportExpiryDate: formatDate(passportExpiryDate) } })
                                        setPassenger(passengers)
                                        let arr = props?.passenger;
                                        arr[props.index] = passengers
                                        props.setPassenger(arr)
                                    }}
                                >
                                    <option value="">Select Nationality</option>
                                    {countries.map((val, index) => {
                                        return (
                                            <option value={val.name} key={index}>{val.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="form-group">
                            <Checkbox
                                checked={checked}
                                name="ageLimit"
                                onChange={(e) => {
                                    setChecked(e.target.checked);
                                    let passengers = passenger;
                                    passengers = Object.assign(passengers, { ageLimit: e.target.checked })
                                    setPassenger(passengers)
                                    let arr = props?.passenger;
                                    arr[props.index] = passengers;
                                    props.setPassenger(arr)
                                }
                                }
                                overrides={{
                                    Checkmark: {
                                        style: ({ $checked }) => ({
                                            borderLeftColor: "#212c34",
                                            borderRightColor: "#212c34",
                                            borderTopColor: "#212c34",
                                            borderBottomColor: "#212c34",
                                            backgroundColor: $checked
                                                ? "#212c34"
                                                : "#fff",
                                        }),
                                    },
                                    Label: {
                                        style: ({
                                            color: "#000",
                                            fontSize: "16px",
                                            fontWeight: "400"
                                        })
                                    },
                                    ToggleInner: {
                                        style: ({
                                            backgroundColor: "#000"
                                        })
                                    }
                                }}
                            >
                                Is He/She having age between 18 to 40 years?
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </form >
        </div >
    )
}
export default AddPassenger;