import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { putMethod } from "../../../Services/ApiServices";
import { IoTrashOutline } from "react-icons/io5";
import Features from "./Features/Features";

const AddHighlight = (props) => {
    const [highlightTitle, setHighlightTitle] = useState("");
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        let arr = props.highlight;
        arr[props.index] = {
            ...arr[props.index],
            "highlight": features
        }
        props.setHighlight(arr)
        
    }, [features])

    useEffect(()=>{
        setHighlightTitle(props?.data?.heading)
        setFeatures(props?.data?.highlight)
    },[props])

    
    const handleSubmit = (e) => {
        e.preventDefault();
    }
    const deleteHighlight=(e)=>{
        e.preventDefault();
        const endpoint="admin/delete/highlight"
        const params={
            "productId":props?.prodId,
            "highlightsId":props?.highId
        }
        putMethod(endpoint,'',params).then((res)=>{
            if(res.success){
                props.showToast(true);
                props.showMessage("Highlight Deleted");
                props.highlight.splice(props.index, 1)
            }
            else{
                props.showToast(true);
                props.showMessage(res.error)
            }
        })
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6 mt-2">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-title">Highlight Title</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-title"
                                    name="heading"
                                    value={highlightTitle}
                                    placeholder="Enter Highlight Title"
                                    onChange={(e) => {
                                        let arr = props.highlight;
                                        arr[props.index] = {
                                            [e.target.name]: e.target.value,
                                            "highlight": features
                                        }
                                        props.setHighlight(arr)
                                        setHighlightTitle(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <button className="d-flex del-btn ml-auto" onClick={deleteHighlight}><IoTrashOutline color="#000" size={18} /></button>
                    </div>

                    {features?.map((val, index) => {
                        return (
                            <div className="col-md-12">
                                <Features data={val} prodId={props?.prodId} highId={props.highId} featureId={val._id} setFeatures={setFeatures} features={features} index={index} showToast={props?.showToast} showMessage={props?.showMessage} />
                            </div>
                        )
                    })}

                    <div className="col-md-6">
                        <Button className="feature-btn" onClick={() => {
                            setFeatures(prev => [...prev, {
                                "highlightImages": [],
                                "highlightTitle": "",
                                "highlightDescription": ""

                            }])
                        }}>Add Features<FiPlus className="ml-1" color="#fff" size={18} /></Button>
                    </div>
                </div>
            </form>


        </div>

    )
}
export default AddHighlight;