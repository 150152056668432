import React, { useEffect, useState } from "react";
import { getCities, getCountries, getStates, putMethod } from "../../../../../Services/ApiServices";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const UpdateAddress = (props) => {
    const [showToast, setShowToast] = useState(false);
    const [initialFetched, setInitialFetched] = useState({ states: false, cities: false })
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [address, setAddress] = useState(props.data);
    const [editAddress, setEditAddress] = useState(false);
    const [countryCode, setCountryCode] = useState();
    const [stateCode, setStateCode] = useState()
    const [phone, setPhone] = useState("");
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in"
    });

    useEffect(() => {
        if (props?.data) {
            setPhone(props?.data?.phoneNo?.mobile ? props?.data?.phoneNo?.mobile : "");
            setPhoneFormat({
                "dialCode": props?.data?.phoneNo?.callingCode ? props?.data?.phoneNo?.callingCode : "91",
                "countryCode": props?.data?.phoneNo?.countryCode ? props?.data?.phoneNo?.countryCode : "in"
            })
        }
        fetchCountries();
    }, [])

    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
            if (!initialFetched.states) {
                setInitialFetched(state => ({ ...state, states: true }));
                res.data.forEach(x => {
                    if (x.name === props?.data?.country) {
                        setCountryCode(x.isoCode);
                        fetchStates(x.isoCode);
                    }
                })
            }
        }).catch(err => {

        });
    }

    const fetchStates = (countryCode) => {
        getStates(countryCode).then(res => {
            setStates(res.data);
            if (!initialFetched.cities) {
                setInitialFetched(state => ({ ...state, cities: true }));
                res.data.forEach(x => {
                    if (x.name === props?.data?.state) {
                        setCountryCode(x.isoCode);
                        fetchCities(countryCode, x.isoCode);
                    }
                })
            }
        }).catch(err => {

        });
    }

    const fetchCities = (countryCode, stateCode) => {
        getCities(countryCode, stateCode).then(res => {
            setCities(res.data);
        }).catch(err => {

        });
    }

    const updateAddress = async (id) => {
        const endpoint = "user/address";
        const params = {
            "addressId": id
        }

        await putMethod(endpoint, address, params).then((res) => {
            console.log(res)
            if (res.success) {
                props.showToast(true);
                //props.toastHead("Success");
                props.toastMessage("Address Updated Successfully");
                props.reload(true);
                setEditAddress(false);
                if (props?.userId) {
                    props?.agentAddressDetailsFetch(props?.userId)
                } else {
                    props?.agentAddressDetailsFetch()
                }
            }
            else {
                props.showToast(true);
                //props.toastHead("Error");
                props.toastMessage(res.error)
            }
        })
    }

    const handleChange = (e) => {
        setAddress((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,

            };
        });
    }

    return (

        <>
            {editAddress ?
                <div>
                    <div className="px-3">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-name">Name or Company Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-name"
                                                type="text"
                                                name="userName"
                                                placeholder="Name or Company Name"
                                                value={address.userName}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-gst">GST No</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-gst"
                                                type="text"
                                                name="gstNo"
                                                placeholder="GST Number"
                                                value={address.gstNo}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                        <div className="form-control-wrap">
                                            <span className="countryCode">
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phoneNo',
                                                        disabled: true,
                                                        className: "hgt01"
                                                    }}
                                                    value={"+" + phoneFormat.dialCode}
                                                    onChange={(e, a) => {
                                                        setPhoneFormat(a);
                                                        setAddress((prev) => {
                                                            return {
                                                                ...prev,
                                                                "phoneNo": { mobile: phone, countryCode: a.countryCode.toUpperCase(), callingCode: parseInt(a.dialCode) }
                                                            }
                                                        })
                                                    }}
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control pl-110"
                                                id="fv-phone"
                                                name="phoneNo"
                                                value={phone}
                                                placeholder="Enter Phone Number"
                                                onChange={(e) => {
                                                    setPhone(e.target.value)
                                                    setAddress((prev) => {
                                                        return {
                                                            ...prev,
                                                            [e.target.name]: { mobile: e.target.value, countryCode: phoneFormat.countryCode.toUpperCase(), callingCode: parseInt(phoneFormat.dialCode) }
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-hno">House/Flat Number</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-hno"
                                                type="text"
                                                name="buildingName"
                                                placeholder="House/Flat Number"
                                                value={address.buildingName}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-address">Street Address</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-address"
                                                type="text"
                                                name="address"
                                                placeholder="Street Address"
                                                value={address.address}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-country">Country</label>
                                        <div className="form-control-wrap">
                                            <select custom name="country" value={address?.country}
                                                onChange={(e) => {
                                                    setAddress((prev) => {
                                                        return {
                                                            ...prev,
                                                            [e.target.name]: e.target.value,
                                                            state: '',
                                                            city: ''
                                                        };
                                                    });
                                                    countries.forEach(x => {
                                                        if (x.name === e.target.value) {
                                                            setCountryCode(x.isoCode)
                                                            fetchStates(x.isoCode)
                                                        }
                                                    });

                                                }}>
                                                <option>Choose Country</option>
                                                {countries.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.name}>{val.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-state">State</label>
                                        <div className="form-control-wrap">
                                            <select custom name="state" value={address?.state}
                                                onChange={(e) => {
                                                    setAddress((prev) => {
                                                        return {
                                                            ...prev,
                                                            [e.target.name]: e.target.value,
                                                            city: ''
                                                        };
                                                    });
                                                    states.forEach(x => {
                                                        if (x.name === e.target.value) {
                                                            setStateCode(x.isoCode)
                                                            fetchCities(countryCode, x.isoCode)
                                                        }
                                                    });

                                                }}
                                            >
                                                <option>Choose State</option>
                                                {states.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.name}>{val.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-city">City</label>
                                        <div className="form-control-wrap">
                                            <select custom name="city" value={address?.city}
                                                onChange={(e) => {
                                                    setAddress((prev) => {
                                                        return {
                                                            ...prev,
                                                            [e.target.name]: e.target.value,
                                                        };
                                                    });

                                                }}
                                            >
                                                <option>Choose City</option>
                                                {cities.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.name}>{val.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-portcode">Zip Code</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-portcode"
                                                type="text"
                                                name="portCode"
                                                placeholder="Zip Code"
                                                value={address.portCode}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="py-4 px-3 fr_aI">
                        <button type="button" className="btn btn-primary btn-md" onClick={() => { updateAddress(address._id) }}>Update</button>
                        <button className="btn_none pl-4" onClick={() => { setEditAddress(false) }}>Cancel</button>
                    </div>
                </div>
                :
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <h6>{props.data.userName}</h6>
                        <div>
                            <button type="button" className="button_none uL_18 btn btn-primary" onClick={() => { setEditAddress(true) }}>EDIT</button>
                        </div>
                    </div>
                    <div className="fr_aI">
                        <p className="p-text1">
                            {props.data.buildingName},
                            {props.data.address},
                            {props.data.city},
                            {props.data.state},
                            {props.data.country},
                            {props.data.portCode}
                        </p>
                    </div>
                </div>
            }
        </>

    )
}
export default UpdateAddress;