import React from "react";
import DoubleBed from "../../../../../Components/BedType/DoubleBed";
import SingleBed from "../../../../../Components/BedType/SingleBed";
import { detectBed, inr } from '../../../../../Utils/utils';

const RoomType = (props) => {
    const {bgColor="#101924", color="#fff", innerColor="#fff"} = props
    return (
        <div className="roomBlock" style={{backgroundColor: bgColor, color:color}}>
            <div className="rBlock">
                {props?.data?.map((val, index) => {
                    return (
                        <div className="roomBlockInner" key={index} style={{backgroundColor: bgColor, color:color}}>
                            <div>
                                <h3 className="room-type" style={{color:color}}>{val?.noOfRooms > 1 ? val?.noOfRooms : null}{val?.noOfRooms > 1 ?<span className="cross">𐤕</span>: null}{val?.name}</h3>
                            </div>
                            <div className="roomInnerMainBlock" style={{backgroundColor: innerColor}}>
                                <h3 className="room-h5">{val?.noOfPerson}<span className="cross">𐤕</span> {val?.noOfPerson > 1 ? "adults" : "adult"}</h3>
                                <div className="pl-4 fr_aI">
                                    {
                                        detectBed(val?.bed?.double)?.map((item, index) => {
                                            return (
                                                <DoubleBed key={index} bgColor={index.toString() < val?.noOfPerson ? "#212c34" : "#b3b3b3"} />
                                            )
                                        })
                                    }
                                    {
                                        detectBed(val?.bed?.single)?.map((item, index) => {
                                            return (
                                                <SingleBed key={index} bgColor={index.toString() < val?.noOfPerson ? "#212c34" : "#b3b3b3"} />
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className="room-price" style={{color:color}}>{props?.price>0?`INR +${inr(props?.price)}`: null}</h2>
            </div>

        </div>

    )
}
export default RoomType;