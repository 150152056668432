import React from "react";
import { inr } from "../../../../Utils/utils";

const TripDetails = (props) => {
    const dateFormatConverter = (arg) => {
        let date = new Date(arg);
        let datel = date.getUTCDate() < 10 ? ("0" + date.getUTCDate()).slice(-2) : date.getUTCDate();
        let month = date.getUTCMonth() < 10 ? ("0" + (date.getUTCMonth() + 1)).slice(-2) : (date.getUTCMonth() + 1);
        let returnDate = datel + '-' + month + '-' + date.getUTCFullYear()
        return returnDate;
    }
    return (
        <div className="border px-2 mb-3 bg-white" style={{ width: "100%" }}>
            <div className="profile-ud-list">
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip Name</span>
                        <span className="profile-ud-value">{props?.data?.productDetails?.tripName ? props?.data?.productDetails?.tripName : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip Amount</span>
                        <span className="profile-ud-value">INR {props?.data?.productDetails?.sellingPrice ? inr(props?.data?.productDetails?.sellingPrice) : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip Booking Start Date</span>
                        <span className="profile-ud-value">{props?.data?.productDetails?.tripBookingTripStartDate ? dateFormatConverter(props?.data?.productDetails?.tripBookingTripStartDate) : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip Booking End Date</span>
                        <span className="profile-ud-value">{props?.data?.productDetails?.tripBookingTripEndDate ? dateFormatConverter(props?.data?.productDetails?.tripBookingTripEndDate) : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip Start Date</span>
                        <span className="profile-ud-value">{props?.data?.productDetails?.tripGoingDate ? dateFormatConverter(props?.data?.productDetails?.tripGoingDate) : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trip End Date</span>
                        <span className="profile-ud-value">{props?.data?.productDetails?.tripLeavingDate ? dateFormatConverter(props?.data?.productDetails?.tripLeavingDate) : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Arrival Airport</span>
                        <span className="profile-ud-value">{props?.data?.productId?.arrivalAirport ? props?.data?.productId?.arrivalAirport : "-"}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Departure Airport</span>
                        <span className="profile-ud-value">{props?.data?.productId?.departureAirport ? props?.data?.productId?.departureAirport : "-"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TripDetails;