import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import ToastMessage from "../../../../Components/Toast/Toast";
import { deleteMethod, getMethod } from "../../../../Services/ApiServices";
import { IoDocumentTextOutline } from "react-icons/io5";
import MUIDataTable from "mui-datatables";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { format } from 'fecha';
const AgentList = (props) => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const [searchToggle, setSearchToggle] = useState(false);
    const [page, setPage] = useState(1);
    const [prevVal, setPrevVal] = useState(1);
    const [nextVal, setNextVal] = useState(10);
    const [search, setSearch] = useState("");
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    async function fetchAgents() {
        const endpoint = "admin/agents/list"
        await getMethod(endpoint).then((res) => {
            if (res.success) {
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        fetchAgents()
    }, [])

    const searchVal = (e) => {
        setSearch(e.target.value);
        const endpoint = "admin/agents/list"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10,
            "search": e.target.value
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setList(res.data);
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)
    }

    const previous = () => {
        const endpoint = "admin/agents/list"
        const p = page == 1 ? page : page - 1
        const params = {
            "page": p,
            "limit": 10
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setList(res.data)
            }
        })
        setPage(p)
        setPrevVal((p * 10) - 9)
        setNextVal(p * 10)

    }
    const next = (currentPage) => {
        const endpoint = "admin/agents/list"
        const p = page >= 1 ? page + 1 : 1
        var params = {
            "page": p,
            "limit": 10
        }
        if (currentPage) {
            params = {
                "page": currentPage,
                "limit": 10
            }
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setList(res.data)
            }
        })
        if (currentPage) {
        } else {
            setPage(p);
            setPrevVal((p * 10) - 9)
            setNextVal(p * 10)
        }
    }

    const data = list?.map((item) => {
        const ar = [];
        ar.push(
            item.name ? item.name : "-",
            item.agencyName ? item.agencyName : "-",
            item.email ? item.email : "-",
            item?.phone ? "+" + item?.phone?.callingCode + " " + item?.phone?.mobile : "-",
            item.createdAt ? format(new Date(item.createdAt), 'Do MMM YYYY') : "-",
            item?._id ?
                <div className="drodown">
                    <a className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            <li><a onClick={() => viewDetail(item._id)}><em className="icon ni ni-eye"></em><span>View Details</span></a></li>
                            <li><a onClick={() => deleteAgent(item._id, item)}><em className="icon ni ni-trash"></em><span>Delete Agent</span></a></li>
                        </ul>
                    </div>
                </div>
                :
                null
        );
        return ar;
    })

    const columns = [
        "Name",
        "Agent Name",
        "Email",
        "Phone",
        "Created At",
        "Action"
    ];
    const options = {
        filter: false,
        filterType: "dropdown",
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        search: false
    };

    const viewDetail = (id) => {
        history.push('/add-agent', { id: id })
    }

    const deleteAgent = (id, index) => {
        const endpoint = "agent/delete";
        const params = {
            "agentId": id
        }
        deleteMethod(endpoint, params).then((res) => {
            if (res.success) {
                setShowToast(true);
                next(page)
                //list.splice(index, 1);
                setShowMessage("Agent deleted Successfully");

            }
            else {
                setShowToast(true);
                setShowMessage(res.error);
            }
        })
    }
    return (
        <div className="nk-content ">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Agents</h3>
                                </div>
                                <div className="nk-block-head-content">
                                    <Link to="/add-agent" className="btn btn-white btn-outline-light"><span>Add Agent</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="box">
                                <div className="card-inner position-relative card-tools-toggle">

                                    <div className="card-title-group">
                                        <div className="card-tools mr-n1">
                                            <p className="mb-0 p-text">All Agents</p>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a onClick={(e) => setSearchToggle(true)} className="btn btn-icon search-toggle toggle-search" data-target="search"><em className="icon ni ni-search"></em></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={searchToggle ? "card-search search-wrap active" : "card-search search-wrap"} data-search="search">
                                        <div className="card-body">
                                            <div className="search-content">
                                                <a onClick={(e) => { setSearchToggle(false); setSearch("") }} className="search-back btn btn-icon toggle-search" data-target="search"><em className="icon ni ni-arrow-left"></em></a>
                                                <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={search} onChange={searchVal} />
                                                <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </div>
                        <div className="pagination-row box">
                            <div className="fr_jE_aI">
                                <p className="mb-0">Page No. {page}</p>
                            </div>
                            <div className="mx-4 fr_jE_aI">
                                <p className="mb-0">{prevVal}</p>
                                <p className="mb-0">-</p>
                                <p className="mb-0">{nextVal}</p>
                            </div>
                            <button className="btn_none" disabled={page == 1 ? true : false} onClick={() => previous()}><FaAngleLeft size={20} color="#999999" /></button>
                            <button className="btn_none" disabled={list?.length < 10 ? true : false} onClick={() => next(0)}><FaAngleRight size={20} color="#999999" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AgentList;