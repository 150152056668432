import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ToastMessage from "../../../../Components/Toast/Toast";
import { getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import RoomType from "../AddBooking/BookingDetail/RoomType";
import InstallmentsList from "../Passenger/InstallmentsList";
import Passenger from "../Passenger/Passenger";
import User from "../Passenger/User";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
import TripDetails from "../TripDetails/TripDetails";
import { format } from 'fecha';
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';
const BookingDetail = (props) => {
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role;
    const [payuId, setPayuId] = useState("");
    const [amount, setAmount] = useState("");
    const [advStatus, setAdvStatus] = useState()
    const [showModal, setShowModal] = useState(false)
    const [BookingDetail, setBookingDetail] = useState({})
    const [showToast, setShowToast] = useState(false);
    const [noOfInstall, setNoOfInstall] = useState("");
    const [checkNormal, setCheckNormal] = useState(true);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    useEffect(() => {
        const endpoint = "booking/detail"
        const params = {
            "bookingId": props?.match?.params?.id
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setBookingDetail(res.data)
                setCheckNormal(res.data.advancedPaidStatus === 'paid' || res.data.advancedPaidStatus === 'success' ? false : true)
                setAdvStatus(res.data.advancedPaidStatus === 'paid' || res.data.advancedPaidStatus === 'success' ? 'success' : res.data.advancedPaidStatus)
            }
        })
    }, [])
    const Inr = (arg) => {
        let inr = new Intl.NumberFormat('en-IN').format(arg)
        return inr
    }
    const bookingStatus = [
        { id: 0, value: "pending" },
        { id: 1, value: "lock" },
        { id: 2, value: "paid" }
    ]
    const bookingStatus2 = [
        { id: 0, value: "pending", label: ' Pending' },
        { id: 1, value: "lock", label: 'Lock' },
        { id: 2, value: "success", label: 'Paid' }
    ]
    const redirectToPayU = async (paymentDetails, isInstallments) => {
        var fr = '<form action=\"' + process.env.REACT_APP_PAYU_URL + '\" method=\"post\">' +
            '<input type=\"hidden\" name=\"key\" value=\"' + paymentDetails.key + '\" />' +
            '<input type=\"hidden\" name=\"salt\" value=\"' + paymentDetails.salt + '\" />' +
            '<input type=\"hidden\" name=\"txnid\" value=\"' + paymentDetails.txnid + '\" />' +
            '<input type=\"hidden\" name=\"amount\" value=\"' + paymentDetails.amount + '\" />' +
            '<input type=\"hidden\" name=\"productinfo\" value=\"' + paymentDetails.productinfo + '\" />' +
            '<input type=\"hidden\" name=\"firstname\" value=\"' + paymentDetails.firstname + '\" />' +
            '<input type=\"hidden\" name=\"email\" value=\"' + paymentDetails.email + '\" />' +
            '<input type=\"hidden\" name=\"phone\" value=\"' + paymentDetails.phone + '\" />' +
            '<input type=\"hidden\" name=\"surl\" value=\"' + paymentDetails.surl + '\" />' +
            '<input type=\"hidden\" name=\"furl\" value=\"' + paymentDetails.furl + '\" />' +
            '<input type=\"hidden\" name=\"udf1\" value=\"' + paymentDetails.udf1 + '\" />' +
            '<input type=\"hidden\" name=\"udf2\" value=\"' + paymentDetails.udf2 + '\" />' +
            '<input type=\"hidden\" name=\"udf3\" value=\"' + paymentDetails.udf3 + '\" />' +
            '<input type=\"hidden\" name=\"hash\" value=\"' + paymentDetails.hash + '\" />' +
            '</form>';
        var form = $(fr);
        $('body').append(form);
        form.submit();
    }
    const handlePendingPaymentAdvance = async (getBookingData) => {
        console.log(getBookingData)
        let payuDetails = {
            key: process.env.REACT_APP_PAYU_KEY,
            salt: process.env.REACT_APP_PAYU_SALT,
            txnid: uuidv4(),
            amount: Math.round(getBookingData?.paymentId?.amount),
            firstname: JSON.parse(localStorage.getItem(LS_USER_DATA))?.name ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.name : '',
            lastName: '',
            email: JSON.parse(localStorage.getItem(LS_USER_DATA))?.email ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.email : '',
            phone: JSON.parse(localStorage.getItem(LS_USER_DATA))?.phone?.mobile ? JSON.parse(localStorage.getItem(LS_USER_DATA))?.phone?.mobile : '',
            productinfo: getBookingData?.productDetails?.tripName ? getBookingData?.productDetails?.tripName : '',
            hash: '',
            surl: process.env.REACT_APP_API_URL+'api/v1/booking/agent/payment',
            furl: process.env.REACT_APP_API_URL+'api/v1/booking/agent/payment',
            bookingId: getBookingData?.paymentId?.bookingId,
            paymentId: getBookingData?.paymentId?._id,
            udf1: getBookingData?.paymentId?.bookingId,
            udf2: getBookingData?.paymentId?._id,
            udf3: JSON.parse(localStorage.getItem(LS_USER_DATA))?._id ? JSON.parse(localStorage.getItem(LS_USER_DATA))?._id : ''
        }
        let payuHashDetails = {
            'isInstallment': false,
            'txnid': payuDetails.txnid,
            'email': payuDetails.email,
            'amount': payuDetails.amount,
            'productinfo': payuDetails.productinfo,
            'firstname': payuDetails.firstname,
            "bookingId": payuDetails.bookingId,
            "paymentId": payuDetails.paymentId,
            "udf1": payuDetails.udf1,
            "udf2": payuDetails.udf2,
            "udf3": payuDetails.udf3,
        }
        await postMethod('payment/payumoney', payuHashDetails).then(async response => {
            if (response.hash) {
                payuDetails.hash = response.hash;
                console.log(payuDetails)
                //setIsSubmitting(false);
                await redirectToPayU(payuDetails, false);
            } else {
                // setIsSubmitting(false);
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }
    const updateStatus = (e) => {
        setAdvStatus(e.target.value)
        if (role === 'Admin') {
            setShowModal(true)
            setAmount(BookingDetail?.advancePaidPayment)
        } else {
            handlePendingPaymentAdvance(BookingDetail)
        }
    }

    const handleSubmit = () => {
        if (!payuId) {
            setShowToast(true)
            setShowMessage("Please add Payu Id")
            return
        }
        const data = {
            "bookingId": BookingDetail?._id,
            "advancedPaidStatus": "paid",
            "advancePaidPayment": amount,
            "payuId": payuId
        }
        const endpoint = "admin/advanced/payment"
        postMethod(endpoint, data).then((res) => {
            if (res.success) {
                setShowToast(true)
                setShowMessage("Paid Successfully")
                setShowModal(false)
            }
            else {
                setShowToast(true)
                setShowMessage(res.error)
            }
        })
    }

    return (
        <div className="nk-content p-0">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Trip Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <TripDetails data={BookingDetail} />
                    </div>
                    {
                        BookingDetail?.userId ?
                            <>
                                <div className="nk-block-head nk-block-head-sm">
                                    <div className="nk-block-between g-3">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title page-title">User Detail</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-block">
                                    <User val={BookingDetail?.userId} />
                                </div>
                            </>
                            : null
                    }
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Passenger Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div id="accordion" className="accordion mb-3">
                            {BookingDetail?.passengersDetails?.map((val, index) => {
                                return (
                                    <Passenger key={index} index={index} val={val} data={BookingDetail} showToast={setShowToast} showMessage={setShowMessage} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Installment Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block" style={{ border: '1px solid #dbdfea' }}>
                        <div className="card-inner p-0">
                            <div className="nk-tb-list nk-tb-ulist">
                                <div className="nk-tb-item nk-tb-head">
                                    <div className="nk-tb-col"><span className="sub-text">S No.</span></div>
                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Installment Amount</span></div>
                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Type</span></div>
                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Due Date</span></div>
                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Status</span></div>
                                </div>
                                <div className="nk-tb-item">
                                    <div className="nk-tb-col">
                                        <div className="user-info">
                                            <span className="tb-lead">1</span>
                                        </div>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                        <span className="tb-lead">INR {Inr(BookingDetail?.advancePaidPayment)}</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                        <span className="tb-lead">Advance Amount</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                        <span className="tb-lead">-</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                        <select name="installmentStatus" disabled={advStatus == 'success' || advStatus == 'paid' ? true : false} value={advStatus} onChange={updateStatus}>
                                            <option value="">Select Status</option>
                                            {
                                                bookingStatus2.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.value}>{val.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {BookingDetail?.paymentId ?
                                    <div className="nk-tb-item">
                                        <div className="nk-tb-col">
                                            <span className="tb-lead">Payment Id: {BookingDetail?.paymentId?.payuMoneyId}</span>
                                        </div>
                                        <div className="nk-tb-col">
                                            <span className="tb-lead">Amount: {(Math.round(BookingDetail?.paymentId?.amount)).toFixed(2)}</span>
                                        </div>
                                        <div className="nk-tb-col">
                                            <span className="tb-lead">Paid Date: {format(new Date(BookingDetail?.paymentId?.updatedAt), 'Do MMM YYYY')}</span>
                                        </div>
                                        <div className="nk-tb-col"></div>
                                        <div className="nk-tb-col"></div>
                                    </div>
                                    :
                                    null
                                }


                                {BookingDetail?.totalInstallments?.map((val, index) => {
                                    return (
                                        <InstallmentsList checkNormal={index === 0 ? checkNormal : false} key={index} val={val} bookingDetails={BookingDetail} bookingId={BookingDetail?._id} showToast={setShowToast} showMessage={setShowMessage} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                {BookingDetail?.room?.details?.length > 0 ?
                                    <h3 className="nk-block-title page-title">Room Detail</h3>
                                    :
                                    null}
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        {BookingDetail?.room?.details?.length > 0 ?
                            <RoomType data={BookingDetail?.room?.details} price={BookingDetail?.room?.totalPrice} bgColor="#fff" color="#000" innerColor="#f5f5f5" />
                            :
                            null
                        }

                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Pay Advance
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-bordered">
                        <div className="card-inner">
                            <form className="form-validate">
                                <div className="row g-gs">
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fv-payuId">Payu Id</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fv-payuId"
                                                    name="payuId"
                                                    value={payuId}
                                                    placeholder="Enter Payu Id"
                                                    onChange={(e) => {
                                                        setPayuId(e.target.value)
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fv-amount">Amount</label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fv-amount"
                                                    name="amount"
                                                    value={amount}
                                                    placeholder="Enter Amount"
                                                    // onChange={(e) => {
                                                    //     setAmount(e.target.value)
                                                    // }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row fr_jc pr-3 py-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}
export default BookingDetail;