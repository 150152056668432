import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCountries, putMethod } from "../../../../Services/ApiServices";
import InstallmentsList from "./InstallmentsList";
import { checkStatus, dmy, formatDate } from "../../../../Utils/utils";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
import { Checkbox } from "baseui/checkbox";

const Passenger = (props) => {
    const [index, setIndex] = useState('')
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role

    const bookingStatus = [
        { id: 0, value: "pending" },
        { id: 1, value: "lock" },
        { id: 2, value: "paid" }
    ]
    const [countries, setCountries] = useState([])
    const [firstName, setFirstName] = useState(props?.val?.firstName);
    const [lastName, setLastName] = useState(props?.val?.lastName);
    const [ageLimit, setAgeLimit] = useState(props?.val?.ageLimit)
    const [phone, setPhone] = useState(props?.val?.phone?.mobile);
    const [installmentStatus, setInstallmentStatus] = useState("");
    const [phoneFormat, setPhoneFormat] = useState({
        dialCode: props?.val?.phone?.callingCode ? props?.val?.phone?.callingCode : "91",
        countryCode: props?.val?.phone?.countryCode ? props?.val?.phone?.countryCode : "IN"
    })
    const [email, setEmail] = useState(props?.val?.email);
    const [altPhone, setAltPhone] = useState(props?.val?.alternatePhone?.mobile);
    const [altPhoneFormat, setAltPhoneFormat] = useState({
        dialCode: props?.val?.alternatePhone?.callingCode ? props?.val?.alternatePhone?.callingCode : "91",
        countryCode: props?.val?.alternatePhone?.countryCode ? props?.val?.alternatePhone?.countryCode : "IN"
    })
    const [panName, setPanName] = useState(props?.val?.panDetails?.panName);
    const [panNumber, setPanNumber] = useState(props?.val?.panDetails?.panNo)
    const [passenger, setPassenger] = useState(props.val);
    const [passportName, setPassportName] = useState(props?.val?.passportDetails?.passportName);
    const [passportSurname, setPassportSurname] = useState(props?.val?.passportDetails?.passportSurname);
    const [passportNumber, setPassportNumber] = useState(props?.val?.passportDetails?.passportNumber);
    const [passportDOB, setPassportDOB] = useState(formatDate(props?.val?.passportDetails?.passportDOB));
    const [passportIssueDate, setPassportIssueDate] = useState(formatDate(props?.val?.passportDetails?.passportIssueDate));
    const [passportIssuePlace, setPassportIssuePlace] = useState(props?.val?.passportDetails?.passportIssuePlace);
    const [passportExpiryDate, setPassportExpiryDate] = useState(formatDate(props?.val?.passportDetails?.passportExpiryDate));
    const [passportNationality, setPassportNationality] = useState(props?.val?.passportDetails?.passportNationality);


    const Inr = (arg) => {
        let inr = new Intl.NumberFormat('en-IN').format(arg)
        return inr
    }
    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
        })
    }

    useEffect(() => {
        fetchCountries()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint = "admin/update/booking";
        const params = {
            "bookingId": props?.data?._id,
            "passengerId": props?.val?._id
        }
        var data = {
            "firstName": firstName,
            "lastName": lastName,
            "phone": {
                "mobile": phone,
                "countryCode": phoneFormat?.countryCode?.toUpperCase(),
                "callingCode": phoneFormat?.dialCode
            },
            "alternatePhone": {
                "mobile": altPhone,
                "countryCode": altPhoneFormat?.countryCode?.toUpperCase(),
                "callingCode": altPhoneFormat?.dialCode
            },
            "email": email,
            "passportDetails": {
                "passportName": passportName,
                "passportSurname": passportSurname,
                "passportDOB": formatDate(passportDOB),
                "passportNumber": passportNumber,
                "passportIssueDate": formatDate(passportIssueDate),
                "passportExpiryDate": formatDate(passportExpiryDate),
                "passportIssuePlace": passportIssuePlace,
                "passportNationality": passportNationality
            },
            "panDetails": {
                "panName": panName,
                "panNo": panNumber,
            }

        }
        if (role) {
            data = Object.assign(data, { role: role });
        }
        putMethod(endpoint, data, params).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Booking Details Updated")
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })
    }



    return (
        <div className="accordion-item">
            <a href="#" className="accordion-head collapsed d-flex justify-content-between" key={props?.index} data-toggle="collapse" data-target={`#accordion-item-${props.index}`}>
                <div className="d-flex justify-content-between w-80 aC">
                    <h6 className="title">{props?.val?.firstName + ' ' + props?.val?.lastName}</h6>
                    <h6 className="title">{props?.val?.phone?.callingCode ? '+' + props?.val?.phone?.callingCode : null}{props?.val?.phone?.mobile ? ' ' + props?.val?.phone?.mobile : '-'}</h6>
                    <h6 className="title">{props?.val?.email}</h6>
                    {/* <h6 className="title">{checkStatus(props?.data?.totalInstallments)}</h6> */}
                </div>
                <span className="accordion-icon"></span>
            </a>
            <div className="accordion-body collapse" key={props.index} id={`accordion-item-${props.index}`} data-parent="#accordion">
                <div className="accordion-inner">
                    <div className="px-2 mb-3 bg-white" id={passenger?._id} style={{ width: "100%" }}>
                        <div className="nk-block-head mt-3">
                            <h5 className="title">Personal Detail</h5>
                        </div>
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-fname">First Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-fname"
                                                name="firstName"
                                                value={firstName}
                                                placeholder="Enter First Name"
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-lname">Last Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-lname"
                                                name="lastName"
                                                value={lastName}
                                                placeholder="Enter Last Name"
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-email">Email</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="fv-email"
                                                name="email"
                                                value={email}
                                                placeholder="Enter Email Address"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                        <div className="form-control-wrap">
                                            <span className="countryCode">
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phone',
                                                        disabled: true,
                                                        className: "hgt01"
                                                    }}
                                                    value={"+" + phoneFormat.dialCode}
                                                    onChange={(e, a) => { setPhoneFormat(a); }}
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control pl-110"
                                                id="fv-phone"
                                                name="phone"
                                                value={phone}
                                                placeholder="Enter Phone Number"
                                                onChange={(e) => setPhone(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-altphone">Alternate Phone Number</label>
                                        <div className="form-control-wrap">
                                            <span className="countryCode">
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phone',
                                                        disabled: true,
                                                        className: "hgt01"
                                                    }}
                                                    value={"+" + altPhoneFormat.dialCode}
                                                    onChange={(e, a) => { setAltPhoneFormat(a); }}
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control pl-110"
                                                id="fv-altphone"
                                                name="altPhone"
                                                value={altPhone}
                                                placeholder="Enter Alternate Phone Number"
                                                onChange={(e) => setAltPhone(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Checkbox
                                            checked={ageLimit}
                                            name="ageLimit"
                                            onChange={(e) => {
                                                setAgeLimit(!ageLimit);

                                            }
                                            }
                                            overrides={{
                                                Checkmark: {
                                                    style: ({ $checked }) => ({
                                                        borderLeftColor: "#212c34",
                                                        borderRightColor: "#212c34",
                                                        borderTopColor: "#212c34",
                                                        borderBottomColor: "#212c34",
                                                        backgroundColor: $checked
                                                            ? "#212c34"
                                                            : "#fff",
                                                    }),
                                                },
                                                Label: {
                                                    style: ({
                                                        color: "#000",
                                                        fontSize: "16px",
                                                        fontWeight: "400"
                                                    })
                                                },
                                                ToggleInner: {
                                                    style: ({
                                                        backgroundColor: "#000"
                                                    })
                                                }
                                            }}
                                        >
                                            Is He/She having age between 18 to 40 years?
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="nk-block-head mt-4">
                            <h5 className="title">PAN Detail</h5>
                        </div>
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pan-name">Pan Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pan-name"
                                                name="panName"
                                                value={panName}
                                                placeholder="Enter Pan Name"
                                                onChange={(e) => setPanName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-panNo">Pan Number</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-panNo"
                                                name="panNo"
                                                value={panNumber}
                                                placeholder="Enter Pan Number"
                                                onChange={(e) => setPanNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="nk-block-head mt-4">
                            <h5 className="title">Passport Detail</h5>
                        </div>
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-name">Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pass-name"
                                                value={passportName}
                                                name="passportName"
                                                placeholder="Enter Name"
                                                onChange={(e) => setPassportName(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-surname">Surname</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pass-surname"
                                                value={passportSurname}
                                                name="passportSurname"
                                                placeholder="Enter Surname"
                                                onChange={(e) => setPassportSurname(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-no">Passport Number</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pass-no"
                                                value={passportNumber}
                                                name="passportNumber"
                                                placeholder="Enter Passport number"
                                                onChange={(e) => setPassportNumber(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-dob">Date Of Birth</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="date"

                                                className="form-control"
                                                id="fv-pass-dob"
                                                value={passportDOB}
                                                name="passportDOB"
                                                placeholder="Enter Date of Birth"
                                                onChange={(e) => { setPassportDOB(e.target.value) }}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-issue-date">Issue Date</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fv-pass-issue-date"
                                                value={passportIssueDate}
                                                name="passportIssueDate"
                                                placeholder="Enter Issue Date"
                                                onChange={(e) => setPassportIssueDate(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-expiry">Expiry Date</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fv-pass-expiry"
                                                value={passportExpiryDate}
                                                name="passportExpiryDate"
                                                placeholder="Enter Expiry Date"
                                                onChange={(e) => setPassportExpiryDate(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-place">Issue Place</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pass-place"
                                                value={passportIssuePlace}
                                                name="passportIssuePlace"
                                                placeholder="Enter Issue Place"
                                                onChange={(e) => setPassportIssuePlace(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass-nationality">Nationality</label>
                                        <div className="form-control-wrap">
                                            <select name="passportNationality" value={passportNationality} onChange={(e) => setPassportNationality(e.target.value)}>
                                                <option value="">Select Nationality</option>
                                                {countries.map((val, index) => {
                                                    return (
                                                        <option value={val.name} key={index}>{val.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                        {/* {(props.data.splitPayment || props.index == 0) && (
                            <>
                                <div className="nk-block-head mt-4">
                                    <h5 className="title">Installments</h5>
                                </div>
                                <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col"><span className="sub-text">S No.</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text">Installment Amount</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text">Due Date</span></div>
                                            <div className="nk-tb-col tb-col-md"><span className="sub-text">Status</span></div>
                                        </div>
                                        {props?.data?.splitPayment ?
                                            props?.val?.installments?.map((val, index) => {
                                                return (
                                                    <InstallmentsList val={val} bookingId={props?.data?._id} showToast={props?.showToast} showMessage={props?.showMessage} />

                                                )
                                            })
                                            :

                                            <>
                                                {props?.data?.totalInstallments?.map((val, index) => {
                                                    return (
                                                        <InstallmentsList val={val} bookingId={props?.data?._id} showToast={props?.showToast} showMessage={props?.showMessage} />
                                                    )
                                                })
                                                }
                                            </>

                                        }
                                    </div>
                                </div>
                            </>
                        )} */}



                        <div className="d-flex justify-content-end my-3">
                            <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Update</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Passenger;