import React from "react";

const Footer = (props) => {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap justify-content-center">
                    <div className="nk-footer-copyright"> &copy; 2022 JOL. All Rights Reserved.</div>
                    {/* <div className="nk-footer-links">
                        <ul className="nav nav-sm">
                            <li className="nav-item"><a className="nav-link" href="#">Terms</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Privacy</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Help</a></li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default Footer;