import React, { useEffect, useState } from "react";
import { getCountries, getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import PhoneInput from 'react-phone-input-2';
import ToastMessage from "../../../../Components/Toast/Toast";
import 'react-phone-input-2/lib/style.css';
import { formatDate } from "../../../../Utils/utils";

const UserDetail = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState("");
    const [gender, setGender] = useState("");
    const [panNo, setPanNo] = useState("");
    const [panName, setPanName] = useState("");
    const [passportName, setPassportName] = useState("");
    const [passportSurname, setPassportSurname] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [passportDOB, setPassportDOB] = useState("");
    const [passportIssueDate, setPassportIssueDate] = useState("");
    const [passportIssuePlace, setPassportIssuePlace] = useState("");
    const [passportExpiryDate, setPassportExpiryDate] = useState("");
    const [passportNationality, setPassportNationality] = useState("");
    const [countries, setCountries] = useState([])
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in",
    });
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    const fetchCountries = () => {
        getCountries().then(res => {
            setCountries(res.data);
        })
    }

    useEffect(() => {
        fetchCountries()
    }, [])

    useEffect(() => {
        const id = props?.location?.state?.id;
        const endpoint = "admin/user/detail"
        const params = {
            "userId": id
        }
        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setFirstName(res?.data?.firstName);
                setLastName(res?.data?.lastName);
                setEmail(res?.data?.email);
                setPhone(res?.data?.phone?.mobile);
                setRole(res?.data?.role);
                setGender(res?.data?.gender);
                setPanNo(res?.data?.panDetails?.panNo);
                setPanName(res?.data?.panDetails?.panName);
                setPassportName(res?.data?.passportDetails?.passportName);
                setPassportSurname(res?.data?.passportDetails?.passportSurname);
                setPassportNumber(res?.data?.passportDetails?.passportNumber);
                setPassportDOB(formatDate(res?.data?.passportDetails?.passportDOB));
                setPassportIssueDate(formatDate(res?.data?.passportDetails?.passportIssueDate));
                setPassportIssuePlace(res?.data?.passportDetails?.passportIssuePlace);
                setPassportExpiryDate(formatDate(res?.data?.passportDetails?.passportExpiryDate));
                setPassportNationality(res?.data?.passportDetails?.passportNationality)
                setPhoneFormat({
                    "countryCode": res?.data?.phone?.countryCode ? res?.data?.phone?.countryCode : "IN",
                    "dialCode": res?.data?.phone?.callingCode ? res?.data?.phone?.callingCode : "91"
                })
            }
        })

    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "gender": gender,
            "phone": {
                "mobile": phone,
                "countryCode": phoneFormat?.countryCode?.toUpperCase(),
                "callingCode": phoneFormat?.dialCode
            },
            "passportDetails": {
                "passportName": passportName,
                "passportSurname": passportSurname,
                "passportDOB": formatDate(passportDOB),
                "passportNumber": passportNumber,
                "passportIssueDate": formatDate(passportIssueDate),
                "passportExpiryDate": formatDate(passportExpiryDate),
                "passportIssuePlace": passportIssuePlace,
                "passportNationality": passportNationality
            },
            "panDetails": {
                "panName": panName,
                "panNo": panNo,
            }
        }
        const endpoint = "admin/update/user";
        const params = {
            "userId": props?.location?.state?.id
        }
        putMethod(endpoint, data, params).then((res) => {
            if (res.success) {
                setShowToast(true);
                setShowMessage("Updated Successfully");
            }
            else {
                setShowToast(true);
                setShowMessage(res.error);
            }
        })
    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">User Details</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <div id="accordion" className="accordion">
                            <div className="accordion-item">
                                <a href="#" className="accordion-head" data-toggle="collapse" data-target="#accordion-item-1">
                                    <h6 className="title">Personal Info</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse show" id="accordion-item-1" data-parent="#accordion">
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-first-name">First Name</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-first-name"
                                                                name="firstName"
                                                                value={firstName}
                                                                placeholder="Enter First Name"
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-last-name">Last Name</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-last-name"
                                                                name="lastName"
                                                                value={lastName}
                                                                placeholder="Enter Last Name"
                                                                onChange={(e) => setLastName(e.target.value)}
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                                        <div className="form-control-wrap">
                                                            <span className="countryCode">
                                                                <PhoneInput
                                                                    inputProps={{
                                                                        name: 'phone',
                                                                        disabled: true,
                                                                        className: "hgt01"
                                                                    }}
                                                                    // country={'in'}
                                                                    value={"+" + phoneFormat.dialCode}
                                                                    onChange={(e, a) => { setPhoneFormat(a); }}
                                                                />
                                                            </span>
                                                            <input
                                                                type="number"
                                                                className="form-control pl-110"
                                                                id="fv-phone"
                                                                name="phone"
                                                                value={phone}
                                                                placeholder="Enter Phone Number"
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-email">Email</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="fv-email"
                                                                name="email"
                                                                value={email}
                                                                placeholder="Enter Email Address"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-role">Role</label>
                                                        <div className="form-control-wrap">
                                                            <span className="form-control">{role}</span>
                                                            {/* <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-role"
                                                                name="role"
                                                                value={userDetail?.role}
                                                                placeholder="Enter Role"
                                                                // onChange={(e) => setBookingTripStartDate(e.target.value)}
                                                                required
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-gender">Gender</label>
                                                        <div className="form-control-wrap">
                                                            <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                                <option value="">Choose Gender</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                                <option value="others">Others</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-2">
                                    <h6 className="title">PAN Details</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-2" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pan-name">Pan Name</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pan-name"
                                                                value={panName}
                                                                name="panName"
                                                                placeholder="Enter Pan Name"
                                                                onChange={(e) => setPanName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pan-no">Pan Number</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pan-no"
                                                                value={panNo}
                                                                name="panNo"
                                                                placeholder="Enter Pan Number"
                                                                onChange={(e) => setPanNo(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-3">
                                    <h6 className="title">Logo Image</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-3" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        {userDetail?.avatarUrl ?
                                                            <label htmlFor="customLogo">
                                                                <Image src={process.env.REACT_APP_IMG_URL + userDetail?.avatarUrl} className="image-container1" />
                                                            </label>
                                                            :
                                                            <label htmlFor="customLogo">
                                                                <div className="image-container1">
                                                                    <IoCamera style={{ marginLeft: "40px" }} size={25} color='#6576ff' />
                                                                </div>
                                                            </label>
                                                        }

                                                        <input
                                                            id="customLogo"
                                                            type="file"
                                                            name="avatarUrl"
                                                        // onChange={(e) => setTripLogoImage(e.target.files[0])}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                             */}
                            <div className="accordion-item">
                                <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-4">
                                    <h6 className="title">Passport Details</h6>
                                    <span className="accordion-icon"></span>
                                </a>
                                <div className="accordion-body collapse" id="accordion-item-4" data-parent="#accordion" >
                                    <div className="accordion-inner">
                                        <form className="form-validate">
                                            <div className="row g-gs">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-name">Name</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pass-name"
                                                                value={passportName}
                                                                name="passportName"
                                                                placeholder="Enter Name"
                                                                onChange={(e) => setPassportName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-surname">Surname</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pass-surname"
                                                                value={passportSurname}
                                                                name="passportSurname"
                                                                placeholder="Enter Surname"
                                                                onChange={(e) => setPassportSurname(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-no">Passport Number</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pass-no"
                                                                value={passportNumber}
                                                                name="passportNumber"
                                                                placeholder="Enter Passport number"
                                                                onChange={(e) => setPassportNumber(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-dob">Date Of Birth</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"

                                                                className="form-control"
                                                                id="fv-pass-dob"
                                                                value={passportDOB}
                                                                name="passportDOB"
                                                                placeholder="Enter Date of Birth"
                                                                onChange={(e) => { setPassportDOB(e.target.value) }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-issue-date">Issue Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-pass-issue-date"
                                                                value={passportIssueDate}
                                                                name="passportIssueDate"
                                                                placeholder="Enter Issue Date"
                                                                onChange={(e) => setPassportIssueDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-expiry">Expiry Date</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="fv-pass-expiry"
                                                                value={passportExpiryDate}
                                                                name="passportExpiryDate"
                                                                placeholder="Enter Expiry Date"
                                                                onChange={(e) => setPassportExpiryDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-place">Issue Place</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pass-place"
                                                                value={passportIssuePlace}
                                                                name="passportIssuePlace"
                                                                placeholder="Enter Issue Place"
                                                                onChange={(e) => setPassportIssuePlace(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fv-pass-nationality">Nationality</label>
                                                        <div className="form-control-wrap">
                                                            <select name="passportNationality" value={passportNationality} onChange={(e) => setPassportNationality(e.target.value)}>
                                                                <option value="">Select Nationality</option>
                                                                {countries.map((val, index) => {
                                                                    return (
                                                                        <option value={val.name} key={index}>{val.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {/* <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fv-pass-surname"
                                                                // value={noOfInstallments}
                                                                name="passportSurname"
                                                                placeholder="Enter Surname"
                                                                // onChange={(e) => setNoOfInstallments(e.target.value)}
                                                                required
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default UserDetail;