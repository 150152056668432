import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import AddPayment from "./AddPayment";
import { FiPlus } from "react-icons/fi";
import { getMethod } from "../../../../../Services/ApiServices";

const PaymentDetail1 = (props) => {
    const [payuId, setPayuId] = useState("");
    const [amount, setAmount] = useState(0);
    const { data, advAmount, booking } = props

    useEffect(() => {
        let amount = advAmount * booking?.numberOfPassengers
        setAmount(isNaN(amount) ? 0 : amount);
        var amountUpdate = props?.bookingDetail;
        amountUpdate = Object.assign(amountUpdate, { amount: isNaN(amount) ? 0 : amount })
        data(amountUpdate)
    }, [props])

    return (
        <div>
            <form className="form-validate">
                <div className="row g-gs">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-payId">Payment Id</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-payId"
                                    name="payuId"
                                    value={payuId}
                                    placeholder="Enter PayuId"
                                    onChange={(e, a) => {
                                        setPayuId(e.target.value)
                                        data((prev) => {
                                            return {
                                                ...prev,
                                                [e.target.name]: e.target.value
                                            }
                                        })
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fv-amount">Amount</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fv-amount"
                                    name="amount"
                                    value={amount}
                                    placeholder="Enter Amount"
                                    // onChange={(e, a) => {
                                    //     setAmount(e.target.value)
                                    //     data((prev) => {
                                    //         return {
                                    //             ...prev,
                                    //             [e.target.name]: e.target.value
                                    //         }
                                    //     })
                                    // }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default PaymentDetail1;