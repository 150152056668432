import React, { useReducer } from 'react'

export const CoreContext = React.createContext();

const initialState = {
    showSpinner: false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_SPINNER':
            return { ...state, showSpinner: true }
        case 'HIDE_SPINNER':
            return { ...state, showSpinner: false }
        default:
            return state
    }
}

function CoreContextProvider(props) {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <CoreContext.Provider value={{ coreState: state, coreDispatch: dispatch }}>
            {props.children}
        </CoreContext.Provider>
    )
}

export default CoreContextProvider
