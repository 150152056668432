import React, { useState, useEffect } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import AddPassenger from "./AddPassenger";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { getMethod } from "../../../../../Services/ApiServices";
import RoomType from "./RoomType";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { LS_USER_DATA } from "../../../../../Contexts/UserContext";

const BookingDetail = (props) => {
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role
    const [value, setValue] = useState("0");
    const [val, setVal] = useState("");
    const [rooms, setRooms] = useState([])
    const [isLeadPassenger, setIsLeadPassenger] = useState("true");
    const [passenger, setPassenger] = useState([
        {
            firstName: "",
            lastName: "",
            phone: {},
            email: "",
            alternatePhoneNo: {},
            panDetails: {},
            passportDetails: {},
            ageLimit: false,
            isLeadPassenger: true
        }
    ]);
    const [count, setCount] = useState(1);


    // useEffect(() => {
    //     if (role == 'Admin') {
    //         if (props.userDetail) {
    //             if (isLeadPassenger == "true") {
    //                 passenger?.forEach((val, index) => {
    //                     if (val?.isLeadPassenger) {
    //                         let arr = passenger;
    //                         let updateNew = arr[index];
    //                         updateNew = Object.assign(updateNew, {
    //                             firstName: props?.userDetail?.firstName,
    //                             lastName: props?.userDetail?.lastName,
    //                             phone: props?.userDetail?.phone ? props?.userDetail?.phone : {},
    //                             email: props?.userDetail?.email,
    //                             alternatePhoneNo: props?.userDetail?.alternatePhoneNo ? props?.userDetail?.alternatePhoneNo?.mobile : {},
    //                             panDetails: {
    //                                 panName: props?.userDetail?.panDetails?.panName,
    //                                 panNo: props?.userDetail?.panDetails?.panNo
    //                             },
    //                             passportDetails: {
    //                                 passportDOB: props?.userDetail?.passportDetails?.passportDOB,
    //                                 passportExpiryDate: props?.userDetail?.passportDetails?.passportExpiryDate,
    //                                 passportIssueDate: props?.userDetail?.passportDetails?.passportIssueDate,
    //                                 passportIssuePlace: props?.userDetail?.passportDetails?.passportIssuePlace,
    //                                 passportName: props?.userDetail?.passportDetails?.passportName,
    //                                 passportNationality: props?.userDetail?.passportDetails?.passportNationality,
    //                                 passportNumber: props?.userDetail?.passportDetails?.passportNumber,
    //                                 passportSurname: props?.userDetail?.passportDetails?.passportSurname
    //                             },
    //                             ageLimit: false,
    //                             isLeadPassenger: true
    //                         })
    //                         arr[index] = updateNew
    //                         setPassenger(arr)
    //                     }
    //                 })
    //             }
    //             else {
    //                 passenger?.forEach((val, index) => {
    //                     if (val?.isLeadPassenger) {
    //                         let arr = passenger;
    //                         let updateNew = arr[index];
    //                         updateNew = Object.assign(updateNew, {
    //                             firstName: "",
    //                             lastName: "",
    //                             phone: {},
    //                             email: "",
    //                             alternatePhoneNo: {},
    //                             panDetails: {},
    //                             passportDetails: {},
    //                             ageLimit: false,
    //                             isLeadPassenger: true
    //                         })
    //                         arr[index] = updateNew
    //                         setPassenger(arr)
    //                     }
    //                 })
    //             }
    //         }
    //     }
    // }, [props?.userDetail, isLeadPassenger])

    useEffect(() => {
        const endpoint = "room/filter";
        const params = {
            "noOfPerson": count
        }

        getMethod(endpoint, params).then((res) => {
            if (res.success) {
                setRooms(res.data)
            }
        })
    }, [count])

    useEffect(() => {
        // console.log(props?.userDetail)
        if (props?.userDetail) {
            const data = {
                firstName: props?.userDetail?.firstName,
                lastName: props?.userDetail?.lastName,
                phone: props?.userDetail?.phone ? props?.userDetail?.phone : {},
                email: props?.userDetail?.email,
                alternatePhoneNo: props?.userDetail?.alternatePhoneNo ? props?.userDetail?.alternatePhoneNo?.mobile : {},
                panDetails: {
                    panName: props?.userDetail?.panDetails?.panName,
                    panNo: props?.userDetail?.panDetails?.panNo
                },
                passportDetails: {
                    passportDOB: props?.userDetail?.passportDetails?.passportDOB,
                    passportExpiryDate: props?.userDetail?.passportDetails?.passportExpiryDate,
                    passportIssueDate: props?.userDetail?.passportDetails?.passportIssueDate,
                    passportIssuePlace: props?.userDetail?.passportDetails?.passportIssuePlace,
                    passportName: props?.userDetail?.passportDetails?.passportName,
                    passportNationality: props?.userDetail?.passportDetails?.passportNationality,
                    passportNumber: props?.userDetail?.passportDetails?.passportNumber,
                    passportSurname: props?.userDetail?.passportDetails?.passportSurname
                },
                ageLimit: false,
                isLeadPassenger: true
            }
            const exchnageData = passenger?.map((item, index) => index === 0 ? data : item);
            //  console.log('pasenger', exchnageData)
            setPassenger(exchnageData)
        } else {
            const data = {
                firstName: '',
                lastName: '',
                phone: {},
                email: '',
                alternatePhoneNo: {},
                panDetails: {},
                passportDetails: {},
                ageLimit: false,
                isLeadPassenger: true
            }
            const exchnageData = passenger?.map((item, index) => index === 0 ? data : item);
            setPassenger(exchnageData)
        }
    }, [props?.userDetail])


    const decrementCount = () => {
        if (count === 1) {
            // setCount(count);
            // passenger.pop()
            // console.log(passenger)
            // setPassenger(passenger)
        }
        else {
            setCount(count - 1);
            passenger.pop()
            props.data((prev) => {
                return {
                    ...prev,
                    "coPassengersDetails": passenger,
                    "numberOfPassengers": passenger?.length,
                }
            })
        }

    }
    const incrementCount = () => {
        setCount(count + 1);
        setPassenger((prev) => [...prev, {
            firstName: "",
            lastName: "",
            phone: {},
            email: "",
            alternatePhoneNo: {},
            panDetails: {},
            passportDetails: {},
            ageLimit: false,
            isLeadPassenger: false
        }])
    }
    useEffect(() => {
        props.data((prev) => {
            return {
                ...prev,
                "coPassengersDetails": passenger,
                "numberOfPassengers": passenger?.length,
            }
        })
    }, [passenger])

    return (
        <div className="nk-block">
            <div className="mb-3 bg-white" style={{ width: "100%" }}>
                <p className="mb-0 p-text">No. of Passengers</p>
                <div className="fr_jS_aI py-3">
                    <button className="count-button" onClick={() => decrementCount()}>
                        <AiOutlineMinus size={18} color="#fff" />
                    </button>
                    <h4 className="mb-0 p-text px-3">{count}</h4>
                    <button className="count-button" onClick={() => incrementCount()}>
                        <AiOutlinePlus size={18} color="#fff" />
                    </button>
                </div>
                {role == 'Admin' ?
                    <div className="py-4">
                        <h5 className="title">Is user and lead passenger be same?</h5>
                        <div>
                            <RadioGroup
                                disabled={false}
                                align="horizontal"
                                name="horizontal"
                                onChange={(e) => {
                                    //console.log(e.target.value)
                                    setIsLeadPassenger(e.target.value)
                                    // let { value } = target;
                                    // setIsLeadPassenger(value);

                                }}
                                value={isLeadPassenger}
                                overrides={{
                                    Label: {
                                        style: ({
                                            marginRight: "15px"
                                        })
                                    },
                                    RadioMarkOuter: {
                                        style: ({
                                            backgroundColor: "#212c34"
                                        })
                                    }
                                }}
                            >
                                <Radio value="true">Yes</Radio>
                                <Radio value="false">No</Radio>
                            </RadioGroup>
                        </div>
                    </div>
                    :
                    null
                }
                {/* <RadioGroup
                    value={value}
                    disabled='true'
                    onChange={(e) => {
                        setValue(e.target.value);
                        let arr = passenger;
                        arr.forEach((e, index) => {
                            if (e.isLeadPassenger === true) {
                                arr[index] = {
                                    ...arr[index],
                                    isLeadPassenger: false
                                }
                            }
                        })
                        arr[e.target.value] = {
                            ...arr[e.target.value],
                            isLeadPassenger: true
                        }
                    }}
                    name="number"
                    align={ALIGN.vertical}
                    overrides={{
                        Label: {
                            style: ({
                                color: "#fff",
                                width: "100%"
                            })
                        },
                        Root: {
                            style: ({
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                paddingTop: "10px",
                                paddingBottom: "10px"
                            })
                        },
                        RadioMarkOuter: {
                            style: ({
                                backgroundColor: "#212c34"
                            })
                        }
                    }}
                > */}
                {passenger?.map((val, index) => {
                    return (
                        // <Radio value={index.toString()} key={index}>
                        <AddPassenger matchUserData={props?.userDetail} isLeadPassenger={value == index.toString()} checkIsLeadPassenger={isLeadPassenger} key={index} index={index} val={val} passenger={passenger} setPassenger={(e) => {
                            setPassenger(e)
                        }} data={props?.data} />
                        // </Radio>
                    )
                })}
                {/* </RadioGroup> */}

                <div>
                    <h3 className="nk-block-title page-title">Room Type</h3>
                    <RadioGroup
                        value={val}
                        onChange={(e) => {
                            e.preventDefault()
                            setVal(e.target.value);
                            rooms.forEach((item, index) => {
                                if (index.toString() === e.target.value) {

                                    props.data((prev) => {
                                        return {
                                            ...prev,
                                            "room": item
                                        }
                                    })
                                }
                            })
                        }}
                        name="number"
                        align={ALIGN.vertical}
                        overrides={{
                            Label: {
                                style: ({
                                    color: "#fff",
                                    width: "100%",

                                })
                            },
                            Root: {
                                style: ({
                                    width: "100%",
                                    display: "flex",
                                    alignItems: 'center',
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid"
                                })
                            },
                            RadioMarkOuter: {
                                style: ({
                                    backgroundColor: "#212c34"
                                })
                            }
                        }}
                    >
                        {rooms?.map((val, index) => {
                            return (
                                <Radio value={index.toString()} key={index}>
                                    <RoomType data={val.details} price={val.price} bgColor="#101924" color="#fff" innerColor="#fff" />
                                </Radio>
                            )
                        })}
                    </RadioGroup>

                </div>
            </div>
        </div>


    )
}
export default BookingDetail;