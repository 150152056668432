import moment from 'moment';

export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * @param {Date | string | number} date 
 * @param {string} format 
 * @returns {string}
 */
export const getFormatedDate = (date, format = "YYYY-MM-DD") => {
    return moment(date).format(format)
}

/**
 * @param {string} format 
 * @returns {string}
 */
export const currentDate = (format = "YYYY-MM-DD") => {
    return moment(new Date()).format(format)
}

/**
 * @param {string} time 
 * @returns {string}
 */
export const time24to12 = time => {
    // Check correct time format and split into components
    let timeArray = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (timeArray.length > 1) { // If time format correct
        timeArray = timeArray.slice(1);  // Remove full string match value
        timeArray[5] = +timeArray[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        timeArray[0] = +timeArray[0] % 12 || 12; // Adjust hours
    }
    return timeArray.join(''); // return adjusted time or original string
}

/**
 * @param {string} time 
 * @returns {boolean}
 */
export const isValidTime = time => !time ? false : Boolean(time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/));

/**
 * @param {string} value 
 * @returns {string}
 */
export const mintToTimeFormat = value => {
    if (!value) return value;
    let h = Math.floor(parseInt(value, 10) / 60).toString(),
        m = Math.floor(parseInt(value, 10) % 60).toString(),
        hours = h.length == 1 ? '0' + h : h,
        mints = m.length == 1 ? '0' + m : m;
    return hours + ':' + mints;
}

/**
 * @param {string} value 
 * @returns {string}
 */
export const timeToMintFormat = value => {
    if (!value) return '0';
    let [h, m] = value.split(':');
    let total = 0;
    total += parseInt(h, 10) * 60;
    total += parseInt(m, 10);
    return total.toString();
}