import React, { useEffect, useState, useRef } from "react";

import { useLocation, useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { format } from 'fecha';
import {
  getMethod,
  postMethod,
  putMethod,
  BASE_IMG_URL,
} from "../../../../Services/ApiServices";
import { Editor } from "@tinymce/tinymce-react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCoupon = (props) => {
  const query = useQuery();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editCase, setEditCase] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [product, setProduct] = useState([]);
  const [isActive , setIsActive] = useState(false);
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [discountType, setDiscountType] = useState("fixed");
  const [discount, setDiscount] = useState(0);
  const [limitPerUser, setLimitPerUser] = useState(0);
  const [user, setUser] = useState([]);









  const dateFormatConverter = (arg) => {
    let date = new Date(arg);
    let datel = date.getUTCDate() < 10 ? ("0" + date.getUTCDate()).slice(-2) : date.getUTCDate();
    let month = date.getUTCMonth() < 10 ? ("0" + (date.getUTCMonth() + 1)).slice(-2) : (date.getUTCMonth() + 1);
    let returnDate = date.getUTCFullYear() + '-' + month + '-' + datel
    return returnDate;
}
  const toggleShow = () => {
    setShowToast(!showToast);
  };
  const [showMessage, setShowMessage] = useState("");
  useEffect(() => {

    getMethod('coupons/products').then(res=>{
      if(res.success){
        if(res.data.length)
        {
          setProducts((res.data).map((n)=>({label:n?.tripName, value:n?._id})))
        }
      }
    });
 getMethod('coupons/users').then(res=>{
      if(res.success){
        if(res.data.length)
        {
          setUsers((res.data).map((n)=>({value:n?._id, label: (n?.firstName)+(n?.lastName?' '+n?.lastName:'')})))
          //setUsers((res.data).map((n)=>({label:n, value:n?._id})))
        }
      }
    });

    if (window.location.pathname === "/edit-coupon" && query.get("id") != "") {
      setEditCase(true);
      const endpoint = "coupons/" + query.get('id') + "/single";
      const params = {
      };
      getMethod(endpoint, params).then((res) => {
        if (res.success && res.data) {
          let data = res.data;
          setCouponCode(data?.couponCode?data?.couponCode:'');
          setTitle(data?.name?data?.name:'');
          setDescription(data?.description?data?.description:'');
          setIsActive(data?.active?data?.active:false);
          setProduct(data?.product?.length?data?.product:[])
          setUser(data?.user?.length?data?.user:[]);
          setValidFrom(data?.validFrom?dateFormatConverter(data?.validFrom):'');
          setValidTill(data?.validTill?dateFormatConverter(data?.validTill):'')
        }else{
          history.goBack();
        }
      });
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.get("id")) {
      update();
    } else {
      add();
    }
  };
  const update = () => {
    const endpoint = "coupons/" + query.get("id") + "/edit";
    const params = {

    };
    let data;
    if (editCase) {
      data = {
        couponCode:couponCode,
        name:title,
        description:description,
        product:(product).filter((n)=>n),
        active:isActive,
        user:(user).filter((n)=>n),
        validFrom:dateFormatConverter(validFrom),
        validTill:dateFormatConverter(validTill),
        discountType:discountType,
        discount:discount,
        limitPerUser:limitPerUser
      };
    }
    setIsSubmitting(true);
    putMethod(endpoint, data, params).then((res) => {
      setIsSubmitting(false);
      if (res.success) {
        setShowToast(true);
        setShowMessage("Updated Successfully");
        history.goBack();
      } else {
        setShowToast(true);
        setShowMessage(res.error);
      }
    });
  };
  const add = () => {
    const endpoint = "coupons/create";
    let data = {
      couponCode:couponCode,
      name:title,
      description:description,
      product:(product).filter((n)=>n),
      active:isActive,
      user:(user).filter((n)=>n),
      validFrom:dateFormatConverter(validFrom),
      validTill:dateFormatConverter(validTill),
      discountType:discountType,
      discount:discount,
      limitPerUser:limitPerUser
    };
    setIsSubmitting(true);
    postMethod(endpoint, data)
      .then((res) => {
        setIsSubmitting(false);
        if (res.success) {
          setShowToast(true);
          setShowMessage("Added Successfully");
          history.goBack();
        } else {
          setShowToast(true);
          setShowMessage(res.message);
        }
      })
      .catch((err) => {
        setShowMessage(err.message);
      });
  };



  return (
    <div className="nk-content">
      <ToastMessage
        showToast={showToast}
        toggleShow={toggleShow}
        showMessage={showMessage}
      />
      <div className="container-fluid pt-3 px-5">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {editCase ? "Update Coupon" : "Add Coupon"}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <form className="form-validate" onSubmit={handleSubmit}>
          <div className="card card-bordered">
            <div className="card-inner">
              <div className="row g-gs">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                    Coupon Code
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fv-date"
                        name="date"
                        value={couponCode}
                        placeholder="Enter Coupon Code"
                        onChange={(e) => setCouponCode((e.target.value).toUpperCase())}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Title (Heading)
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fv-name"
                        name="name"
                        value={title}
                        placeholder="Enter Name"
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-agency-name">
                      Description
                    </label>
                    <div className="form-control-wrap">
                      {/* {description} */}

                      <textarea
                        type="text"
                        className="form-control"
                        id="fv-agency-name"
                        name="agencyName"
                        placeholder="Enter Description"
                        onChange={(e) => setDescription(e.target.value)}
                        autoComplete="off"
                        value={description}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
               
          
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      IsActive
                    </label>
                    <div className="form-control-wrap">
                      <select
                        className="form-control"
                        id="fv-email"
                        name="isActive"
                        value={isActive}
                        placeholder="Enter related"
                        onChange={(e) => setIsActive(e.target.value)}
                        autoComplete="off"
                  
                      >
                        <option
                          value="true"
                          
                        >
                          Yes
                        </option>
                        <option
                          value="false"
                         
                        >
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
            
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      Product
                    </label>
                    <div className="form-control-wrap">
                      <MultiSelect
                        style={{ width: '100%' }}
                        options={products?.length ? products : []}
                        defaultValue={product}
                        onChange={(e) => setProduct(e.split(","))}
                        labelledBy="Select Tags"
                        
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      Users
                    </label>
                    <div className="form-control-wrap">
                      <MultiSelect
                        style={{ width: '100%' }}
                        options={users?.length ? users : []}
                        defaultValue={user}
                        onChange={(e) => setUser(e.split(","))}
                        labelledBy="Select Tags"
                       

                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                       valid from
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="fv-date"
                        name="date1"
                        value={validFrom}
                        placeholder="dd/mm/yyyy"
                        onChange={(e) => setValidFrom(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Valid Till
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="fv-date"
                        name="date2"
                        value={validTill}
                        placeholder="dd/mm/yyyy"
                        onChange={(e) => setValidTill(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-email">
                      Discount Type
                    </label>
                    <div className="form-control-wrap">
                      <select
                        className="form-control"
                        id="fv-email"
                        name="discountType"
                        value={discountType}
                        onChange={(e) => setDiscountType(e.target.value)}
                        autoComplete="off"
                        required
                      >
                        <option
                          value="fixed"
                        >
                          Fixed
                        </option>
                        <option
                          value="percent"
                        >
                          Percent
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Discount
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="fv-date"
                        name="discount"
                        value={discount}
                        placeholder="Enter discount"
                        onChange={(e) => setDiscount(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fv-name">
                      Limit Per User
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="fv-date"
                        name="limit"
                        value={limitPerUser}
                        placeholder="Enter limit per user"
                        onChange={(e) => setLimitPerUser(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="row fr_jc pr-3 py-3">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? "Submitting.."
                      : props?.location?.state?.id
                        ? "Update"
                        : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;
