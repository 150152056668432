import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../Components/Toast/Toast";
import { getMethod, postMethod } from "../../../../Services/ApiServices";
import AddressDetail from "./AddressDetail/AddressDetail";
import BookingDetail from "./BookingDetail/BookingDetail";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import { inr } from "../../../../Utils/utils";
import PaymentDetail1 from "./PaymentDetail/PaymentDetail1";
import { format } from 'fecha';
import { LS_USER_DATA } from "../../../../Contexts/UserContext";
const AddBooking = (props) => {
    const role = JSON.parse(localStorage.getItem(LS_USER_DATA))?.role;
    const history = useHistory();
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState();
    const [userDetail, setUserDetail] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState('')
    const [tripDetail, setTripDetail] = useState();
    const [singleTripDetail, setSingleTripDetail] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [bookingDetail, setBookingDetail] = useState({
        splitPayment: false,
        "coPassengersDetails": [],
        "numberOfPassengers": "",
        "payuId": "",
        "amount": "",
        // "payMethod": "",
        // "paymentMethodId": "",
        "addressId": "",
        "termsAndCondition": true,
        "room": {}
    })
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    function fetchUsers() {
        const endpoint = "admin/users"
        getMethod(endpoint).then((res) => {
            if (res.success) {
                setUserList(res.data)
            }
        })
    }

    useEffect(() => {
        fetchUsers()
        const endpoint = "product/all/admin"
        getMethod(endpoint).then((res) => {
            if (res.success) {
                setTripDetail(res.data)
            }
        })
    }, [])

    const selectTrip = (e) => {
        setSelectedTrip(e.target.value)
        tripDetail.forEach(element => {
            if (element._id === e.target.value) {
                setSingleTripDetail(element)
            }
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedTrip) {
            setShowToast(true);
            setShowMessage("Please Select Trip");
            return
        }
        const endpoint = "admin/trip/booking";
        bookingDetail.userId = user;
        bookingDetail.amount = bookingDetail?.numberOfPassengers * singleTripDetail?.advancePrepaidAmount
        const params = {
            "productId": selectedTrip
        }
        console.log("booking", bookingDetail)
        setIsSubmitting(true);
        postMethod(endpoint, bookingDetail, params).then((res) => {
            setIsSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage(res.message);
                history.push('/bookings');
            }
            else {
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }

    const selectedUser = (e) => {
        setUser(e.target.value)
        var id = e.target.value;
        if (id) {
            userList.forEach((val) => {
                if (val._id == id) {
                    setUserDetail(val)
                }
            })
        } else {
            setUserDetail(id)
        }
    }

    return (
        <div className="nk-content p-0">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Trip Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="border px-2 mb-3 bg-white" style={{ width: "100%" }}>
                            <div className="profile-ud-list">
                                <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                        <span className="profile-ud-label">Trip Name</span>
                                        <select value={selectedTrip} onChange={selectTrip}>
                                            <option value="">Select Trip Name</option>
                                            {tripDetail?.map((val, index) => {
                                                return (
                                                    <option key={index} value={val._id}>{val.tripName}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {selectedTrip != "" ?
                                <div className="profile-ud-list">
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Trip Amount</span>
                                            <span className="profile-ud-value">INR {singleTripDetail?.sellingPrice ? inr(singleTripDetail?.sellingPrice) : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Advance Payment</span>
                                            <span className="profile-ud-value">INR {singleTripDetail?.advancePrepaidAmount ? inr(singleTripDetail?.advancePrepaidAmount) + '/Passenger ' : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">No. of Installments</span>
                                            <span className="profile-ud-value">{singleTripDetail?.noOfInstallments + 1 || "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Booking Start Date</span>
                                            <span className="profile-ud-value">{singleTripDetail?.bookingTripStartDate ? format(new Date(singleTripDetail?.bookingTripStartDate), 'Do MMM YYYY') : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Booking End Date</span>
                                            <span className="profile-ud-value">{singleTripDetail?.bookingTripEndDate ? format(new Date(singleTripDetail?.bookingTripEndDate), 'Do MMM YYYY') : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Going Date</span>
                                            <span className="profile-ud-value">{singleTripDetail?.tripGoingDate ? format(new Date(singleTripDetail?.tripGoingDate), 'Do MMM YYYY') : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Leaving Date</span>
                                            <span className="profile-ud-value">{singleTripDetail?.tripLeavingDate ? format(new Date(singleTripDetail?.tripLeavingDate), 'Do MMM YYYY') : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Arrival Airport</span>
                                            <span className="profile-ud-value">{singleTripDetail?.arrivalAirport ? singleTripDetail?.arrivalAirport : "-"}</span>
                                        </div>
                                    </div>
                                    <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                            <span className="profile-ud-label">Departure Airport</span>
                                            <span className="profile-ud-value">{singleTripDetail?.departureAirport ? singleTripDetail?.departureAirport : "-"}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Passenger Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card card-bordered">
                        <div className="card-inner">
                            <div id="accordion" className="accordion">
                                <div className="accordion-item">
                                    <a href="#" className="accordion-head" data-toggle="collapse" data-target="#accordion-item-4">
                                        <h6 className="title">Add User</h6>
                                        <span className="accordion-icon"></span>
                                    </a>
                                    <div className="accordion-body collapse show" id="accordion-item-4" data-parent="#accordion">
                                        <div className="accordion-inner">
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">User Name</span>
                                                        <select value={user} onChange={(e) => selectedUser(e)}>
                                                            <option value="">Select User</option>
                                                            {userList?.map((val, index) => {
                                                                return (
                                                                    <option key={index} value={val._id}>{val.firstName + ' ' + val.lastName}{val?.email ? ', ' + val?.email : null}{val?.phone?.mobile ? ', ' + val?.phone?.mobile : null}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <a href="#" className="accordion-head" data-toggle="collapse" data-target="#accordion-item-1">
                                        <h6 className="title">Passenger Details</h6>
                                        <span className="accordion-icon"></span>
                                    </a>
                                    <div className="accordion-body collapse" id="accordion-item-1" data-parent="#accordion">
                                        <div className="accordion-inner">
                                            {role == 'Admin' ?
                                                <BookingDetail n={bookingDetail} data={setBookingDetail} userDetail={userDetail} />
                                                :
                                                <BookingDetail n={bookingDetail} data={setBookingDetail} userDetail={userDetail} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-2">
                                        <h6 className="title">Address Details</h6>
                                        <span className="accordion-icon"></span>
                                    </a>
                                    <div className="accordion-body collapse" id="accordion-item-2" data-parent="#accordion" >
                                        <div className="accordion-inner">
                                            <AddressDetail role={role} data={setBookingDetail} id={userDetail?._id} showToast={setShowToast} toastMesage={setShowMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <a href="#" className="accordion-head collapsed" data-toggle="collapse" data-target="#accordion-item-3">
                                        <h6 className="title">Payment Details</h6>
                                        <span className="accordion-icon"></span>
                                    </a>
                                    <div className="accordion-body collapse" id="accordion-item-3" data-parent="#accordion" >
                                        <div className="accordion-inner">
                                            <PaymentDetail1 bookingDetail={bookingDetail} data={setBookingDetail} advAmount={singleTripDetail?.advancePrepaidAmount} booking={bookingDetail} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row fr_jc pr-3 py-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting..' : 'Add Booking'}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}
export default AddBooking;