import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { postMethod } from "../../../../../Services/ApiServices";

const AddProductGallery = (props) => {
    const [title, setTitle] = useState("")
    const [images, setImages] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint = "home/create/productgallery";
        const formData = new FormData();
        formData.append('title', title)
        for (let key in images) {
            formData.append('images', images[key])
        }
        postMethod(endpoint, formData).then((res) => {
            if (res.success) {
                props?.showToast(true);
                props?.showMessage("Added Successfully");
                props?.onHide();
            }
            else {
                props?.showToast(true);
                props?.showMessage(res.error)
            }
        })

    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Product Gallery
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6 mt-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-title">Title</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-title"
                                                name="heading"
                                                value={title}
                                                placeholder="Enter Title"
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Gallery</label>
                                        <div className="form-control-wrap">
                                            {
                                                images?
                                                    <div className="main-block">
                                                        
                                                        {
                                                            images.map((val, i) => {
                                                                return (
                                                                    <div className="inner-block">
                                                                        <img className="image-container1" key={i} src={URL.createObjectURL(val)} alt="gallery" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <label htmlFor="customGallery1">
                                                            <div className="image-container1">
                                                                <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    :
                                                    <label htmlFor="customGallery1">
                                                        <div className="image-container1">
                                                            <p style={{ margin: "25px" }}>Choose <br /> Images</p>
                                                        </div>
                                                    </label>
                                            }
                                        </div>
                                        <input
                                            id="customGallery1"
                                            type="file"
                                            name="gallery"
                                            multiple
                                            onChange={(e) => setImages(Object.values(e.target.files))}
                                        />
                                    </div>
                                </div></div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default AddProductGallery;