import React from "react";
import Routes from "./Routes/Routes";
import CoreContextProvider from "./Contexts/CoreContext";
import UserContextProvider from "./Contexts/UserContext";

function App() {
  return (
    <div>
      <CoreContextProvider>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </CoreContextProvider>
    </div>
  );
}

export default App;
