import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import ToastMessage from "../../../../Components/Toast/Toast";
import { getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";

const AddAgent = (props) => {
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState("");
    const [agencyName, setAgencyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine3, setAddressLine3] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [agencyGSTNo, setAgencyGSTNo] = useState("");
    const [agencyPanNo, setAgencyPanNo] = useState("");
    const [commissionType, setCommissionType] = useState("");
    const [commission, setCommission] = useState("")
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in",
    });
    const [password, setPassword] = useState("");
    const discType = [{
        "id": "1",
        "value": "fixed"
    },
    {
        "id": "2",
        "value": "percent"
    }
    ]
    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");
    useEffect(() => {
        if (props?.location?.state?.id) {
            const endpoint = "agent/detail";
            const params = {
                "agentId": props?.location?.state?.id
            }
            getMethod(endpoint, params).then((res) => {
                if (res.success) {
                    setName(res?.data?.name);
                    setAgencyName(res?.data?.agencyName);
                    setEmail(res?.data?.email);
                    setPhone(res?.data?.phone?.mobile);
                    setPhoneFormat({
                        "dialCode": res?.data?.phone?.callingCode,
                        "countryCode": res?.data?.phone?.countryCode,
                    });
                    setCity(res?.data?.city);
                    setState(res?.data?.state);
                    setCountry(res?.data?.country);
                    setPostalCode(res?.data?.postalCode);
                    setAddressLine1(res?.data?.addressLine1);
                    setAddressLine2(res?.data?.addressLine2);
                    setAddressLine3(res?.data?.addressLine3);
                    setAgencyGSTNo(res?.data?.agencyGSTNo);
                    setAgencyPanNo(res?.data?.agencyPanNo);
                    setCommissionType(res?.data?.commissionType);
                    setCommission(res?.data?.commission)
                }
            })
        }
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        if (props?.location?.state?.id) {
            update()
        }
        else {
            add()
        }

    }
    const update = () => {
        const endpoint = "agent/update";
        const params = {
            "agentId": props?.location?.state?.id
        }
        let data;
        if (password) {
            data = {
                "name": name,
                "agencyName": agencyName,
                "email": email,
                "password": password,
                "phone": {
                    "mobile": phone,
                    "countryCode": phoneFormat.countryCode.toUpperCase(),
                    "callingCode": phoneFormat.dialCode
                },
                "addressLine1": addressLine1,
                "addressLine2": addressLine2,
                "addressLine3": addressLine3,
                "state": state,
                "city": city,
                "country": country,
                "postalCode": postalCode,
                "agencyGSTNo": agencyGSTNo,
                "agencyPanNo": agencyPanNo,
                "commissionType": commissionType,
                "commission": commission
            }
        }
        else {
            data = {
                "name": name,
                "agencyName": agencyName,
                "email": email,
                "phone": {
                    "mobile": phone,
                    "countryCode": phoneFormat.countryCode.toUpperCase(),
                    "callingCode": phoneFormat.dialCode
                },
                "addressLine1": addressLine1,
                "addressLine2": addressLine2,
                "addressLine3": addressLine3,
                "state": state,
                "city": city,
                "country": country,
                "postalCode": postalCode,
                "agencyGSTNo": agencyGSTNo,
                "agencyPanNo": agencyPanNo,
                "commissionType": commissionType,
                "commission": commission
            }
        }
        setIsSubmitting(true);
        putMethod(endpoint, data, params).then((res) => {
            setIsSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage("Updated Successfully");
            }
            else {
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }
    const add = () => {
        const endpoint = "agent/add";
        const data = {
            "name": name,
            "agencyName": agencyName,
            "email": email,
            "password": password,
            "phone": {
                "mobile": phone,
                "countryCode": phoneFormat.countryCode.toUpperCase(),
                "callingCode": phoneFormat.dialCode
            },
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "state": state,
            "city": city,
            "country": country,
            "postalCode": postalCode,
            "agencyGSTNo": agencyGSTNo,
            "agencyPanNo": agencyPanNo,
            "commissionType": commissionType,
            "commission": commission

        }
        if (!data) {
            setShowToast(true);
            setShowMessage("All fields are required");
        }
        setIsSubmitting(true);
        postMethod(endpoint, data).then((res) => {
            setIsSubmitting(false);
            if (res.success) {
                setShowToast(true);
                setShowMessage("Added Successfully");
                history.goBack();
            }
            else {
                setShowToast(true);
                setShowMessage(res.error)
            }
        })
    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">{props?.location?.state?.id ? "Update Agent" : "Add Agent"}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-name">Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-name"
                                                name="name"
                                                value={name}
                                                placeholder="Enter Name"
                                                onChange={(e) => setName(e.target.value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-agency-name">Agency Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-agency-name"
                                                name="agencyName"
                                                value={agencyName}
                                                placeholder="Enter Agency Name"
                                                onChange={(e) => setAgencyName(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-phone">Phone Number</label>
                                        <div className="form-control-wrap">
                                            <span className="countryCode">
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phone',
                                                        disabled: true,
                                                        className: "hgt01"
                                                    }}
                                                    // country={'in'}
                                                    value={"+" + phoneFormat.dialCode}
                                                    onChange={(e, a) => { setPhoneFormat(a); }}
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                className="form-control pl-110"
                                                id="fv-phone"
                                                name="phone"
                                                value={phone}
                                                placeholder="Enter Phone Number"
                                                onChange={(e) => setPhone(e.target.value)}
                                                maxLength="10"
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-email">Email</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="fv-email"
                                                name="email"
                                                value={email}
                                                placeholder="Enter Email Address"
                                                onChange={(e) => setEmail(e.target.value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-address1">Address Line 1</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-address1"
                                                name="addressLine1"
                                                value={addressLine1}
                                                placeholder="Enter Address Line 1"
                                                onChange={(e) => setAddressLine1(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-address2">Address Line 2</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-address2"
                                                name="addressLine2"
                                                value={addressLine2}
                                                placeholder="Enter Address Line 2"
                                                onChange={(e) => setAddressLine2(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-address3">Address Line 3</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-address3"
                                                name="addressLine3"
                                                value={addressLine3}
                                                placeholder="Enter Address Line 3"
                                                onChange={(e) => setAddressLine3(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-city">City</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-city"
                                                name="city"
                                                value={city}
                                                placeholder="Enter City"
                                                onChange={(e) => setCity(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-state">State</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-state"
                                                name="state"
                                                value={state}
                                                placeholder="Enter State"
                                                onChange={(e) => setState(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-country">Country</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-country"
                                                name="country"
                                                value={country}
                                                placeholder="Enter Country"
                                                onChange={(e) => setCountry(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-code">Postal Code</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="fv-code"
                                                name="postalCode"
                                                value={postalCode}
                                                placeholder="Enter Postal Code"
                                                onChange={(e) => setPostalCode(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pass">Password</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pass"
                                                name="password"
                                                value={password}
                                                placeholder="Enter Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-agencyGST">Agency GST No</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-agencyGST"
                                                name="agencyGSTNo"
                                                value={agencyGSTNo}
                                                placeholder="Enter Agency GST No"
                                                onChange={(e) => setAgencyGSTNo(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-agencyPan">Agency Pan No</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-agencyPan"
                                                name="agencyPanNo"
                                                value={agencyPanNo}
                                                placeholder="Enter Agency Pan No"
                                                onChange={(e) => setAgencyPanNo(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group id="formGridRadio" className="mb-3">
                                        <Form.Label>Commission Type</Form.Label>
                                        <Row>
                                            {discType.map((value, index) => {
                                                return (
                                                    <Col xs={4} md={4} key={index}>
                                                        <Form.Check
                                                            checked={value.value === commissionType}
                                                            inline
                                                            type="radio"
                                                            label={value.value}
                                                            id={value.id}
                                                            name="discount_type"
                                                            value={value.value}
                                                            onChange={(e) => setCommissionType(e.target.value)}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-commission">Commission</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-commission"
                                                name="commission"
                                                value={commission}
                                                placeholder="Enter Commission"
                                                onChange={(e) => setCommission(e.target.value)}
                                                autoComplete="off"
                                                required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-md btn-primary" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting..' : props?.location?.state?.id ? "Update" : "Add"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAgent;