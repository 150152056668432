import React, { createContext, useEffect, useReducer } from 'react'

export const UserContext = createContext();
export const LS_USER_TOKEN = "jol_a_token";
export const LS_USER_DATA = "jol_a_data";

const token = localStorage.getItem(LS_USER_TOKEN);
const data = localStorage.getItem(LS_USER_DATA);

const initialState = {
    isLoggedin: !!token,
    user: token ? { ...JSON.parse(data), token } : null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, isLoggedin: true, user: action.payload }
        case 'REMOVE_USER':
            return { ...state, isLoggedin: false, user: null }
        default:
            return state
    }
}

function UserContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
