import React, { useEffect, useState } from "react";
import { getCountries, getMethod, postMethod, putMethod } from "../../../../Services/ApiServices";
import PhoneInput from 'react-phone-input-2';
import ToastMessage from "../../../../Components/Toast/Toast";
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { LS_USER_DATA } from "../../../../Contexts/UserContext";

const AddUser = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailPhone, setEmailPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState({
        firstName: "",
        lastName: "",
        emailPhone: "",
        password: "",
        confirmPassword: ""
    })
    const [phoneFormat, setPhoneFormat] = useState({
        "dialCode": "91",
        "countryCode": "in",
    });

    const shouldShow = (field) => {
        var phoneno = /^[0-9]+$/;
        if (emailPhone.match(phoneno)) {
            return true;
        }
        else {
            return false
        }

    };

    const [showToast, setShowToast] = useState(false);
    const toggleShow = () => {
        setShowToast(!showToast);
    };
    const [showMessage, setShowMessage] = useState("");

    const history = useHistory()

    const formValidation = () => {
        let isValid = true;
        if (!firstName) {
            setError((prev) => {
                return {
                    ...prev,
                    firstName: "First Name is Required"
                }
            })
            isValid = false;
        }
        else {
            setError((prev) => {
                return {
                    ...prev,
                    firstName: ""
                }
            })
            isValid = true;
        }
        if (!lastName) {
            setError((prev) => {
                return {
                    ...prev,
                    lastName: "Last Name is Required"
                }
            })
            isValid = false;
        }
        else {
            setError((prev) => {
                return {
                    ...prev,
                    lastName: ""
                }
            })
            isValid = true;
        }
        if (!emailPhone) {
            setError((prev) => {
                return {
                    ...prev,
                    emailPhone: "Email or Phone is Required"
                }
            })
            isValid = false;
        }
        else {
            setError((prev) => {
                return {
                    ...prev,
                    emailPhone: ""
                }
            })
            isValid = true;
        }
        if (!password) {
            setError((prev) => {
                return {
                    ...prev,
                    password: "Password is Required"
                }
            })
            isValid = false;
        }
        else {
            setError((prev) => {
                return {
                    ...prev,
                    password: ""
                }
            })
            isValid = true;
        }
        if (!confirmPassword) {
            setError((prev) => {
                return {
                    ...prev,
                    confirmPassword: "Confirm Password is Required"
                }
            })
            isValid = false;
        }
        else {
            setError((prev) => {
                return {
                    ...prev,
                    confirmPassword: ""
                }
            })
            isValid = true;
        }
        return isValid;
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        let Valid = formValidation();
        if (Valid) {
            const data = {
                "firstName": firstName,
                "lastName": lastName,
                "emailPhone": emailPhone,
                "password": password,
                "confirmPassword": confirmPassword
            }
            if (shouldShow(emailPhone)) {
                data.emailPhone = {
                    "mobile": emailPhone,
                    "countryCode": phoneFormat.countryCode.toUpperCase(),
                    "callingCode": parseInt(phoneFormat.dialCode)
                }
            }
            else {
                data.emailPhone = emailPhone
            }
            const endpoint = "admin/addUser"
            postMethod(endpoint, data).then((res) => {
                if (res.success) {
                    setShowToast(true)
                    setShowMessage("User added successfully")
                    history.push("/users")
                }
                else {
                    setShowToast(true)
                    setShowMessage(res.error)
                }
            })
        }
    }
    return (
        <div className="nk-content">
            <ToastMessage showToast={showToast} toggleShow={toggleShow} showMessage={showMessage} />
            <div className="container-fluid pt-3 px-5">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Add User</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form className="form-validate">
                            <div className="row g-gs">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-first-name">First Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-first-name"
                                                name="firstName"
                                                value={firstName}
                                                placeholder="Enter First Name"
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                            <span className="errorStyle">{error.firstName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-last-name">Last Name</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-last-name"
                                                name="lastName"
                                                value={lastName}
                                                placeholder="Enter Last Name"
                                                onChange={(e) => setLastName(e.target.value)}
                                                required />
                                            <span className="errorStyle">{error.lastName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-phone">Phone or Email</label>
                                        <div className="form-control-wrap">
                                            <span className={shouldShow('emailPhone') ? "countryCode" : "hidden"}>
                                                <PhoneInput
                                                    inputProps={{
                                                        name: 'phone',
                                                        disabled: true
                                                    }}
                                                    // country={'in'}
                                                    value={"+" + phoneFormat.dialCode}
                                                    onChange={(e, a) => { setPhoneFormat(a); }}
                                                />
                                            </span>
                                            <input
                                                type="text"
                                                className={shouldShow('emailPhone') ? "form-control form-control-lg pl-110" : "form-control form-control-lg"}
                                                id="emailPhone"
                                                maxLength={shouldShow('emailPhone') ? "10" : null}
                                                name="emailPhone"
                                                value={emailPhone}
                                                placeholder="Enter your email address or phone number"
                                                onChange={(e) => setEmailPhone(e.target.value)}
                                                autoComplete="off"
                                            />
                                            <span className="errorStyle">{error.emailPhone}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pwd">Password</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pwd"
                                                name="password"
                                                value={password}
                                                placeholder="Enter Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <span className="errorStyle">{error.password}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fv-pwd">Confirm Password</label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fv-pwd"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                placeholder="Enter Confirm Password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                            <span className="errorStyle">{error.confirmPassword}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row fr_jc pr-3 py-3">
                            <div className="form-group">
                                <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default AddUser;